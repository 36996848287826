/* eslint-disable import/extensions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-undef */
import {
  Box,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { TickIcon } from '../icons';
import { commonSteper_style } from './style';

function CommonSteper(props) {
  const {
    className = '',
    step = 1,
    stepText = [],
    icons = {},
    rootStyle = {},
    ...rest
  } = props;

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#E2E2E2',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#25C460',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 2,
    },
  }));

  function ColorlibStepIcon(props) {
    const { active, completed } = props;

    return (
      <Box>
        {completed ? (
          <Box>
            <Box
              sx={{
                zIndex: 1,
                display: 'flex',
                borderRadius: '50%',
                justifyContent: 'center',
                width: 28,
                height: 28,
                alignItems: 'center',
                backgroundColor: '#E8FCF0',
                border: '2px solid #25C460',
              }}
            >
              <TickIcon rootStyle={{ color: '#25C460' }} />
            </Box>
          </Box>
        ) : active ? (
          <Box>
            <Box
              sx={{
                zIndex: 1,
                display: 'flex',
                borderRadius: '50%',
                width: 28,
                height: 28,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FFF6F0',
                border: '2px dashed #EE8438',
              }}
            >
              <Typography fontSize={12} color="#EE8438">
                {icons[String(props.icon)]}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                zIndex: 1,
                display: 'flex',
                borderRadius: '50%',
                width: 28,
                height: 28,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#EEEEEE',
              }}
            >
              <Typography fontSize={12} color="#757575">
                {icons[String(props.icon)]}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    );
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };

  return (
    <Box
      sx={{
        ...commonSteper_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Stack sx={{ width: '100%' }}>
        <Stepper
          alternativeLabel
          activeStep={step}
          connector={<QontoConnector />}
        >
          {stepText.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                sx={{ '& .MuiStepLabel-label': { mt: 1 } }}
              >
                <Typography
                  id={label}
                  data-active={
                    step === 0 && index === 0
                      ? true
                      : step === 1 && index === 1
                      ? true
                      : step === 2 && index === 2
                      ? true
                      : false
                  }
                  fontSize={12}
                  fontWeight={600}
                  color={
                    step === 0
                      ? index === 0
                        ? '#EE8438'
                        : index === 1
                        ? '#757575'
                        : '#757575'
                      : step === 1
                      ? index === 0
                        ? '#25C460'
                        : index === 1
                        ? '#EE8438'
                        : '#757575'
                      : step === 2
                      ? index === 0
                        ? '#25C460'
                        : index === 1
                        ? '#25C460'
                        : '#EE8438'
                      : '#25C460'
                  }
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </Box>
  );
}

CommonSteper.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  step: PropTypes.number,
  icons: PropTypes.object,
  stepText: PropTypes.array,
};

export { CommonSteper };

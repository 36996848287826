export const qusAnsSelection_style = {
  boxTitleSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pb: 2,
  },
  quickSx: {
    fontWeight: '600',
    color: 'PrimaryTints.A100',
    opacity: '40%',
    pt: 2,
  },
  suggestionSx: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  boxSx: {
    p: 1.25,
    border: '2px solid',
    borderRadius: '8px',
    mr: 1,
    mt: 1,
    cursor: 'pointer',
  },
};

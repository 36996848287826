import OtpBg from '@assets/otpBg.svg';
import { ExpertDoneIcon } from '@hc/ui/atoms';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { AccountSplash_style } from './style';

export default function AccountSplash(props) {
  const { className = '', ...rest } = props;

  return (
    <Box sx={AccountSplash_style.rootSx} className={`${className}`} {...rest}>
      <Box
        sx={{
          ...AccountSplash_style.backgroundrootSx,
          backgroundImage: `url(${OtpBg})`,
        }}
      >
        {/* Splash Design */}
        <Box sx={AccountSplash_style.resetSx}>
          <ExpertDoneIcon rootStyle={AccountSplash_style.avatarSx} />
          <Typography variant="h5" sx={AccountSplash_style.titleSx}>
            Account created
          </Typography>
          <Typography variant="body1" sx={AccountSplash_style.titleSx}>
            successfully
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

AccountSplash.propTypes = {
  className: PropTypes.string,
};

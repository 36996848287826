import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { splashPageFooter_style } from './style';

function SplashPageFooter(props) {
  const { className = '', rootStyle = {}, ...rest } = props;
  const navigate = useNavigate();

  const buttonData = [
    {
      name: 'Sign in',
      value: 1,
      path: '/login',
    },
    {
      name: 'Sign up',
      value: 2,
      path: '/createaccount',
    },
  ];

  return (
    <Box
      sx={{
        ...splashPageFooter_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid container display="flex" columnSpacing={1}>
        {buttonData.map((val, i) => (
          <Grid item xs={6} sm={6} md={6} key={i}>
            <Box
              sx={splashPageFooter_style.buttonSx}
              onClick={() => navigate(val?.path)}
            >
              <Typography sx={splashPageFooter_style.buttonTextSx}>
                {val?.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

SplashPageFooter.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { SplashPageFooter };

/* eslint-disable no-sparse-arrays */
// import { envConfig } from '@hc/config';
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import toast from 'react-hot-toast';
import { create } from 'zustand';

export const constructPlanCradData = (data) => {
  if (Array.isArray(data) && data?.length > 0) {
    const arr = data.map((card) => {
      return {
        id: card?.id,
        plan_name: card?.plan_name,
        created_at: card?.created_at,
        activity_count: card?.activity_count,
        category_id: card?.category_id,
        duration: card?.duration,
        duration_type: card?.duration_type,
      };
    });
    return arr;
  }
};

export const useDefaultPlan = create((set, get) => ({
  useDefaultPlanState: {
    planMasterDefaultData: [],
    planMasterDefaultCount: 0,
    planMasterlimit: 4,
    planMasteroffset: 0,
  },
  loading: null,
  error: null,

  // GET ALL ACTIVITY TEMPLATE DATA
  getAllDefaultPlanListData: async (searchValue, offset, isSearch) => {
    const { useDefaultPlanState } = get();
    const { planMasterDefaultData, planMasterlimit } = useDefaultPlanState;

    try {
      queryClient.invalidateQueries({
        queryKey: [`plan-master-list`],
      });
      set({ loading: true });
      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: [`plan-master-list`],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/expert/expert-plan-master-list-default`,
            {
              search: searchValue,
              limit: planMasterlimit,
              offset,
            },
            true
          );

          return response;
        },
        staleTime: Infinity,
      });

      if (status?.code === 200) {
        if (isSearch) {
          set(() => ({
            useDefaultPlanState: {
              ...useDefaultPlanState,
              planMasterDefaultData: data?.data ?? [],
              planMasterDefaultCount: data.count ?? '',
            },
            loading: false,
          }));
        } else {
          set(() => ({
            useDefaultPlanState: {
              ...useDefaultPlanState,
              planMasterDefaultData: [...planMasterDefaultData, ...data.data],
              planMasterDefaultCount: data.count ?? '',
            },
          }));
        }
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  handlePlanChange: (key, value) => {
    set({
      [key]: value,
    });
  },

  clearPlanFilter: () => {
    set({
      planMasterDefaultData: [],
    });
  },
}));

import { Box, Grid, Stack, Typography } from '@mui/material';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import PropTypes from 'prop-types';
import { planPreview_style } from './style';

function PlanPreview(props) {
  const {
    className = '',
    rootStyle = {},
    planData = [],
    planName = '',
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...planPreview_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid container sx={{ border: '2px solid #E2E2E2' }}>
        <Grid
          item
          xs={3}
          backgroundColor="#EFFADD"
          sx={{
            border: 'solid',
            borderWidth: '0px 2px 2px 0px',
            borderColor: '#E2E2E2',
          }}
        />
        <Grid
          item
          xs={9}
          display="flex"
          justifyContent="start"
          backgroundColor="#EFFADD"
          sx={{
            border: 'solid',
            borderWidth: '0px 0px 2px 0px',
            borderColor: '#E2E2E2',
          }}
        >
          <Typography
            pl={1}
            py={2}
            sx={{
              fontSize: '12px',
              color: '#0E1824',
              fontWeight: 500,
            }}
          >
            {planName}
          </Typography>
        </Grid>
        {planData?.length > 0 ? (
          <Grid item width="100%">
            <Box width="100%">
              {planData?.map((data, i) => (
                <Grid container key={i}>
                  <Grid
                    item
                    xs={3}
                    p={2}
                    backgroundColor="#EFFADD"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      border: 'solid',
                      borderWidth: '0px 2px 2px 0px',
                      borderColor: '#E2E2E2',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: '#0E1824',
                        fontWeight: 500,
                      }}
                    >
                      {data.day}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sx={{
                      border: 'solid',
                      borderWidth: '0px 0px 2px 0px',
                      borderColor: '#E2E2E2',
                    }}
                  >
                    {data?.plan?.length > 0 ? (
                      <Box>
                        {' '}
                        {data?.plan.map((pln, i) => (
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            key={i}
                            sx={{
                              borderBottom: `${
                                data.plan.length === i + 1 ? 0 : 2
                              }px solid`,
                              borderColor: '#E2E2E2',
                            }}
                            p={1}
                          >
                            <RiCheckboxBlankCircleFill
                              style={{ fontSize: '8px', ml: 2, color: 'grey' }}
                            />
                            <Typography
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: '#5C6266',
                              }}
                              p={1}
                            >
                              {`${pln?.activity_name ?? ''} - ${
                                pln?.quantity ?? ''
                              } ${pln?.unit_name ?? ''} , ${
                                pln?.activity_time_name ?? ''
                              }`}
                            </Typography>
                          </Stack>
                        ))}
                      </Box>
                    ) : (
                      <Box>
                        <Stack
                          flexDirection="row"
                          alignItems="center"
                          key={i}
                          sx={{
                            borderBottom: `${
                              data.plan.length === i + 1 ? 0 : 2
                            }px solid`,
                            borderColor: '#E2E2E2',
                          }}
                          p={1}
                        >
                          <Typography
                            sx={{
                              fontSize: '12px',
                              fontWeight: 500,
                              color: '#5C6266',
                            }}
                            p={1}
                          >
                            No Activity
                          </Typography>
                        </Stack>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Grid>
        ) : (
          <Box
            sx={{
              height: '60vh',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Typography sx={{ alignSelf: 'center' }}>No data Found</Typography>
          </Box>
        )}
      </Grid>
    </Box>
  );
}

PlanPreview.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  planData: PropTypes.array,
  planName: PropTypes.string,
};

export { PlanPreview };

import { expertRoutes } from '@hc/routes';
import { Animate } from '@hc/ui/atoms';
import { ExpertRootLayout, PageNotFound } from '@hc/ui/components';
import ErrorBoundary from '@pages/errorBoundary';
import Home from '@pages/home';
import ExpertLogin from '@pages/login';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AccountSplash from '../pages/accountCreatedSplash';
import AddActivity from '../pages/activity/addActivity';
import EditActivityDetails from '../pages/activity/editActivityDetail';
import SelectActivity from '../pages/activity/selectActivity';
import NewRequestStepper from '../pages/basicInfoStepper';
import ExpertSignUp from '../pages/createAccount';
import CustomPlanSplash from '../pages/customPlanSplash';
import ExpertNotes from '../pages/expertNotes';
import ExpertForgotPassword from '../pages/forgotPassword';
import Master from '../pages/master';
import ActivityNotes from '../pages/notes';
import ExpertAddProfile from '../pages/onboarding';
import PlanAddEditTool from '../pages/planAddEditTool';
import EditPlan from '../pages/previewPlan';
import ExpertProfile from '../pages/profile';
import ExpertEditProfile from '../pages/profile/edit';
import ActivityReference from '../pages/reference';
import ExpertLandingPage from '../pages/splash';
import { PrivateRouter } from './privateRouter';
import { ExpertNotification } from '../pages/notifications';
import { UserProfile } from '../pages/userProfile';

const pathSlicer = (path, startSlice = 1) => path.slice(startSlice);

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRouter>
        <ExpertRootLayout />
      </PrivateRouter>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: (
          <Animate key="/" show>
            <Home />
          </Animate>
        ),
      },

      // onboarding
      {
        path: pathSlicer(expertRoutes?.landPage),
        element: (
          <Animate key={pathSlicer(expertRoutes?.landPage)} show>
            <ExpertLandingPage />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.signIn),
        element: (
          <Animate key={pathSlicer(expertRoutes?.signIn)} show>
            <ExpertLogin />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.createAccount),
        element: (
          <Animate key={pathSlicer(expertRoutes?.createAccount)} show>
            <ExpertSignUp />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.resetPassword),
        element: (
          <Animate key={pathSlicer(expertRoutes?.resetPassword)} show>
            <ExpertSignUp />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.forgotPassword),
        element: (
          <Animate key={pathSlicer(expertRoutes?.forgotPassword)} show>
            <ExpertForgotPassword />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.profile),
        element: (
          <Animate key={pathSlicer(expertRoutes?.profile)} show>
            <ExpertProfile />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.editProfile),
        element: (
          <Animate key={pathSlicer(expertRoutes?.editProfile)} show>
            <ExpertEditProfile />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.addProfile),
        element: (
          <Animate key={pathSlicer(expertRoutes?.addProfile)} show>
            <ExpertAddProfile />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.master),
        element: (
          <Animate key={pathSlicer(expertRoutes?.master)} show>
            <Master />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.editplan),
        element: (
          <Animate key={pathSlicer(expertRoutes?.editplan)} show>
            <EditPlan />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.expertNotes),
        element: (
          <Animate key={pathSlicer(expertRoutes?.expertNotes)} show>
            <ExpertNotes />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.addActivity),
        element: (
          <Animate key={pathSlicer(expertRoutes?.addActivity)} show>
            <AddActivity />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.basicInfoSteper),
        element: (
          <Animate key={pathSlicer(expertRoutes?.basicInfoSteper)} show>
            <NewRequestStepper />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.accountCreated),
        element: (
          <Animate key={pathSlicer(expertRoutes?.accountCreated)} show>
            <AccountSplash />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.customPlan),
        element: (
          <Animate key={pathSlicer(expertRoutes?.customPlan)} show>
            <CustomPlanSplash />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.activityNotes),
        element: (
          <Animate key={pathSlicer(expertRoutes?.activityNotes)} show>
            <ActivityNotes />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.activityReference),
        element: (
          <Animate key={pathSlicer(expertRoutes?.activityReference)} show>
            <ActivityReference />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.selectActivity),
        element: (
          <Animate key={pathSlicer(expertRoutes?.selectActivity)} show>
            <SelectActivity />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.editActivityDetails),
        element: (
          <Animate key={pathSlicer(expertRoutes?.editActivityDetails)} show>
            <EditActivityDetails />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.planAddEditTool),
        element: (
          <Animate key={pathSlicer(expertRoutes?.planAddEditTool)} show>
            <PlanAddEditTool />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.notification),
        element: (
          <Animate key={pathSlicer(expertRoutes?.notification)} show>
            <ExpertNotification />
          </Animate>
        ),
      },
      {
        path: pathSlicer(expertRoutes?.userProfile),
        element: (
          <Animate key={pathSlicer(expertRoutes?.userProfile)} show>
            <UserProfile />
          </Animate>
        ),
      },
    ],
  },
  {
    path: '*',
    errorElement: <ErrorBoundary />,
    element: <PageNotFound />,
  },
]);

function RouterApp() {
  return <RouterProvider router={router} />;
}

export default RouterApp;

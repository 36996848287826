export const dataTabs_style = {
  totalSx: {
    backgroundColor: 'primary.bglighter',
    borderRadius: '8px',
    '& .MuiTabs-root': {
      borderRadius: '8px',
    },
  },
  numberCountSx: {
    width: '24px',
    height: '24px',
    fontSize: '14px',
  },
  tabSx: {
    textTransform: 'capitalize',
    minHeight: 0,
    p: '12px 0px',
    width: '100%',
    color: '#3D3D3D',
    fontWeight: 500,
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: 'primary.main',
      borderRadius: '8px',
      fontSize: '14px',
      p: '12px 0px',
      fontWeight: 600,
    },
  },

  underTab: {
    '& .MuiTabs-indicator': {
      backgroundColor: 'none',
      height: '0',
      px: 3,
    },
    '& .MuiTabs-flexContainer': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2,1fr)',
      gap: '8px',
      boxSizing: 'border-box',
      m: 0.5,
    },
    '& .MuiTabs-root': {
      borderRadius: '8px',
    },
  },

  searchFiledSx: {
    '& .MuiOutlinedInput-root': {
      py: 0.3,
      color: '#C5C5C5',
      borderRadius: '8px',
      borderColor: '#DBEAE8',
      '&.Mui-focused fieldset': {
        borderColor: '#DBEAE8',
        color: '#0E1824',
        borderRadius: '8px',
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      color: '#0E1824',
    },
  },
  switchSx: {
    '& .MuiSwitch-track': {
      backgroundColor: '#fff',
      opacity: 1,
      border: 0,
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#fff',
      opacity: 1,
    },
  },
  switchFont: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#FFFFFF',
    mr: 1,
  },
};

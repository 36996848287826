/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { questionAnsList_style } from './style';

function QuestionAnsList(props) {
  const { className = '', data = {}, rootStyle = {}, ...rest } = props;
  const [arrayData, setArrayData] = useState([]);

  const generateArray = () => {
    const array = [];
    for (const [key, value] of Object?.entries(data)) {
      if (
        key !== 'Mobile Number' &&
        key !== 'Plan No' &&
        key !== 'Plan Assigned'
      ) {
        const obj = {
          question: key,
          ans: value,
        };
        array.push(obj);
      }
    }

    let result = [];
    if (array[4]?.ans === 'Other') {
      const obj = {
        ans: array[5]?.ans,
        question: array[4]?.question,
      };
      result.push(obj);
      for (const object of array) {
        if (object.ans !== 'Other' && object?.question !== 'Time Spent') {
          result.push(object);
        }
      }
    } else {
      result = array;
    }

    let finalResult = [];
    if (array[10]?.ans.filter((valid) => valid === 'Other').length > 0) {
      const quesAns = array[10]?.ans;
      quesAns.push(array[11]?.ans);

      const obj = {
        ans: array[10]?.ans.filter((valid) => valid !== 'Other'),
        question: array[10]?.question,
      };
      for (const object of result) {
        if (
          object.question !==
            'If you suffer from any of the following, please select if applicable' &&
          object.question !== 'Other Disease'
        ) {
          finalResult.push(object);
        }
      }
      finalResult.push(obj);
    } else {
      finalResult = array;
    }

    setArrayData(finalResult);
    return finalResult;
  };

  useEffect(() => {
    generateArray();
  }, []);

  return (
    <Box
      sx={{
        ...questionAnsList_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {arrayData.map((value, index) => (
        <Box key={index} sx={questionAnsList_style.cardRootSx}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} pb={1.8}>
              <Typography fontSize={14} fontWeight={500}>{`Q${index + 1}: ${
                value.question
              }`}</Typography>
            </Grid>
            {typeof value.ans === 'string' ? (
              <Grid item xs={12} sm={12} md={12}>
                <Typography fontSize="12px">{value.ans}</Typography>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={12}>
                {value?.ans?.length &&
                  value?.ans?.map((val, i) => (
                    <Typography key={i} fontSize={12}>
                      {val}
                    </Typography>
                  ))}
              </Grid>
            )}
          </Grid>
        </Box>
      ))}
    </Box>
  );
}

QuestionAnsList.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  data: PropTypes.object,
};

export { QuestionAnsList };

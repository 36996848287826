export const taskWeeklyCalender_Style = {
  rootSx: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: 1.5,
    mt: 1,
  },
  activeSx: {
    bgcolor: 'background.paper',
    color: 'purple.A700',
    borderRadius: '28px',
    pb: 1,
    pt: 0.5,
    px: 0.5,
  },
  inactiveSx: {
    bgcolor: 'purple.A700',
    borderRadius: '28px',
    pb: 1,
    pt: 0.5,
    px: 0.5,
  },
  pastButton: {
    backgroundColor: 'purple.A200',
    fontSize: '14px',
    fontWeight: 'medium',
    borderRadius: '28px',
    opacity: '0.5',
    p: 0.5,
  },
  circleSx: {
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',
    width: '38px',
    height: '38px',
    bgcolor: 'background.paper',
    borderRadius: '50%',
  },
  circleInactiveSx: {
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',
    width: '38px',
    height: '38px',
    bgcolor: 'mbf.main',
    borderRadius: '50%',
  },
  dateSx: {
    fontSize: '12px',
    fontWeight: 600,
    color: 'mbf.main',
    pt: 0.4,
  },
  dateInactiveSx: {
    fontSize: '12px',
    fontWeight: 600,
    color: 'background.paper',
    pt: 0.4,
  },
  weekSx: {
    fontSize: '8px',
    fontWeight: 600,
    color: 'mbf.main',
    pb: 0.6,
  },
  weekInactiveSx: {
    fontSize: '8px',
    fontWeight: 600,
    color: 'background.paper',
    pb: 0.6,
  },
  taskCountSx: {
    fontSize: '12px',
    fontWeight: 'medium',
    color: 'background.paper',
  },
  taskCountInactiveSx: {
    fontSize: '12px',
    fontWeight: 'medium',
    color: 'purple.A700',
  },
  taskSx: {
    fontSize: '8px',
    fontWeight: 'normal',
    color: 'background.paper',
  },
  taskInactiveSx: {
    fontSize: '8px',
    fontWeight: 'normal',
    color: '#purple.A700',
  },
};

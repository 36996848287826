/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-key */
import { expertRoutes } from '@hc/routes';
import { usePlanAddEditTool } from '@hc/store';
import {
  Button,
  EyeIcon,
  NotesEmptyStateIcon,
  ReferenceEmptyStateIcon,
} from '@hc/ui/atoms';
import { HomeEmptyState, Screenlayout } from '@hc/ui/components';
import { localStorageKeys } from '@hc/utils';
import TabContext from '@mui/lab/TabContext/TabContext';
import TabList from '@mui/lab/TabList/TabList';
import { Box, Grid, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import { ExpertNotes_style } from './style';

export default function ExpertNotes(props) {
  const { className = '', ...rest } = props;
  const [tabIndex, setTabIndex] = useState('1');
  const [notes, setNotes] = useState({});
  const [mindDontsValue, setMindDontsValue] = useState('');
  const [bodyDontsValue, setbodyDontsValue] = useState('');
  const [foodDontsValue, setfoodDontsValue] = useState('');
  const navigate = useNavigate();

  const planAddEditToolStateData = localStorage.getItem(
    localStorageKeys.planAddEditToolState
  );
  const planAddEditToolData = JSON.parse(planAddEditToolStateData);

  const { planAddEditToolState, onExpertNotesUpdate, getLocalStorage } =
    usePlanAddEditTool(
      (state) => ({
        onExpertNotesUpdate: state.onExpertNotesUpdate,
        planAddEditToolState: state.planAddEditToolState,
        getLocalStorage: state.getLocalStorage,
      }),
      (prev, curr) => isEqual(prev, curr)
    );

  const breaks = [
    {
      label: 'Mind',
      value: '1',
    },
    {
      label: 'Body',
      value: '2',
    },
    {
      label: 'Food',
      value: '3',
    },
  ];

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  function handleDontsChanges(content, delta, source, editor) {
    if (tabIndex === '1') {
      setMindDontsValue(editor.getContents());
    } else if (tabIndex === '2') {
      setbodyDontsValue(editor.getContents());
    }
    if (tabIndex === '3') {
      setfoodDontsValue(editor.getContents());
    }
  }

  const onSave = (value) => {
    setNotes(planAddEditToolState?.expertNotes?.[value]);
    if (tabIndex === '1') {
      onExpertNotesUpdate('mind', 'isNotesAdded', true);
      setNotes(planAddEditToolState?.expertNotes?.mind);
      if (
        !planAddEditToolData?.expertNotes?.mind?.isNotesAdded ||
        planAddEditToolData?.isHistory ||
        planAddEditToolData?.isDraft
      ) {
        onExpertNotesUpdate('mind', 'data', mindDontsValue);
      }
    } else if (tabIndex === '2') {
      onExpertNotesUpdate('body', 'isNotesAdded', true);
      setNotes(planAddEditToolState?.expertNotes?.body);
      if (
        !planAddEditToolData?.expertNotes?.body?.isNotesAdded ||
        planAddEditToolData?.isHistory ||
        planAddEditToolData?.isDraft
      ) {
        onExpertNotesUpdate('body', 'data', bodyDontsValue);
      }
    } else if (tabIndex === '3') {
      onExpertNotesUpdate('food', 'isNotesAdded', true);
      setNotes(planAddEditToolState?.expertNotes?.food);
      if (
        !planAddEditToolData?.expertNotes?.food?.isNotesAdded ||
        planAddEditToolData?.isHistory ||
        planAddEditToolData?.isDraft
      ) {
        onExpertNotesUpdate('food', 'data', foodDontsValue);
      }
    }
    navigate(expertRoutes.planAddEditTool);
  };

  useEffect(() => {
    getLocalStorage();
  }, []);

  useEffect(() => {
    if (tabIndex === '1') {
      setNotes(planAddEditToolState?.expertNotes?.mind);
      if (!planAddEditToolData?.expertNotes?.mind?.isNotesAdded) {
        setMindDontsValue(planAddEditToolData?.expertNotes?.mind.data);
      }
    } else if (tabIndex === '2') {
      setNotes(planAddEditToolState?.expertNotes?.body);
      if (!planAddEditToolData?.expertNotes?.body?.isNotesAdded) {
        setbodyDontsValue(planAddEditToolData?.expertNotes?.body?.data);
      }
    } else if (tabIndex === '3') {
      setNotes(planAddEditToolState?.expertNotes?.food);
      if (!planAddEditToolData?.expertNotes?.food?.isNotesAdded) {
        setfoodDontsValue(planAddEditToolData?.expertNotes?.food.data);
      }
    }
  }, [tabIndex]);

  useEffect(() => {
    setNotes(planAddEditToolState?.expertNotes?.mind);
    setMindDontsValue(planAddEditToolData?.expertNotes?.mind.data);
    setbodyDontsValue(planAddEditToolData?.expertNotes?.body?.data);
    setfoodDontsValue(planAddEditToolData?.expertNotes?.food.data);
  }, []);

  useEffect(() => {
    setNotes(planAddEditToolState?.expertNotes?.mind);
  }, [planAddEditToolState?.expertNotes?.mind]);

  return (
    <Screenlayout
      isExpertAppBar
      appBarStyle={ExpertNotes_style.appBarStyleSx}
      backRequired
      backRoute={expertRoutes.planAddEditTool}
      backIconStyle={{ color: '#6552CC' }}
      rootStyle={{ backgroundColor: '#FCFBFF' }}
      title={planAddEditToolState?.planDurationData?.plan_name ?? ''}
      masterchildStyle={ExpertNotes_style.screenLayoutChildernSx}
      notshowFooter={false}
      footerStyle={{ borderTop: '0px', p: 3 }}
      footer={
        <Grid container columnSpacing={2}>
          <Grid
            item
            xs={!notes?.isAddNotes ? 12 : 6}
            sm={!notes?.isAddNotes ? 12 : 6}
            md={!notes?.isAddNotes ? 12 : 6}
          >
            <Button
              onClick={() => navigate(expertRoutes?.planAddEditTool)}
              buttonStyle={ExpertNotes_style.backbuttonSx}
            >
              {!notes?.isAddNotes ? 'Back' : 'Cancel'}
            </Button>
          </Grid>
          {notes?.isAddNotes && (
            <Grid item xs={6} sm={6} md={6}>
              <Button
                onClick={() =>
                  onSave(
                    tabIndex === '1'
                      ? 'mind'
                      : tabIndex === '2'
                      ? 'body'
                      : 'food'
                  )
                }
                buttonStyle={{ textTransform: 'capitalize' }}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      }
    >
      <Box sx={ExpertNotes_style.rootSx} className={`${className}`} {...rest}>
        <Box sx={ExpertNotes_style.expertAppbarSx}>
          <Box
            sx={{
              height: '80px',
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'center',
              pb: 0.3,
            }}
          >
            <TabContext value={tabIndex}>
              <Box
                sx={{
                  width: '100%',
                  height: '40px',
                  backgroundColor: '#D5CCFF',
                  position: 'relative',
                  py: 0.4,
                  px: 0,
                  borderRadius: '8px',
                }}
              >
                <TabList
                  sx={{
                    '& .MuiTabs-flexContainer': {
                      display: 'flex',
                      justifyContent: 'space-around',
                    },
                  }}
                  onChange={handleChange}
                  TabIndicatorProps={{
                    style: {
                      width: '110px',
                      height: '32px',
                      top: 1,
                      zIndex: 0,
                      backgroundColor: ' rgb(101, 82, 204)',
                      borderRadius: '8px',
                    },
                  }}
                >
                  {breaks.length > 0 &&
                    breaks?.map((tab, i) => {
                      return (
                        <Tab
                          key={i}
                          sx={ExpertNotes_style.tabButtonSx}
                          disableTouchRipple
                          label={tab.label}
                          value={tab.value}
                          icon={tab.count}
                          iconPosition="end"
                        />
                      );
                    })}
                </TabList>
              </Box>
            </TabContext>
          </Box>
        </Box>
        {!notes?.isAddNotes ? (
          <Box
            pt={17}
            pb={5}
            px={2}
            sx={{ overflowY: 'scroll', cursor: 'context-menu' }}
          >
            {!notes?.isAddNotes && !notes?.isNotesAdded ? (
              tabIndex === '1' ? (
                <HomeEmptyState
                  illustartion={<NotesEmptyStateIcon />}
                  title="No notes from expert"
                />
              ) : tabIndex === '2' ? (
                <HomeEmptyState
                  illustartion={<NotesEmptyStateIcon />}
                  title="No notes from expert"
                />
              ) : (
                <HomeEmptyState
                  illustartion={<NotesEmptyStateIcon />}
                  title="No notes from expert"
                />
              )
            ) : (
              <ReactQuill
                theme="bubble"
                value={
                  tabIndex === '1'
                    ? planAddEditToolState?.expertNotes?.mind?.data
                    : tabIndex === '2'
                    ? planAddEditToolState?.expertNotes?.body?.data
                    : planAddEditToolState?.expertNotes?.food?.data
                }
                readOnly
              />
            )}
          </Box>
        ) : (
          <Box pt={17} pb={5} px={2} sx={{ overflowY: 'scroll' }}>
            {!notes?.isAddNotes && !notes?.isNotesAdded ? (
              <Typography>No data found</Typography>
            ) : (
              <Box sx={{ display: 'grid', gap: 2, pt: 1 }} height="68vh" pb={4}>
                <ReactQuill
                  theme="snow"
                  value={
                    tabIndex === '1'
                      ? mindDontsValue
                      : tabIndex === '2'
                      ? bodyDontsValue
                      : foodDontsValue
                  }
                  onChange={handleDontsChanges}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>

      {!notes?.isAddNotes && !notes?.isAddedByMe ? (
        <Box sx={ExpertNotes_style.viewOnlySx}>
          <Box sx={ExpertNotes_style.viewOnlyBoxSx}>
            <Typography alignSelf="center" fontSize={12} color="#FACC15">
              <EyeIcon /> View Only
            </Typography>
          </Box>
        </Box>
      ) : notes?.isAddNotes && !notes?.isAddedByMe ? (
        <Box sx={ExpertNotes_style.viewOnlySx}>
          <Box sx={ExpertNotes_style.viewOnlyBoxSx}>
            <Typography alignSelf="center" fontSize={12} color="#FACC15">
              <EyeIcon /> View Only
            </Typography>
          </Box>
        </Box>
      ) : !notes?.isAddNotes && notes?.isAddedByMe ? (
        <Box sx={ExpertNotes_style.viewOnlySx}>
          <Box sx={ExpertNotes_style.viewOnlyBoxSx}>
            <Typography alignSelf="center" fontSize={12} color="#FACC15">
              <EyeIcon /> View Only
            </Typography>
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Screenlayout>
  );
}

ExpertNotes.propTypes = {
  className: PropTypes.string,
};

import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { homeEmptyState_style } from './style';

function HomeEmptyState(props) {
  const {
    className = '',
    rootStyle = {},
    illustartion,
    title = '',
    description = '',
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...homeEmptyState_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Stack alignItems="center">
        <Box mb={1}> {illustartion}</Box>
        <Typography sx={homeEmptyState_style.titleSx}>{title} </Typography>
        <Typography sx={homeEmptyState_style.descriptionSx}>
          {' '}
          {description}
        </Typography>
      </Stack>
    </Box>
  );
}

HomeEmptyState.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  illustartion: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.object,
};

export { HomeEmptyState };

export const addActivityCard_style = {
  cardContentSx: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  profileSx: {
    backgroundColor: '#FFF6F0',
    color: 'secondary.main',
    fontSize: '24px',
    fontWeight: 600,
    width: '48px',
    height: '48px',
    borderRadius: '6px',
  },
  relationSX: {
    px: 1,
  },
};

import { useExpertOnboarding, useLanguage } from '@hc/store';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { createFilterOptions } from '@mui/material/Autocomplete';
import {
  CustomTextArea,
  Label,
  SearchIcon,
  SearchSelect,
} from '../../../atoms';
import { QusAnsSelectionLisiting } from '../../qusAnsSelectionListing';
import { profileAdditionalInfo_style } from './style';

function ProfileAdditionalInfo(props) {
  const {
    className = '',
    showTitle,
    handleChange = () => {},
    rootStyle = {},
    ...rest
  } = props;

  const [text, setText] = useState(null);
  const filter = createFilterOptions();

  const {
    expertOnboardingState,
    updateOnboarding,
    onClearLanguage,
    onDeleteLanguage,
  } = useExpertOnboarding((state) => ({
    onClearLanguage: state.onClearLanguage,
    expertOnboardingState: state.expertOnboardingState,
    updateOnboarding: state.updateOnboarding,
    onDeleteLanguage: state.onDeleteLanguage,
  }));

  const { languageData, getLanguageData } = useLanguage((state) => ({
    languageData: state.languageData,
    getLanguageData: state.getLanguageData,
  }));

  const getLanguage = async () => {
    await getLanguageData();
  };

  const getFilteredadata = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.title);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        value: inputValue,
        labelShow: inputValue,
      });
    }
    return filtered;
  };

  const handleAddLanguage = (key, option) => {
    updateOnboarding('languages', option);
    setText({ value: '', label: '' });
    const errorCopy = expertOnboardingState?.error;

    errorCopy.about = '';
    if (!option) {
      errorCopy.languages = 'please select the languages';
    } else {
      errorCopy.languages = '';
    }
  };

  function getUnselectedLanguages() {
    const unselectedLanguages =
      Array.isArray(expertOnboardingState?.languages) &&
      expertOnboardingState?.languages?.length > 0
        ? languageData.filter((language) => {
            return !expertOnboardingState?.languages?.some(
              (selected) => selected.value === language.value,
            );
          })
        : languageData;
    return unselectedLanguages;
  }

  const onClearAllLanguage = () => {
    onClearLanguage();
  };

  const onDeleteLanguages = (i) => {
    onDeleteLanguage(i);
  };

  useEffect(() => {
    getLanguage();
  }, []);

  return (
    <Box
      sx={{
        ...profileAdditionalInfo_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {showTitle && (
        <Typography mb={1} fontSize={14} fontWeight={600}>
          Additional info
        </Typography>
      )}

      {/* Languages Known */}
      <Box sx={profileAdditionalInfo_style.inputGroupSx}>
        <Box sx={profileAdditionalInfo_style.boxRootSx}>
          {/* Qus and Ans Selection Lisiting */}
          <Label isRequired rootStyle={profileAdditionalInfo_style.labelStyle}>
            Languages Known
          </Label>

          <QusAnsSelectionLisiting
            qusAnsListingCardStyle={profileAdditionalInfo_style.listingCardSx}
            QusAnsListingHeading="Languages"
            QusAnsListingAdded="Added"
            showDivider={false}
            OnClearAll={onClearAllLanguage}
            rootStyle={profileAdditionalInfo_style.qnsAnsTotalSx}
            displaySelectionValues={expertOnboardingState?.languages}
            OnDelete={onDeleteLanguages}
            qsOptionViewKey="label"
            qusAnsSelectionHeadingStyle={
              profileAdditionalInfo_style.qnsAnsSelectionHeadSx
            }
            QusAnsSelectionListing={
              <SearchSelect
                TestId="languagesKnown"
                data-Testid="languagesKnown"
                isError={expertOnboardingState?.error?.languages?.length > 0}
                helperText={expertOnboardingState?.error?.languages ?? ''}
                startAdornment={
                  <SearchIcon sx={{ color: 'text.hint', fontSize: '16px' }} />
                }
                placeholder="English,Tamil"
                onChange={handleAddLanguage}
                filterOptions={(options, params) =>
                  getFilteredadata(options, params)
                }
                optionViewKey="label"
                value={text}
                options={getUnselectedLanguages()}
                autocompeleteStyle={profileAdditionalInfo_style.autocompeleteSx}
              />
            }
          />
        </Box>
      </Box>
      {/* About */}
      <Box sx={profileAdditionalInfo_style.inputGroupSx}>
        <Label isRequired rootStyle={profileAdditionalInfo_style.labelStyle}>
          About
        </Label>
        <CustomTextArea
          id="about"
          readonly={false}
          isError={expertOnboardingState?.error?.about?.length > 0}
          helperText={expertOnboardingState?.error?.about ?? ''}
          placeholder="Tell us a little bit about yourself"
          textFieldStyle={profileAdditionalInfo_style.customAboutSx}
          customWrapperSx={{
            '& .MuiTextField-root': {
              backgroundColor: '#F7F7F7',
              '&:hover': {
                borderColor: 'border.main',
              },
            },
          }}
          value={expertOnboardingState?.about ?? ''}
          onChange={(e) => handleChange('about', e.target.value)}
        />
      </Box>
    </Box>
  );
}

ProfileAdditionalInfo.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  showTitle: PropTypes.bool,
  handleChange: PropTypes.func,
};

export { ProfileAdditionalInfo };

import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import {
  BrainIcon,
  FoodIcon,
  LeftArrowClickIcon,
  RuningIcon,
} from '../../../atoms';
import { expertPlanTemplateCard_style } from './style';

function ExpertPlanTemplateCard(props) {
  const { className = '', rootStyle = {}, data = {}, index, ...rest } = props;
  const activity = [
    {
      name: 'Mind',
      icon: <BrainIcon color="#C5C5C5" />,
      value: data?.mindActivityCount ?? 0,
    },
    {
      name: 'Body',
      icon: <RuningIcon rootStyle={{ height: '24px' }} color="#C5C5C5" />,
      value: data?.bodyActivityCount ?? 0,
    },
    {
      name: 'Food',
      icon: <FoodIcon color="#C5C5C5" />,
      value: data?.foodActivityCount ?? 0,
    },
  ];
  return (
    <Box
      id={`plan${index}`}
      sx={{
        ...expertPlanTemplateCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={expertPlanTemplateCard_style.cardRootSx}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={expertPlanTemplateCard_style.cardTopContent}>
              <Grid container>
                <Grid item xs={10} sm={10} md={10}>
                  <Typography fontSize={14} fontWeight={600}>
                    {data?.plan_name ?? ''}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  display="flex"
                  justifyContent="end"
                >
                  <Box>
                    <LeftArrowClickIcon rootStyle={{ color: '#6552CC' }} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography color="#888888" fontSize={12}>
                      {`${data?.duration ?? ''} ${data?.duration_type ?? ''}`}
                    </Typography>
                    <Box sx={{ fontSize: '4px', pl: 1, pr: 1, color: 'grey' }}>
                      <RiCheckboxBlankCircleFill />
                    </Box>
                    <Typography color="#1F91B4" fontSize={12}>
                      {data?.created_by_admin === true ? 'master' : 'by me'}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={expertPlanTemplateCard_style.cardBottomContent}>
              <Grid container>
                {activity?.map((val, i) => (
                  <Grid item xs={4} sm={4} md={4} key={i}>
                    <Grid container>
                      <Grid
                        xs={6}
                        sm={6}
                        md={6}
                        display="flex"
                        justifyContent="end"
                      >
                        <Box display="flex">
                          <Box alignSelf="center">{val?.icon}</Box>
                        </Box>
                      </Grid>
                      <Grid
                        xs={6}
                        sm={6}
                        md={6}
                        display="flex"
                        sx={
                          val.name === 'Mind' || val.name === 'Body'
                            ? { borderRight: '1px solid #E3E3E3' }
                            : ''
                        }
                      >
                        <Typography
                          ml={0.7}
                          color="#888888"
                          alignSelf="center"
                          fontSize={12}
                        >
                          {val?.name}
                        </Typography>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        display="flex"
                        justifyContent="center"
                      >
                        <Typography color="#5C6266" fontWeight={600}>
                          {val?.value}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

ExpertPlanTemplateCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  data: PropTypes.object,
};

export { ExpertPlanTemplateCard };

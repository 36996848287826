export const config = {
  auth_url: `${import.meta.env.VITE_AUTH_API_PROTOCAL}${
    import.meta.env.VITE_AUTH_API_HOST
  }${import.meta.env.VITE_AUTH_API_BASE_URL}`,
  api_url: `${import.meta.env.VITE_AUTH_API_PROTOCAL}${
    import.meta.env.VITE_REST_API_HOST
  }${import.meta.env.VITE_REST_API_BASE_URL}`,
  payment_url: `${import.meta.env.VITE_APP_PAYMENT_BASE_URL}`,
  socket_io_url: `${import.meta.env.VITE_REST_API_PROTOCAL}${
    import.meta.env.VITE_REST_API_HOST
  }`,
};

export const expertRoutes = {
  signIn: '/login',
  home: '/',
  landPage: '/landpage',
  createAccount: '/createaccount',
  resetPassword:'/resetpassword',
  forgotPassword: '/forgotpassword',
  profile: '/profile',
  editProfile: '/profile/edit',
  addProfile: '/addprofile',
  master: '/master',
  editplan: '/editplan',
  expertNotes: '/expertNotes',
  addActivity: '/addActivity',
  basicInfoSteper: '/basicinfosteper',
  accountCreated: '/accountCreated',
  customPlan: '/customPlan',
  activityNotes: '/addActivity/activityNotes',
  activityReference: '/addActivity/activityReference',
  selectActivity: '/selectActivity',
  editActivityDetails: '/editActivityDetails',
  planAddEditTool: '/planaddedittool',
  notification: '/notification',
  userProfile: '/userprofile',
};

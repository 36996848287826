/* eslint-disable react/jsx-key */
import { expertRoutes } from '@hc/routes';
import { useExpertRequest } from '@hc/store';
import { Button, Drawer, MembersRightIcon } from '@hc/ui/atoms';
import {
  ExpertPreviousPlanCard,
  HealthConditionChips,
  QuestionAnsList,
  VitalsCard,
} from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { user_profile_style } from './style';

export function UserProfile() {
  const [tabIndex, setTabIndex] = useState('1');
  const [openPreviousPlanDrawer, setOpenPreviousPlanDrawer] = useState(false);
  const navigate = useNavigate();
  const { requestState } = useExpertRequest(
    (state) => ({
      requestState: state.requestState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { requestDataById } = requestState;

  const toFindBMICategory = (BMI_Value) => {
    if (parseFloat(BMI_Value) < 18.5) {
      return 'Underweight';
    }
    if (parseFloat(BMI_Value) > 18.5 && parseFloat(BMI_Value) < 25) {
      return 'Normal';
    }
    if (parseFloat(BMI_Value) > 25 && parseFloat(BMI_Value) < 30) {
      return 'Overweight';
    }
    if (parseFloat(BMI_Value) > 30) {
      return 'Obesity';
    }
    return 'Normal';
  };

  const onBack = () => {
    navigate(expertRoutes?.planAddEditTool);
  };
  return (
    <Screenlayout
      title={requestDataById?.mbf_user?.profile_data?.name ?? ''}
      isExpertAppBar
      tabRequired
      isDrawerFooter
      tabIndex={tabIndex}
      setTabIndex={() =>
        setTabIndex(tabIndex === '1' ? '2' : tabIndex === '2' ? '1' : '2')
      }
      isShowChildFooter={requestDataById?.plan_type !== 'New'}
      backRequired="/"
      backRoute={-1}
      tabData={[
        {
          label: 'Basic Info',
          value: '1',
        },
        {
          label: 'Q/A',
          value: '2',
        },
      ]}
      drawerChildren={
        <Box>
          <Grid container columnSpacing={2}>
            <Grid item xs={10} sm={10} md={10}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} display="flex">
                  <Typography color="#888888" fontSize={12}>
                    Previous plan •
                  </Typography>
                  <Typography
                    color="#25C460"
                    fontSize={12}
                    ml={1}
                  >{`Completion ${
                    requestDataById?.overall_percentage ?? 0
                  }%`}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography fontSize={14} fontWeight={500}>
                    {`${requestDataById?.plan_data?.plan_name} - ${requestDataById?.plan_data?.duration} ${requestDataById?.plan_data?.duration_type}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <Box
                sx={{
                  transform: 'rotate(270deg)',
                  ml: '10px',
                  mt: '-9px',
                  cursor: 'pointer',
                }}
                onClick={() => setOpenPreviousPlanDrawer(true)}
              >
                <MembersRightIcon circleFill="#F3F0FF" arrowColor="#6552CC" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
      backIconStyle={{ color: '#7A67D9' }}
      footerdDrawerChildren={
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Button onClick={onBack} sx={user_profile_style.buttonSx}>
              Back
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Box>
        {tabIndex === '1' ? (
          <Grid container pt={8}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
            >
              <Avatar
                sx={{ height: '74px', width: '74px', alignSelf: 'center' }}
                src={requestDataById?.mbf_user?.profile_data?.profile_pic ?? ''}
              >
                {requestDataById?.mbf_user?.profile_data?.name.slice(0, 1) ??
                  'A'}
              </Avatar>
            </Grid>
            <Grid item xs={12} sm={12} md={12} textAlign="center">
              <Typography fontSize={18} mt={1.5} fontWeight={600}>
                {requestDataById?.mbf_user?.profile_data?.name ?? ''}
              </Typography>

              <Typography
                mt={0.8}
                fontSize={12}
                color="#888888"
              >{`${requestDataById?.mbf_user?.profile_data?.gender} / ${requestDataById?.mbf_user?.profile_data?.age} yrs`}</Typography>
            </Grid>
            <Grid p={2.5} item xs={12} sm={12} md={12}>
              <VitalsCard
                data={{
                  height: requestDataById?.mbf_user?.profile_data?.height ?? '',
                  weight: requestDataById?.mbf_user?.profile_data?.weight ?? '',
                  bmi: requestDataById?.mbf_user?.profile_data?.bmi ?? '',
                  blood:
                    requestDataById?.mbf_user?.profile_data?.master_blood_group
                      ?.id ?? '',
                  bloodGroupName:
                    requestDataById?.mbf_user?.profile_data?.master_blood_group
                      ?.blood_group ?? '',
                  bmiCategory:
                    requestDataById?.mbf_user?.profile_data?.height ?? ''
                      ? toFindBMICategory(
                          requestDataById?.mbf_user?.profile_data?.height ?? '',
                        )
                      : '',
                  blood_pressure: requestDataById?.mbf_user?.profile_data
                    ?.blood_pressure
                    ? requestDataById?.mbf_user?.profile_data?.blood_pressure
                    : '-',

                  temp_in_f: requestDataById?.mbf_user?.profile_data?.temp_in_f
                    ? requestDataById?.mbf_user?.profile_data?.temp_in_f
                    : '-',
                  puls_bpm: requestDataById?.mbf_user?.profile_data?.puls_bpm
                    ? requestDataById?.mbf_user?.profile_data?.puls_bpm
                    : '-',
                  o2_level: requestDataById?.mbf_user?.profile_data?.o2_level
                    ? requestDataById?.mbf_user?.profile_data?.o2_level
                    : '-',
                }}
              />
            </Grid>
            <Grid px={2.5} item xs={12} sm={12} md={12}>
              <HealthConditionChips
                userData={requestDataById?.mbf_user?.profile_data ?? {}}
              />
            </Grid>
          </Grid>
        ) : (
          <Box px={2}>
            <QuestionAnsList
              data={requestDataById?.pre_evaluation_and_goals_json ?? {}}
            />
          </Box>
        )}
      </Box>

      {/* Previous plan Drawer */}
      <Drawer
        show={openPreviousPlanDrawer}
        rootStyle={user_profile_style.drawerSx}
        header="Previous plan"
        drawerRightClose
        childrenStyle={{ p: 0 }}
        onCloseDrawer={() => setOpenPreviousPlanDrawer(false)}
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        closeStyle={{ color: 'grey', height: '14px', width: '12px' }}
      >
        <Box p={2} sx={{ height: 450, overflowX: 'scroll' }}>
          <Typography pb={1.5} fontSize={14} fontWeight={500}>
            {`${requestDataById?.plan_data?.plan_name} - ${requestDataById?.plan_data?.duration} ${requestDataById?.plan_data?.duration_type}`}
          </Typography>
          <Box pb={1.5}>
            <ExpertPreviousPlanCard
              overAllProgress={requestDataById?.overall_percentage ?? 0}
              data={requestDataById?.mbf_percentage ?? []}
            />
          </Box>
          <Box pb={1.5}>
            <Typography color="#888888" fontSize={12} fontWeight={500}>
              Client feedback on plan
            </Typography>
          </Box>
          <Box pb={1.5}>
            <QuestionAnsList data={requestDataById?.feedback_json ?? {}} />
          </Box>
        </Box>
      </Drawer>
    </Screenlayout>
  );
}

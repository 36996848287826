import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { addEditToolEmptyState_style } from './style';
import { AddIcon } from '../../../atoms';

function AddEditToolEmptyState(props) {
  const { className = '', onClick = () => {}, rootStyle = {}, ...rest } = props;

  return (
    <Box
      sx={{
        ...addEditToolEmptyState_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
      onClick={() => onClick()}
    >
      <Typography
        textAlign="center"
        display="flex"
        alignSelf="center"
        color="#888888"
        fontSize={14}
        mt={18}
      >
        Tap on{' '}
        <AddIcon
          rootStyle={{
            color: '#888888',
            mx: 1,
            Height: '16px',
            width: '16px',
          }}
        />{' '}
        to add activities
      </Typography>
    </Box>
  );
}

AddEditToolEmptyState.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  onClick: PropTypes.func,
};

export { AddEditToolEmptyState };

export const expertLayout_style = {
  rootSx: {},
  expertAppbarSx: {
    pl: 2.5,
    pr: 2.5,
    py: 2,
    boxSizing: 'border-box',
    bgcolor: 'primary.light',
    borderRadius: '0px 0px 24px 24px',
    boxShadow: '0px 0px 10px #00000029',
    position: 'fixed',
    top: 0,
    zIndex: '5',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },
  promptSx: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    maxWidth: 425,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'white',
    zIndex: 1000,
  },
  app1stRootSx: {
    pl: 2,
    pr: 2.5,
    bgcolor: '#F7F7F7',
    boxSizing: 'border-box',
    pt: '76px',
    borderBottom: '1px solid #E2E2E2',
    position: 'fixed',
    top: 0,
    zIndex: '4',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },

  totalScreenSx: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  footerNavBarSx: {
    position: 'fixed',
    bottom: 0,
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
    display: 'flex',
    justifyContent: 'space-between',
    px: 5,
    py: 1.5,
    boxSizing: 'border-box',
    bgcolor: 'common.white',
    borderColor: 'border.main',
    boxShadow: '0px -2px 23px #00000014',
    borderRadius: '24px 24px 0px 0px',
  },
  navItemSx: {
    display: 'grid',
    placeItems: 'center',
    gap: 0.5,
    cursor: 'pointer',
  },
  navItemTextSx: {
    fontSize: '12px',
    fontWeight: 600,
  },
};

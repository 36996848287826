import { useEventListener } from 'ahooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

function Animate({
  delay = '0s',
  show,
  entryAnimation = 'fadeIn',
  animate = true,
  exitAnimation = 'fadeOut',
  iterationCount = 1,
  duration = '0.3s',
  children,
}) {
  const [mountComponent, setMountComponent] = useState(show);
  const [entry, setEntry] = useState(true);

  useEventListener(
    'animationend',
    () => {
      if (!show && exitAnimation) {
        setMountComponent(false);
      }
    },
    {}
  );

  useEffect(() => {
    if (!show && mountComponent) {
      if (exitAnimation) {
        setEntry(false);
      } else {
        setMountComponent(show);
      }
    } else {
      setEntry(show);
      setMountComponent(show);
    }
  }, [show]);

  return (
    <div>
      {animate && mountComponent && (
        <div>
          {React.cloneElement(children, {
            ...children.props,

            className: `hcanim ${entry ? entryAnimation : exitAnimation} ${
              children.props?.className ?? ''
            }`,
            style: {
              animationDelay: delay,
              animationDuration: duration,
              animationIterationCount: iterationCount,
              ...children.props?.style,
            },
          })}
        </div>
      )}
      {!animate && show && <div>{React.cloneElement(children, {})}</div>}
    </div>
  );
}

Animate.displayName = 'Animate';

Animate.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
  show: PropTypes.bool,
  animate: PropTypes.bool,
  entryAnimation: PropTypes.string,
  exitAnimation: PropTypes.string,
  iterationCount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['infinite', 'initial', 'inherit']),
  ]),
  duration: PropTypes.string,
  delay: PropTypes.string,
};

export { Animate };

/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */

/* eslint-disable no-param-reassign */
import { expertRoutes } from '@hc/routes';
import { useCreateAndAddActivity, useMasters } from '@hc/store';
import {
  Button,
  CloseIcon,
  CustomFilterIcon,
  CustomFilterSltIcon,
  Drawer,
  FileSearchIllustration,
  Input,
  MultiSelectToggleButton,
  SearchIcon,
  TickIcon,
} from '@hc/ui/atoms';
import {
  AddActivityCard,
  ExpertActivityCardSkeleton,
  HomeEmptyState,
  Screenlayout,
  SelectActivitiesFooter,
} from '@hc/ui/components';
import { queryClient } from '@hc/utils';
import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { SelectActivity_Style } from './style';

export default function SelectActivity(props) {
  const { className = '', ...rest } = props;
  const [activityDrawer, setOpenactivityDrawer] = React.useState(false);
  const [filterDrawer, setfilterDrawer] = React.useState(false);
  const [open, setOpen] = useState(false);

  const {
    activityState,
    onFilterUpdate,
    onStateUpdate,
    getActivities,
    onCardSelect,
    clearCreateNewActivityData,
    loading,
  } = useCreateAndAddActivity(
    (state) => ({
      clearCreateNewActivityData: state.clearCreateNewActivityData,
      onStateUpdate: state.onStateUpdate,
      onCardSelect: state.onCardSelect,
      activityState: state.activityState,
      getActivities: state.getActivities,
      onFilterUpdate: state.onFilterUpdate,
      loading: state.loading,
    }),
    (prev, curr) => {
      isEqual(prev, curr);
      return false;
    }
  );

  const { mastersState, getGoalTag } = useMasters(
    (state) => ({
      getGoalTag: state.getGoalTag,
      mastersState: state.mastersState,
    }),
    (prev, curr) => {
      isEqual(prev, curr);
      return false;
    }
  );

  const handleOpen = () => setOpen(!open);

  const { activityData, filters, addActivitySelectedData } = activityState;

  const navigate = useNavigate();
  const location = useLocation();

  const [buttons, setButtons] = useState([]);
  const [filterButtons, setFilterButtons] = useState([]);

  const handleButton = async (buttonId) => {
    const newButtons = buttons.map((btn) => {
      if (btn.value !== buttonId) return btn;
      btn.selected = !btn.selected;
      return btn;
    });
    setButtons(newButtons);
    let value = [];
    for (const val of newButtons) {
      if (val?.selected) {
        value.push(val?.value);
      }
    }
    onFilterUpdate('goal_id', value);
    queryClient.invalidateQueries({
      queryKey: ['get-activity-by-category-id'],
    });
    await getActivities([location?.state?.category_id.toString()]);
  };

  const handleClick = (buttonId) => {
    const newButtons = filterButtons.map((btn) => {
      if (btn.value !== buttonId) return btn;
      btn.selected = !btn.selected;
      return btn;
    });
    setFilterButtons(newButtons);
  };

  const onCancel = () => {
    const newButtons = filterButtons.map((btn) => {
      btn.selected = false;
      return btn;
    });
    setFilterButtons(newButtons);
  };

  const onApply = async () => {
    let value = [];
    for (const val of filterButtons) {
      if (val?.selected) {
        value.push(val?.value);
      }
    }
    onFilterUpdate('goal_id', value);
    queryClient.invalidateQueries({
      queryKey: ['get-activity-by-category-id'],
    });
    await getActivities([location?.state?.category_id.toString()]);
    setfilterDrawer(false);
  };
  const handleOpenActivity = () => {
    setOpenactivityDrawer(true);
  };

  const handelFilterDrawer = () => {
    setfilterDrawer(true);
  };

  const onNewActivityClick = () => {
    navigate(expertRoutes.addActivity, {
      state: {
        category_id: location?.state?.category_id,
        isEdit: false,
        isCustomEdit: false,
      },
    });
  };

  const onCheckSelect = (activityId) => {
    onCardSelect(activityId);
  };

  const onSearch = async (key, value) => {
    onFilterUpdate(key, value);
    queryClient.invalidateQueries({
      queryKey: ['get-activity-by-category-id'],
    });
    await getActivities([location?.state?.category_id?.toString()]);
  };

  const getInitialData = async () => {
    queryClient.invalidateQueries({
      queryKey: ['get-activity-by-category-id'],
    });
    await getActivities([location?.state?.category_id?.toString()]);
    await getGoalTag();
  };

  const onProceedClick = () => {
    let reArr = [];
    for (const data of addActivitySelectedData) {
      let obj = {
        id: data?.id,
        activity_name: data?.activity_name,
        activity_image: data?.activity_image,
        category_id: data?.category_id,
        goal_id: data?.goal_id,
        goal_name: data?.goal_name,
        is_measurable: data?.is_measurable,
        quantity: data?.quantity,
        unit: data?.unit,
        activity_days: data?.activity_days,
        activity_time: data?.activity_time,
        specific_time: data?.specific_time,
        activity_notes: data?.activity_notes,
        activity_references: data?.activity_references,
        activity_dont: data?.activity_dont,
        is_active: data?.is_active,
        activity_time_name: data?.activity_time_name,
        category_name: data?.category_name,
        unit_name: data?.unit_name,
        isNew: data?.isNew,
      };
      reArr.push(obj);
    }

    onStateUpdate('selectedActivityData', reArr);
    if (reArr?.length > 0) {
      navigate(expertRoutes?.editActivityDetails, {
        state: {
          category_id: location?.state?.category_id,
        },
      });
    } else {
      toast.error('Please select the Activities');
    }
  };

  useEffect(() => {
    getInitialData();
    clearCreateNewActivityData();
  }, []);

  useEffect(() => {
    let resArr = [];
    for (const val of mastersState?.goalTag) {
      let obj = {
        value: val?.value,
        name: val?.name,
        selected: false,
        icon: <TickIcon rootStyle={{ color: '#fff' }} />,
      };
      resArr.push(obj);
    }
    setButtons(resArr);
    setFilterButtons(resArr);
  }, [mastersState?.goalTag]);

  return (
    <Screenlayout
      isExpertAppBar
      backRoute={expertRoutes?.planAddEditTool}
      appBarStyle={SelectActivity_Style.appBarStyleSx}
      backRequired
      backIconStyle={{ color: '#6552CC' }}
      rootStyle={{ backgroundColor: '#FCFBFF' }}
      title="Select Activities"
      masterchildStyle={SelectActivity_Style.screenLayoutChildernSx}
      notshowFooter={false}
      createNewActivity
      onCreateClick={onNewActivityClick}
      footerStyle={{ borderTop: '0px', p: 1 }}
      footer={
        <Box p={1}>
          <SelectActivitiesFooter
            activities={addActivitySelectedData?.length}
            viewOnClick={handleOpenActivity}
            proceedOnClick={onProceedClick}
          />
        </Box>
      }
    >
      <Box
        sx={SelectActivity_Style.rootSx}
        className={`${className}`}
        {...rest}
      >
        <Box>
          <Box sx={SelectActivity_Style.SearchSelectSx}>
            <Input
              value={filters?.search ?? ''}
              // disabled={location.state?.doctorId ? true : false}
              onChange={(e) => {
                onSearch('search', e.target.value);
              }}
              onClick={handleOpen}
              textFieldStyle={SelectActivity_Style.textFieldStyle}
              startAdornment={
                <IconButton aria-label="" edge="start" disableRipple>
                  <SearchIcon rootStyle={{ color: 'grey.500' }} />
                </IconButton>
              }
              placeholder="Search activity"
            />
            <Box
              sx={SelectActivity_Style.filterIconSx}
              onClick={() => handelFilterDrawer()}
            >
              {filters.goal_id.length > 0 ? (
                <CustomFilterSltIcon />
              ) : (
                <CustomFilterIcon />
              )}
            </Box>
          </Box>
        </Box>
        {open && (
          <Box mt={2}>
            <MultiSelectToggleButton
              buttons={buttons}
              handleButton={handleButton}
            />
          </Box>
        )}
        {true && (
          <Box>
            <Typography
              sx={{
                textAlign: 'left',
                fontSize: '12px',
                fontWeight: 400,
                letterSpacing: '0px',
                color: '#5C6266',
                opacity: 1,
                mt: 2,
                mb: 1,
              }}
            >
              {`${activityData?.activityCount ?? 0} Activities`}
            </Typography>
            <Box mb={1.5}>
              {!loading && (
                <Box>
                  {activityData?.activityData?.length > 0 &&
                    activityData?.activityData?.map((val, i) => (
                      <Box key={i} pb={1.5}>
                        <AddActivityCard
                          image={val?.activity_image ?? ''}
                          activityName={val?.activity_name ?? ''}
                          onSelected={() =>
                            onCheckSelect(val, 'addActivitySelectedData')
                          }
                          selected={
                            addActivitySelectedData.some(
                              (value) => value?.id === val?.id
                            )
                              ? true
                              : false
                          }
                        />
                      </Box>
                    ))}
                  {activityData?.activityData?.length === 0 && (
                    <HomeEmptyState
                      illustartion={<FileSearchIllustration />}
                      title="No results found!"
                    />
                  )}
                </Box>
              )}
              {loading &&
                Array(10)
                  .fill(1)
                  .map((val, i) => (
                    <Box mb={1.5} key={i}>
                      <ExpertActivityCardSkeleton />
                    </Box>
                  ))}
            </Box>
          </Box>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'end', p: 2.5 }}>
          <Box
            sx={{
              position: 'fixed',
              bottom: 110,
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Box
              sx={
                open
                  ? { ...SelectActivity_Style.SecSpeedDialRootSx }
                  : { ...SelectActivity_Style.speedDialRootSx }
              }
              onClick={() => handleOpen()}
            >
              <Box alignSelf="center">
                {!open && (
                  <SearchIcon
                    style={{
                      marginTop: '5px',
                      height: '20px',
                      width: '35px',
                      color: '#fff',
                    }}
                  />
                )}
                {open && (
                  <CloseIcon
                    rootStyle={{
                      color: '#6552CC',
                      height: '15px',
                      width: '15px',
                      mt: 0.8,
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Drawer
          show={activityDrawer}
          header="Selected activities"
          drawerRightClose
          onCloseDrawer={() => setOpenactivityDrawer(false)}
          headerStyle={{
            fontSize: '16px',
            fontWeight: 600,
            color: '#101010',
            textTransform: 'capitalize',
            textAlign: 'left',
          }}
          rootStyle={SelectActivity_Style.drawerSx}
          closeStyle={{ color: '#000', height: '16px', width: '14px', mr: 0.8 }}
          childrenStyle={{ p: 1 }}
        >
          <Box p={0.5} height="40vh">
            <Typography sx={SelectActivity_Style.drawerTitleSx} mb={0.5}>
              {`${addActivitySelectedData?.length} activities`}
            </Typography>
            {addActivitySelectedData?.length > 0 &&
              addActivitySelectedData?.map((val, index) => (
                <Box pb={1.5} key={index}>
                  <AddActivityCard
                    image={val?.activity_image ?? ''}
                    activityName={val?.activity_name ?? ''}
                    onSelected={() => onCardSelect(val)}
                    selected
                  />
                </Box>
              ))}
          </Box>
        </Drawer>
        <Drawer
          show={filterDrawer}
          header="Filters"
          drawerRightClose
          onCloseDrawer={() => setfilterDrawer(false)}
          headerStyle={{
            fontSize: '16px',
            fontWeight: 600,
            color: '#101010',
            textTransform: 'capitalize',
            textAlign: 'left',
          }}
          rootStyle={SelectActivity_Style.drawerSx}
          closeStyle={{ color: '#000', height: '16px', width: '14px', mr: 0.8 }}
          childrenStyle={{ p: 1 }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={onCancel}
                // disabled={loading === true}
                buttonStyle={SelectActivity_Style.cancelBtnSx}
              >
                Reset
              </Button>
              <Button
                onClick={onApply}
                // loading={loading}
                buttonStyle={SelectActivity_Style.applyBtnSx}
              >
                Apply filter
              </Button>
            </Box>
          }
        >
          <Box py={0.5} px={1}>
            <Typography sx={SelectActivity_Style.drawerTitleSx} mb={0.4}>
              Activity tags
            </Typography>

            <Box sx={{ overflowY: 'scroll' }}>
              <MultiSelectToggleButton
                buttons={filterButtons}
                handleButton={handleClick}
              />
            </Box>
          </Box>
        </Drawer>
      </Box>
    </Screenlayout>
  );
}

SelectActivity.propTypes = {
  className: PropTypes.string,
};

import { localStorageKeys } from '@hc/utils';
import { create } from 'zustand';

export const useBasicInfoStates = create((set, get) => ({
  basicInfoState: {
    steperValue: 0,
    previousPlan: false,
    planTemplate: false,
  },

  setLocalStorage: () => {
    const { basicInfoState } = get();
    localStorage.setItem(
      localStorageKeys?.basicInfoState,
      JSON.stringify(basicInfoState)
    );
  },
  getLocalStorage: () => {
    const data = localStorage.getItem(localStorageKeys?.basicInfoState);
    const jsonData = JSON.parse(data);
    if (jsonData) {
      set({
        basicInfoState: jsonData,
      });
    }
  },

  clearBasicInfoState: () => {
    const { basicInfoState, setLocalStorage } = get();
    set({
      basicInfoState: {
        ...basicInfoState,
        steperValue: 0,
        previousPlan: false,
        planTemplate: false,
      },
    });
    setLocalStorage();
  },

  onStateUpdate: (key, value) => {
    const { basicInfoState, setLocalStorage } = get();
    set({
      basicInfoState: {
        ...basicInfoState,
        [key]: value,
      },
    });
    setLocalStorage();
  },
}));

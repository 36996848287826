export const plan_add_edit_tool_style = {
  rootSx: {
    pt: '200px',
  },
  viewOnlyBoxSx: {
    height: '28px',
    borderTop: '1px solid #E2E2E2',
    borderBottom: '2px solid #FACC15',
    bgcolor: '#FCF8E8',
    display: 'flex',
    justifyContent: 'center',
  },
  drawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    p: 2.5,
  },
  isNotApplyButtonSx: {
    textTransform: 'none',
    bgcolor: '#F44F5A',
    '&:hover': {
      bgcolor: '#F44F5A',
    },
  },
  buttonActiveNumSx: {
    border: '2px solid #EE8438',
    width: 'max-content',
    minHeight: '40px',
    borderRadius: '10px',
    padding: '5px 10px',
    bgcolor: '#FFF6F0',
    color: '#EE8438',
    '&:hover': {
      backgroundColor: '#FFF6F0',
      color: '#EE8438',
    },
  },
  buttonInactiveNumSx: {
    border: '1px solid ',
    borderRadius: '10px',
    width: 'max-content',
    bgcolor: '#FFF6F0',
    minHeight: '40px',
    padding: '5px 10px',
    color: '#5C6266',
    '&:hover': {
      backgroundColor: '#FFF6F0',
      color: '#5C6266',
    },
  },
  cancelBtnSx: {
    textTransform: 'none',
    backgroundColor: '#F3F0FF',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: '#F3F0FF',
    },
  },
  applyBtnSx: {
    textTransform: 'none',
  },
  speedDialRootSx: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    bgcolor: '#6552CC',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  SecSpeedDialRootSx: {
    height: '50px',
    boxShadow: '0px 0px 10px #00000029',
    width: '50px',
    borderRadius: '50%',
    mt: '88px',
    bgcolor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  buttonSx: {
    bgcolor: 'primary.lighter',
    borderRadius: '8px',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primary.lighter',
    },
    textTransform: 'capitalize',
  },
  steperChildRootSx: {
    pt: 1.5,
    pb: 1,
    borderRadius: '0px 0px 16px 16px',
    border: '1px solid #E2E2E2',
    boxShadow: 0,
    zIndex: '4',
  },
  tabStyleSx: {
    backgroundColor: 'transparent',
    width: ' 100%',
    margin: '0px auto',
    display: 'flex',
    '& .MuiTabs-root': {
      minHeight: 0,
    },
  },
  underTabStyleSx: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#EB6D13',
      textAlign: 'left',
    },
    '& .MuiTabs-flexContainer': {
      display: 'grid',
      gridTemplateColumns: ' 58px 60px 47px 0px',
      boxSizing: 'border-box',
      width: 425,

      gridColumnGap: '30px',
      textAlign: 'left',
    },
  },

  tabSx: {
    textTransform: 'capitalize',
    minHeight: 0,
    textAlign: 'left',
    color: '#808080',
    padding: '5px',
    fontWeight: 500,
    '&.Mui-selected': {
      textAlign: 'left',
      fontSize: '14px',
      fontWeight: 600,
      color: '#EB6D13',
      bgcolor: '#FFF6F0',
      borderRadius: '12px 12px 0px 0px',
    },
  },
};

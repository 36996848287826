export const screenlayout_style = {
  rootSx: {
    position: 'relative',
    width: '100%',
    minHeight: '100vh',
  },
  totalScreenSx: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  headerSx: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  calenderSx: {
    zoom: {
      xs: '91%',
    },
  },
  completedSx: {
    fontWeight: 600,
    color: '#25C460',
    textTransform: 'capitalize',
    opacity: 1,
  },
  dayCalenderSx: {
    overflowX: 'scroll',
    width: '300px',
  },
  stepperBarSx: {
    pl: 2.5,
    pr: 2.5,
    py: 2,
    pt: 4,
    bgcolor: '#fff',
    boxSizing: 'border-box',
    borderRadius: '0px 0px 24px 24px',
    boxShadow: '0px 0px 10px #00000029',
    position: 'fixed',
    top: 46,
    zIndex: '3',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },
  appBarSx: {
    pl: 2.5,
    pr: 2.5,
    py: 2,
    boxSizing: 'border-box',
    bgcolor: 'primary.dark',
    borderRadius: '0px 0px 24px 24px',
    boxShadow: '0px 0px 10px #00000029',
    position: 'fixed',
    top: 0,
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },

  expertAppbarSx: {
    pl: 2.5,
    pr: 2.5,
    py: 2,
    boxSizing: 'border-box',
    bgcolor: 'primary.light',
    borderRadius: '0px 0px 24px 24px',
    boxShadow: '0px 0px 10px #00000029',
    position: 'fixed',
    top: 0,
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },

  viewOnlySx: {
    pb: 2,
    pt: '269px',
    boxSizing: 'border-box',
    position: 'fixed',
    top: 0,
    zIndex: '1',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },

  doctorAppbarSx: {
    boxSizing: 'border-box',
    bgcolor: '#fff',
    borderBottom: '1px solid #E3E3E3',
    position: 'fixed',
    top: 0,
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      lg: 1024,
    },
    mx: {
      sm: 'auto',
    },
  },

  childrenSx: {
    pt: {
      // xs: 9,
    },
    pb: {
      xs: 14,
    },
  },
  doctorChildrenSx: {
    pt: {
      // xs: 8,
    },
    // pb: {
    //   xs: 14,
    // },
  },

  footerSx: {
    p: 3,
    position: 'fixed',
    bottom: 0,
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
    boxSizing: 'border-box',
    bgcolor: 'common.white',
    borderTop: '1.5px dashed',
    borderColor: 'border.main',
    boxShadow: '0px -5px 10px #0000000A',
  },

  isExpertAppFooterDrawerSx: {
    p: 2,
    position: 'fixed',
    bottom: 0,
    zIndex: '1000',
    height: '68px',
    left: 0,
    right: 0,
    mb: 10.5,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
    borderRadius: '24px 24px 0 0',
    boxSizing: 'border-box',
    bgcolor: 'common.white',
    borderTop: '1.5px dashed',
    borderColor: 'border.main',
    boxShadow: '0px -5px 10px #0000000A',
  },

  isExpertAppFooterSx: {
    p: 3,
    position: 'fixed',
    bottom: 0,
    zIndex: '1000',
    height: '70px',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
    borderRadius: '24px 24px 0 0',
    boxSizing: 'border-box',
    bgcolor: 'common.white',
    borderTop: '1.5px dashed',
    borderColor: 'border.main',
    boxShadow: '0px -5px 10px #0000000A',
  },

  titleSx: {
    fontWeight: 600,
  },
  searchFieldSx: {
    '&.MuiFormControl-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
      px: 0,
      pt: 0.7,
      backgroundColor: 'common.white',
      borderRadius: '8px',
      color: 'grey',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        border: '1.5px solid',
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        border: '1.5px solid',
        borderColor: 'border.focus',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '0px 4px 5px 10px',
      fontSize: '14px',
    },
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  searchBoxSx: {
    width: '100%',
  },
  iconSx: {
    color: 'common.white',
    p: 0,
  },
  endAdornmentSx: {
    color: 'grey.500',
    padding: '0px',
    width: '20px',
  },
  backIconSx: {
    cursor: 'pointer',
  },
  app1stRootSx: {
    pl: 2,
    pr: 2.5,

    boxSizing: 'border-box',
    pt: '120px',
    borderBottom: '1px solid #E2E2E2',
    position: 'fixed',
    top: 0,
    zIndex: '3',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },
  appSecRootSx: {
    pl: 2.5,
    pr: 2.5,
    py: 2,
    boxSizing: 'border-box',
    pt: '175px',
    bgcolor: '#fff',
    borderBottom: '1px solid #E2E2E2',
    position: 'fixed',
    top: 0,
    zIndex: '2',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },
  createNewActivitySx: {
    bgcolor: '#9382e0',
    height: '28px',
    cursor: 'pointer',
    py: '2px',
    px: '4px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileIconSx: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer ',
  },
  endCallButtonSx: {
    boxShadow: 'none',
    bgcolor: '#F44F5A',
    fontSize: '14px',
    width: '154px',
    textTransform: 'capitalize',
    textWrap: 'nowrap',
    '&:hover': {
      bgcolor: '#F44F5A',
    },
  },
};

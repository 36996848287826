export const EditActivityDetails_Style = {
  appBarStyleSx: {
    backgroundColor: 'purple.A100',
  },
  screenLayoutChildernSx: {
    pt: 3,
    pb: 8.5,
  },

  buttonSx: {
    textTransform: 'none',
    background: ' #6552CC',
    borderRadius: '8px',
    width: '100%',
    color: ' #FFFFFF',
    '&:hover': {
      background: ' #6552CC',
    },
  },
  backbuttonSx: {
    textTransform: 'none',
    background: '#F3F0FF',
    color: ' #6552CC',
    '&:hover': {
      background: '#F3F0FF',
    },
    whiteSpace: 'nowrap',
    p: 1,
  },
  expertAppbarSx: {
    pl: 2.5,
    pr: 2.5,
    py: 2,
    boxSizing: 'border-box',
    bgcolor: '#fff',
    borderRadius: '0px 0px 24px 24px',
    boxShadow: '0px 0px 10px #00000029',
    position: 'fixed',
    top: '6%',
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },

  cardContentSx: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  profileSx: {
    backgroundColor: '#FFF6F0',
    color: 'secondary.main',
    fontSize: '24px',
    fontWeight: 600,
    width: '48px',
    height: '48px',
    borderRadius: '6px',
  },
  relationSX: {
    px: 1,
  },
};

import { useExpertOnboarding } from '@hc/store';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { ProfileAdditionalInfo } from '../profileAdditionalInfo';
import { ProfilePersonalInfo } from '../profilePersonalInfo';
import { expertProfileEdit_style } from './style';

function ExpertProfileEdit(props) {
  const { className = '', rootStyle = {}, ...rest } = props;
  const { updateOnboarding } = useExpertOnboarding((state) => ({
    updateOnboarding: state.updateOnboarding,
  }));
  const handleBasicProfileChange = (key, value) => {
    updateOnboarding(key, value);
  };

  return (
    <Box
      sx={{
        ...expertProfileEdit_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <ProfilePersonalInfo showTitle handleChange={handleBasicProfileChange} />
      <ProfileAdditionalInfo
        showTitle
        handleChange={handleBasicProfileChange}
      />
    </Box>
  );
}

ExpertProfileEdit.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ExpertProfileEdit };

export const expertForgotPassword_style = {
  rootSx: {
    borderRadius: '24px 24px 0px 0px',
    px: 2.5,
    pt: 3,
    pb: 4,
    bgcolor: '#FDFFFF',
    height: '74vh',
  },
  titleSx: {
    color: 'text.primary',
    fontSize: '20px',
    fontWeight: 600,
  },
  signInSx: {
    fontWeight: 600,
    fontSize: '14px',
    cursor: 'pointer',
    opacity: 1,
    textAlign: 'start',
    color: 'expert.main',
    textDecoration: 'none',
  },
  buttonsStyle: {
    textTransform: 'none',
    height: '40px',
  },
  signInLinkSx: {
    position: 'fixed',
    top: '92%',
    left: '50%',
    marginLeft: '-220px',
    width: '425px',
    height: '200px',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
};

import { expertRoutes } from '@hc/routes';
import { useExpertRequest, useMasters, usePlanAddEditTool } from '@hc/store';
import { useByMePlan } from '@hc/store/expert/byMePlan';
import { useExpertHistory } from '@hc/store/expert/history';
import {
  BrainIcon,
  Button,
  DataTabs,
  Drawer,
  FoodIcon,
  Input,
  Label,
  MembersRightIcon,
  RuningIcon,
  ToggleButton
} from '@hc/ui/atoms';
import {
  ExpertPreviousPlanCard,
  PlanPreview,
  PlanPreviewTopBar,
  QuestionAnsList,
  Screenlayout
} from '@hc/ui/components';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { Box, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation, useNavigate } from 'react-router-dom';
import { editPlan_style } from './style';

export default function EditPlan(props) {
  const { className = '', ...rest } = props;
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);

  const [tabIndex, setTabIndex] = useState('1');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openPlanDrawer, setOpenPlanDrawer] = useState(false);
  const [openPreviousPlanDrawer, setOpenPreviousPlanDrawer] = useState(false);
  const [openChangeRequestPlanDrawer, setOpenChangeRequestPlanDrawer] =
    useState(false);
  const [openDeleteDrawer, setOpenDeleteDrawer] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  //  ---------------Request store ---------------------

  const { requestState, loading, getRequestById, getReqDataLocalStorage } =
    useExpertRequest(
      (state) => ({
        getRequestById: state.getRequestById,
        requestState: state.requestState,
        getReqDataLocalStorage: state.getReqDataLocalStorage,
        loading: state.loading,
      }),
      (prev, curr) => isEqual(prev, curr),
    );
  //  ---------------History store ---------------------
  const { historyState } = useExpertHistory(
    (state) => ({
      historyState: state.historyState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  // --------------- Master state ----------------------

  const { getPlanDurationType, mastersState } = useMasters(
    (state) => ({
      getPlanDurationType: state.getPlanDurationType,
      mastersState: state.mastersState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  // -------------- By me store -----------------------
  const { onDeleteByMeplan, clearByMeState } = useByMePlan((state) => ({
    onDeleteByMeplan: state.onDeleteByMeplan,
    clearByMeState: state.clearByMeState,
  }));

  const { planDurationType } = mastersState;

  const { planTemplateDataById, planConstructedData } = requestState;

  const {
    planAddEditToolState,
    onUpdateToolData,
    onDurationUpdation,
    getLocalStorage,
    upsertplanTemplate,
    upsertPublishOrDraftPlan,
    publishLoading,
  } = usePlanAddEditTool(
    (state) => ({
      upsertplanTemplate: state.upsertplanTemplate,
      getLocalStorage: state.getLocalStorage,
      onDurationUpdation: state.onDurationUpdation,
      onUpdateToolData: state.onUpdateToolData,
      planAddEditToolState: state.planAddEditToolState,
      upsertPublishOrDraftPlan: state.upsertPublishOrDraftPlan,
      publishLoading: state.publishLoading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  const {
    overAllConstructedPlanData,
    planDurationData,
    overAllPlanData,
    isHistory,
    isDraft,
    error,
  } = planAddEditToolState;
  const [drawerData, setDrawerData] = useState({
    plan_name: planDurationData?.plan_name,
    duration_type: planDurationData?.duration_type,
    duration: planDurationData?.duration,
  });

  const string = location?.state?.isPublishPlan
    ? planDurationData?.plan_name
    : planTemplateDataById?.planData?.plan_name;

  const length = 35;
  const trimmedString = string?.substring(0, length);
  const topBarData = {
    plan: location?.state?.isPublishPlan
      ? planDurationData?.plan_name.length <= 30
        ? planDurationData?.plan_name
        : `${trimmedString} ...`
      : planDurationData?.plan_name.length > 0
      ? planDurationData?.plan_name.length <= 30
        ? planDurationData?.plan_name
        : `${trimmedString} ...`
      : planTemplateDataById?.planData?.plan_name.length <= 30
      ? planTemplateDataById?.planData?.plan_name
      : `${trimmedString} ...`,

    duration: location?.state?.isPublishPlan
      ? `${planDurationData?.duration ?? ''} ${planDurationData?.duration_type}`
      : `${planTemplateDataById?.planData?.duration ?? ''} ${
          planTemplateDataById?.planData?.duration_type
        }`,
  };

  const breaks = [
    {
      label: (
        <Box display="flex">
          <BrainIcon color={tabIndex === '1' ? '#EE8438' : '#888888'} />
          <Typography alignSelf="center" ml="6px" fontSize={12}>
            Mind
          </Typography>
        </Box>
      ),
      value: '1',
    },
    {
      label: (
        <Box display="flex">
          <RuningIcon color={tabIndex === '2' ? '#EE8438' : '#888888'} />
          <Typography alignSelf="center" ml="6px" fontSize={12}>
            Body
          </Typography>
        </Box>
      ),
      value: '2',
    },
    {
      label: (
        <Box display="flex">
          <FoodIcon color={tabIndex === '3' ? '#EE8438' : '#888888'} />
          <Typography alignSelf="center" ml="6px" fontSize={12}>
            Food
          </Typography>
        </Box>
      ),
      value: '3',
    },
  ];

  const onNext = async () => {
    if (location?.state?.isPublishPlan) {
      if (isHistory) {
        queryClient.invalidateQueries({
          queryKey: [` plan-save-publish`],
        });
        const response = await upsertPublishOrDraftPlan(0, 'history');
        if (response?.code === 200) {
          navigate(expertRoutes?.customPlan, {
            state: {
              addtemplate: true,
            },
          });
        }
      } else if (planAddEditToolState?.masterByMe) {
        queryClient.invalidateQueries({
          queryKey: [`planTemplate-upsert-plan`],
        });
        const response = await upsertplanTemplate(2);
        if (response?.message === 'Plan Name Already Exist') {
          setOpenPlanDrawer(true);
        } else if (response?.code === 200) {
          clearByMeState();
          navigate(expertRoutes?.customPlan, {
            state: {
              addtemplate: false,
            },
          });
        }
      } else {
        queryClient.invalidateQueries({
          queryKey: [` plan-save-publish`],
        });
        const response = await upsertPublishOrDraftPlan(2);
        if (response?.code === 200) {
          navigate(expertRoutes?.customPlan, {
            state: {
              addtemplate: true,
            },
          });
        }
      }
    } else {
      setOpenDrawer(true);
    }
  };

  const onCancel = () => {
    setOpenDrawer(false);
  };

  const onProceed = async () => {
    setOpenDrawer(false);
    navigate(expertRoutes.planAddEditTool);
  };

  const isValidToSave = () => {
    const errorCopy = JSON.parse(JSON.stringify(error));
    let isValid = true;

    // checking plan name
    if (!drawerData?.plan_name.length > 0) {
      isValid = false;
      errorCopy.plan_name = 'Please enter the plan name';
    } else {
      errorCopy.plan_name = '';
    }

    // checking plan duratio
    if (!drawerData?.duration_type > 0) {
      isValid = false;
      errorCopy.duration_type = 'Please select the duration type ';
    } else {
      errorCopy.duration_type = '';
    }

    // checking plan duration name
    if (!drawerData?.duration > 0) {
      isValid = false;
      errorCopy.duration = 'Please enter the duration';
    } else {
      errorCopy.duration = '';
    }
    onUpdateToolData('error', errorCopy);
    return isValid;
  };

  const onViewProfile = async (planId) => {
    const response = await getRequestById(planId, {}, 'profile');
    if (response?.code === 200) {
      navigate(expertRoutes?.userProfile);
    }
  };

  const handleChange = (value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    // HANDLE PROFILE DATA CAHNGE
    setDrawerData({
      ...drawerData,
      duration: value,
    });
  };

  const onUpdate = () => {
    const result = isValidToSave();
    if (result) {
      onDurationUpdation('plan_name', drawerData?.plan_name);
      onDurationUpdation('duration_type', drawerData?.duration_type);
      onDurationUpdation('duration', drawerData?.duration);
      setOpenPlanDrawer(false);
    }
  };

  const onDeleteCancel = () => {
    setOpenDeleteDrawer(false);
  };

  const onDeletePlan = async () => {
    queryClient.invalidateQueries({
      queryKey: [`expert-delete-expert-plan`],
    });
    const result = await onDeleteByMeplan(overAllPlanData?.planData?.id ?? '');
    if (result?.code === 200) {
      clearByMeState();
      navigate(expertRoutes?.master);
    }
  };

  const onTopBarButtonClick = () => {
    if (planAddEditToolState?.masterByMe && !location?.state?.isPublishPlan) {
      setOpenDeleteDrawer(true);
    } else {
      setOpenPlanDrawer(true);
    }
  };

  const openRequestDrawerFnc = () => {
    setOpenChangeRequestPlanDrawer(true);
  };

  useEffect(() => {
    if (!planAddEditToolState?.masterByMe) {
      getReqDataLocalStorage();
    }
    getPlanDurationType();
    getLocalStorage();
  }, []);

  return (
    <Screenlayout
      isExpertAppBar
      appBarStyle={editPlan_style.appBarStyleSx}
      backRequired
      viewProfile={
        planAddEditToolState?.masterByDefault
          ? false
          : planAddEditToolState?.masterByMe
          ? false
          : true
      }
      onViewProfile={() =>
        onViewProfile(
          isHistory || isDraft
            ? planAddEditToolState?.userData?.plan_id
            : planAddEditToolState?.userData?.id,
        )
      }
      backRoute={
        location?.state?.isPublishPlan
          ? expertRoutes?.planAddEditTool
          : !planAddEditToolState?.isDraft &&
            !planAddEditToolState?.isHistory &&
            !planAddEditToolState?.masterByMe &&
            !planAddEditToolState?.masterByDefault
          ? expertRoutes?.basicInfoSteper
          : planAddEditToolState?.isHistory
          ? expertRoutes?.home
          : planAddEditToolState?.masterByMe
          ? expertRoutes?.master
          : planAddEditToolState?.masterByDefault
          ? expertRoutes?.master
          : -1
      }
      stepperformWithAppbar
      backIconStyle={{ color: '#6552CC' }}
      rootStyle={{ backgroundColor: '#FCFBFF' }}
      title={topBarData?.plan}
      isShowChildFooter={
        historyState?.historyRenewalData?.mbfPercentage?.length > 0 &&
        planAddEditToolState?.isHistory &&
        !location?.state?.isPublishPlan &&
        !planAddEditToolState?.masterByDefault
      }
      drawerChildrenStyle={
        historyState?.historyRenewalData?.mbfPercentage?.length > 0 &&
        planAddEditToolState?.isHistory &&
        !location?.state?.isPublishPlan
          ? historyState?.historyRenewalData?.planData?.[0]?.status === 5
            ? { mb: '' }
            : { mb: '84px' }
          : { mb: '' }
      }
      drawerChildren={
        <Box>
          {historyState?.historyRenewalData?.mbfPercentage?.length > 0 &&
            planAddEditToolState?.isHistory &&
            !location?.state?.isPublishPlan && (
              <Grid container columnSpacing={2}>
                <Grid item xs={10} sm={10} md={10}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} display="flex">
                      <Typography
                        color="#25C460"
                        fontSize={12}
                      >{`Current plan progress  ${
                        historyState?.historyRenewalData?.overAllPercentage ?? 0
                      }%`}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography fontSize={14} fontWeight={500}>
                        {topBarData?.plan}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                  <Box
                    sx={{
                      transform: 'rotate(270deg)',
                      ml: '10px',
                      mt: '-9px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setOpenPreviousPlanDrawer(true)}
                  >
                    <MembersRightIcon
                      circleFill="#F3F0FF"
                      arrowColor="#6552CC"
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
        </Box>
      }
      stepperRootStyle={editPlan_style.steperChildRootSx}
      masterchildStyle={editPlan_style.screenLayoutChildernSx}
      notshowFooter={
        historyState?.historyRenewalData?.mbfPercentage?.length > 0 &&
        planAddEditToolState?.isHistory &&
        !location?.state?.isPublishPlan
          ? historyState?.historyRenewalData?.planData?.[0]?.status === 5
            ? true
            : false
          : planAddEditToolState?.masterByDefault
          ? true
          : false
      }
      appBarFirstChild={
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <DataTabs
            isResponsive
            tabs={breaks}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            totalStyle={editPlan_style.tabStyleSx}
            underTabSx={editPlan_style.underTabStyleSx}
            tabStyle={editPlan_style.tabSx}
          />
        </Box>
      }
      stepperChildren={
        <PlanPreviewTopBar
          showOpr={
            location?.state?.isPublishPlan || planAddEditToolState?.masterByMe
          }
          plan={topBarData?.plan}
          onClick={() => onTopBarButtonClick()}
          isDelete={
            planAddEditToolState?.masterByMe && !location?.state?.isPublishPlan
          }
          duration={topBarData?.duration}
          showChangeRequestDrawer={
            tokenData?.user_profile_id ===
            historyState?.historyRenewalData?.planData?.[0]
              ?.change_request_assign_expert_id
          }
          openRequestDrawer={openRequestDrawerFnc}
        />
      }
      isDrawerFooter
      appBarFirstChildRootStyle={editPlan_style.appBarFirstChildSx}
      footerStyle={{ borderTop: '0px', p: 3 }}
      footerdDrawerChildren={
        <Grid container columnSpacing={2}>
          {!location?.state?.isPlanEdit && (
            <Grid item xs={6} sm={6} md={6}>
              <Button
                onClick={() => navigate(-1)}
                sx={editPlan_style.cancelBtnSx}
              >
                Back
              </Button>
            </Grid>
          )}

          <Grid
            item
            xs={location?.state?.isPlanEdit ? 12 : 6}
            sm={location?.state?.isPlanEdit ? 12 : 6}
            md={location?.state?.isPlanEdit ? 12 : 6}
          >
            <Button
              onClick={() => onNext()}
              buttonStyle={{ textTransform: 'capitalize' }}
              loading={location?.state?.isPublishPlan ? publishLoading : false}
            >
              {location?.state?.isPlanEdit
                ? 'Edit plan'
                : location?.state?.isPublishPlan
                ? planAddEditToolState?.isHistory
                  ? 'Save changes'
                  : 'Publish plan'
                : planAddEditToolState?.masterByMe
                ? 'Edit plan'
                : 'Use this plan'}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Box
        className={`${className}`}
        {...rest}
        mt={16.4}
        display="flex"
        bgcolor="#F7F7F7"
        justifyContent="center"
      >
        <Box
          alignSelf="center"
          width="100%"
          pb={
            historyState?.historyRenewalData?.mbfPercentage?.length > 0 &&
            planAddEditToolState?.isHistory &&
            !location?.state?.isPublishPlan
              ? historyState?.historyRenewalData?.planData?.[0]?.status !== 5
                ? 11
                : 2
              : 2
          }
        >
          <PlanPreview
            planData={
              tabIndex === '1'
                ? location?.state?.isPublishPlan
                  ? overAllConstructedPlanData?.mind?.planDayData
                  : planConstructedData?.mind?.planDayData
                : tabIndex === '2'
                ? location?.state?.isPublishPlan
                  ? overAllConstructedPlanData?.body?.planDayData
                  : planConstructedData?.body?.planDayData
                : location?.state?.isPublishPlan
                ? overAllConstructedPlanData?.food?.planDayData
                : planConstructedData?.food?.planDayData
            }
            planName={planAddEditToolState?.planDurationData?.plan_name ?? ''}
          />
        </Box>
      </Box>

      {/* Plan proceed Drawer */}
      <Drawer
        show={openDrawer}
        rootStyle={editPlan_style.drawerSx}
        childrenStyle={{ p: 0 }}
        isStyledDrawer
        IsStyledDrawerContent={
          location?.state?.isPlanEdit
            ? 'You are about to edit a plan which is in progress. Changes will be reflected to users immediately. Do you want to proceed?'
            : 'All the activities will be copied to the plan. You can edit activities. Are you sure you want to proceed?'
        }
        onCloseDrawer={() => setOpenDrawer(false)}
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button onClick={onCancel} buttonStyle={editPlan_style.cancelBtnSx}>
              Cancel
            </Button>
            <Button
              onClick={onProceed}
              loading={loading}
              buttonStyle={
                !location?.state?.isPlanEdit
                  ? { ...editPlan_style.applyBtnSx }
                  : { ...editPlan_style.isNotApplyButtonSx }
              }
            >
              Proceed to edit
            </Button>
          </Box>
        }
      />
      {/* Previous plan Drawer */}
      <Drawer
        show={openPreviousPlanDrawer}
        rootStyle={editPlan_style.drawerSx}
        header="Previous plan"
        drawerRightClose
        childrenStyle={{ p: 0 }}
        onCloseDrawer={() => setOpenPreviousPlanDrawer(false)}
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        closeStyle={{ color: 'grey', height: '14px', width: '12px' }}
      >
        <Box p={2} sx={{ height: 450, overflowX: 'scroll' }}>
          <Typography pb={1.5} fontSize={14} fontWeight={500}>
            {topBarData?.plan ?? ''}
          </Typography>
          <Box pb={2}>
            <ExpertPreviousPlanCard
              overAllProgress={
                historyState?.historyRenewalData?.overAllPercentage ?? 0
              }
              data={historyState?.historyRenewalData?.mbfPercentage ?? []}
            />
          </Box>
          <Box pb={1}>
            <Typography color="#888888" fontSize={12} fontWeight={500}>
              Client feedback on plan
            </Typography>
          </Box>
          <Box pb={1.5}>
            <QuestionAnsList
              data={
                historyState?.historyRenewalData?.mbfPercentage?.length > 0 &&
                planAddEditToolState?.isHistory &&
                !location?.state?.isPublishPlan &&
                historyState?.historyRenewalData?.planData?.[0]
                  ?.feedback_json !== null
                  ? historyState?.historyRenewalData?.planData?.[0]
                      ?.feedback_json
                  : {}
              }
            />
          </Box>
        </Box>
      </Drawer>

      {/* Change Request Description Drawer */}
      <Drawer
        show={openChangeRequestPlanDrawer}
        rootStyle={editPlan_style.drawerSxx}
        header="Change Request Description"
        drawerRightClose
        childrenStyle={{ p: 0, mb: 1 }}
        onCloseDrawer={() => setOpenChangeRequestPlanDrawer(false)}
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        closeStyle={{ color: 'grey', height: '14px', width: '12px' }}
      >
        <Box p={2} sx={{ height: 155, overflowX: 'scroll' }}>
          <Input
            id="Description"
            multiline
            textFieldStyle={{ marginTop: '4px' }}
            value={
              historyState?.historyRenewalData?.planData?.[0]
                ?.change_request_description ?? ''
            }
            fullWidth
            isReadOnly
          />
        </Box>
      </Drawer>

      {/* Plan from scratch drawer */}

      <Drawer
        show={openPlanDrawer}
        rootStyle={editPlan_style.drawerSx}
        header="Plan from scratch"
        drawerRightClose
        childrenStyle={{ p: 0 }}
        onCloseDrawer={() => setOpenPlanDrawer(false)}
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        closeStyle={{ color: 'grey', height: '14px', width: '12px' }}
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              onClick={() => setOpenDrawer(false)}
              // disabled={loading === true}
              buttonStyle={editPlan_style.cancelBtnSx}
            >
              Cancel
            </Button>
            <Button
              onClick={() => onUpdate()}
              // loading={loading}
              buttonStyle={editPlan_style.applyBtnSx}
            >
              Update
            </Button>
          </Box>
        }
      >
        <Box p={2}>
          <Grid container>
            <Grid item xs={12} pb={2} sm={12} md={12}>
              <Label>Name of the plan</Label>
              <Box mt={0.5}>
                <Input
                  value={drawerData?.plan_name}
                  onChange={(e) =>
                    setDrawerData({ ...drawerData, plan_name: e.target?.value })
                  }
                  placeholder="Give a name to identify in future"
                  isError={error?.plan_name?.length > 0}
                  errorMessage={error?.plan_name}
                  id="planName"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Label>Plan Duration</Label>
              <Box display="flex">
                <Box width={161} mt={0.5}>
                  <Input
                    value={drawerData?.duration}
                    placeholder="Type duration"
                    type="number"
                    id="planDuration"
                    onChange={(e) => handleChange(e.target.value, 2)}
                    isError={error?.duration?.length > 0}
                    errorMessage={error?.duration}
                  />
                </Box>
                <Box px={0.5}>
                  <Divider
                    orientation="vertical"
                    sx={{ height: '32px', mt: 1 }}
                  />
                </Box>

                <Box>
                  <Grid container display="flex" columnSpacing={1}>
                    <Grid item>
                      <ToggleButton
                        options={planDurationType}
                        value={drawerData?.duration_type}
                        RootStyle={{ overflow: 'scroll' }}
                        buttonactiveStyle={editPlan_style.buttonActiveNumSx}
                        buttoninactiveStyle={editPlan_style.buttonInactiveNumSx}
                        onChange={(e) =>
                          setDrawerData({
                            ...drawerData,
                            duration_type: e,
                          })
                        }
                      />
                      {error?.duration_type.length > 0 && (
                        <Typography fontSize={12} color="	#ff3333">
                          {error?.duration_type}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Drawer>

      {/* plan delete drawer */}

      <Drawer
        show={openDeleteDrawer}
        rootStyle={editPlan_style.drawerSx}
        childrenStyle={{ p: 0 }}
        onCloseDrawer={() => setOpenDeleteDrawer(false)}
        isStyledDrawer
        IsStyledDrawerContent={
          <Typography mb={2} fontSize={14}>
            Are you sure you want to Delete {planDurationData?.plan_name} ?
          </Typography>
        }
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              onClick={onDeleteCancel}
              buttonStyle={editPlan_style.cancelBtnSx}
            >
              Cancel
            </Button>
            <Button
              onClick={onDeletePlan}
              buttonStyle={editPlan_style.deleteBtnSx}
            >
              Delete
            </Button>
          </Box>
        }
      />
    </Screenlayout>
  );
}

EditPlan.propTypes = {
  className: PropTypes.string,
};

import { Button } from '@atoms';
import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { RiCloseLine } from 'react-icons/ri';
import { qusAnsListing_style } from './style';

export function QusAnsListing(props) {
  const {
    id = '',
    QusAnsListingHeading = '',
    QusAnsListingAdded = '',
    className = '',
    OnClearAll = () => {},
    OnDelete = () => {},
    displaySelectionValues = [],
    qusAnsListingStyle = {},
    qusAnsListingCardStyle = {},
    rootSx = {},
    isShowClearAll = true,
    isShowCount = true,
    QusAnsListingHeadStyle = {},
    ListCardTypoSX,
    ...rest
  } = props;

  return (
    <Box className={`${className}`} sx={{ ...rootSx }} {...rest}>
      <Box sx={{ ...qusAnsListing_style.boxTitleSx, ...qusAnsListingStyle }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="body2"
            color="textPrimary"
            sx={{ fontWeight: '600', ...QusAnsListingHeadStyle }}
          >
            {QusAnsListingHeading}
          </Typography>
          {displaySelectionValues?.length > 0 && (
            <Typography
              variant="body2"
              color="textPrimary"
              sx={{ fontWeight: '600', pl: 0.4, ...QusAnsListingHeadStyle }}
            >
              {QusAnsListingAdded}
            </Typography>
          )}
          {isShowCount && displaySelectionValues?.length > 0 && (
            <Avatar alt="" sx={qusAnsListing_style.smallSx}>
              {displaySelectionValues?.length > 0 &&
              displaySelectionValues?.length < 10
                ? `0${displaySelectionValues?.length}`
                : displaySelectionValues?.length ?? 0}
            </Avatar>
          )}
        </Box>
        {/* Clear all */}
        {isShowClearAll && displaySelectionValues?.length > 0 && (
          <Button
            id="clearAll"
            onClick={OnClearAll}
            buttonStyle={qusAnsListing_style.clearBtnSx}
          >
            <Typography
              color="error"
              variant="caption"
              sx={{ fontWeight: 500 }}
            >
              CLEAR ALL
            </Typography>
          </Button>
        )}
      </Box>

      {/* Listing Box */}
      <Box>
        {displaySelectionValues &&
          displaySelectionValues?.length > 0 &&
          displaySelectionValues?.map((item, index) => (
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              flexWrap="nowrap"
              key={index}
              sx={{ ...qusAnsListing_style.cardSx, ...qusAnsListingCardStyle }}
            >
              <Grid item display="flex">
                {' '}
                <Typography
                  id={id}
                  alignSelf="center"
                  variant="body1"
                  color="textPrimary"
                  sx={{ fontWeight: 500, ...ListCardTypoSX }}
                >
                  {typeof item === 'string'
                    ? item
                    : item?.labelShow || item?.label}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => OnDelete(index)}
                  aria-label="settings"
                  id={`close${id}${index}`}
                >
                  <RiCloseLine
                    sx={{ fontSize: '8px', color: 'common.black' }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          ))}
      </Box>
    </Box>
  );
}
QusAnsListing.propTypes = {
  id: PropTypes.string,
  QusAnsListingHeading: PropTypes.string,
  QusAnsListingAdded: PropTypes.string,
  className: PropTypes.object,
  OnClearAll: PropTypes.func,
  OnDelete: PropTypes.func,
  displaySelectionValues: PropTypes.array,
  qusAnsListingStyle: PropTypes.object,
  qusAnsListingCardStyle: PropTypes.object,
  rootSx: PropTypes.object,
  isShowClearAll: PropTypes.bool,
  isShowCount: PropTypes.bool,
  QusAnsListingHeadStyle: PropTypes.object,
  ListCardTypoSX: PropTypes.string,
};

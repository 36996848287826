export const user_profile_style = {
  buttonSx: {
    bgcolor: 'primary.lighter',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primary.lighter',
    },
    textTransform: 'capitalize',
  },
  drawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    p: 2.5,
  },
};

import { Avatar, Box, Grid, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  BrainIcon,
  CalenderColorIcon,
  FoodIcon,
  InprogressBadgeIcon,
  LeftArrowClickIcon,
  RuningIcon,
  TickBadgeIcon,
} from '../../../atoms';
import { expertRequestCard_style } from './style';

function ExpertRequestCard(props) {
  const {
    className = '',
    rootStyle = {},
    planStatus = 'New',
    requestDuration = '2 days ago',
    lastSavedDuration = '4 Hours ago',
    isDraftCard,
    isHistoryCard,
    changeRequestDescription = '',
    historyStatus = 'Inprogress',
    HistoryDate = '01 Feb 2023, 10:31 AM',
    avatar,
    userName = 'Kakashi Hatake',
    gender = 'M',
    age = 26,
    mind = 'Completed',
    body = 'Completed',
    food = 'Completed',
    onClick = () => {},
    ...rest
  } = props;

  const progressData = [
    {
      name: 'Mind',
      icon: (
        <BrainIcon
          color={
            mind === 'Not yet'
              ? '#888888'
              : mind === 'Inprogress'
              ? '#6552CC'
              : '#25C460'
          }
        />
      ),
      progressValue: mind,
      subIcon:
        mind === 'Completed' ? <TickBadgeIcon /> : <InprogressBadgeIcon />,
    },
    {
      name: 'Body',
      icon: (
        <RuningIcon
          color={
            body === 'Not yet'
              ? '#888888'
              : body === 'Inprogress'
              ? '#6552CC'
              : '#25C460'
          }
        />
      ),
      progressValue: body,
      subIcon:
        body === 'Completed' ? <TickBadgeIcon /> : <InprogressBadgeIcon />,
    },
    {
      name: 'Food',
      icon: (
        <FoodIcon
          color={
            food === 'Not yet'
              ? '#888888'
              : food === 'Inprogress'
              ? '#6552CC'
              : '#25C460'
          }
        />
      ),
      progressValue: food,
      subIcon:
        food === 'Completed' ? <TickBadgeIcon /> : <InprogressBadgeIcon />,
    },
  ];

  return (
    <Box
      sx={{
        ...expertRequestCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box
        sx={expertRequestCard_style.requestCardRootSx}
        onClick={onClick}
        id="card"
      >
        <Grid
          container
          justifyContent="space-between"
          display="flex"
          alignItems="center"
        >
          <Grid item display="flex" alignItems="center">
            {requestDuration && !isHistoryCard && (
              <Typography
                sx={{
                  fontStyle: 'italic',
                  color: '#5C6266',
                  fontSize: '10px',
                  display: 'contents',
                }}
              >
                {requestDuration}
              </Typography>
            )}
            {isHistoryCard && (
              <Box display="flex" alignItems="center">
                <CalenderColorIcon rootStyle={{ color: '#888888' }} />
                <Typography
                  sx={{
                    color: '#5C6266',
                    fontSize: '10px',

                    mb: 0.2,
                    ml: 1,
                  }}
                >
                  {HistoryDate}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item display="flex" alignItems="center">
            {changeRequestDescription?.length > 0 && (
              <div style={{ marginRight: '8px' }}>
                {' '}
                <Tooltip title={changeRequestDescription}>
                  <Typography
                    sx={{
                      fontStyle: 'italic',
                      fontSize: '10px',
                      fontWeight: 500,
                      color: '#6856cd',
                      display: 'contents',
                      mr: 2,
                    }}
                  >
                    Change Request
                  </Typography>
                </Tooltip>
              </div>
            )}
            {isHistoryCard && (
              <Typography
                sx={{
                  fontStyle: 'italic',
                  color: historyStatus === 'Inprogress' ? '#FF980E' : '#5C6266',
                  fontSize: '10px',
                  display: 'contents',
                }}
                fontWeight={500}
              >
                {historyStatus}
              </Typography>
            )}
            {planStatus && !isHistoryCard && (
              <Box
                px={1}
                py={0.4}
                sx={
                  planStatus === 'New'
                    ? { bgcolor: '#DEF5FC', borderRadius: 1 }
                    : { bgcolor: '#E1FFF3', borderRadius: 1 }
                }
              >
                <Typography
                  fontSize={10}
                  fontWeight={500}
                  color={planStatus === 'New' ? '#1F91B4' : '#1FB476'}
                >
                  {planStatus}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid mt={1} container columnSpacing={1}>
          <Grid item display="flex" xs={2} md={1.5} sm={1.5}>
            <Avatar
              sx={{ height: '44px', width: '44px', alignSelf: 'center' }}
              src={avatar}
            >
              {userName.slice(0, 1) ?? 'A'}
            </Avatar>
          </Grid>
          <Grid item xs={10} md={10.5} sm={10.5}>
            <Grid container p={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container display="flex" justifyContent="space-between">
                  <Grid item>
                    <Typography fontSize={14} fontWeight={500}>
                      {userName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {isHistoryCard && (
                      <LeftArrowClickIcon rootStyle={{ color: '#6552CC' }} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography fontSize={12} color="#888888">{`${
                  gender.slice(0, 1) ?? 'A'
                } / ${age} yrs`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!isHistoryCard && (
          <Grid
            mt={1}
            px={1}
            container
            display="flex"
            justifyContent="space-between"
          >
            {progressData.map((data, i) => (
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                key={i}
                borderRight={
                  data.name === 'Mind' || data.name === 'Body'
                    ? '1px solid #E3E3E3'
                    : ''
                }
                // sx={
                //   data.name === 'Mind' || data.name === 'Body'
                //     ? { borderRight: '1px solid #E3E3E3' }
                //     : ''
                // }
              >
                <Box
                  display="flex"
                  justifyContent={
                    data.name === 'Body'
                      ? 'center'
                      : data.name === 'Food'
                      ? 'end'
                      : 'start'
                  }
                  p={
                    data.name === 'Mind' || data.name === 'Food'
                      ? '3.2px'
                      : '6.6px'
                  }
                >
                  <Box sx={{ position: 'relative' }}>
                    <Box>{data?.icon}</Box>

                    <Box
                      sx={
                        data.name === 'Mind'
                          ? data.progressValue === 'Completed'
                            ? {
                                position: 'absolute',
                                zIndex: 2,
                                marginTop: '-22px',
                                marginLeft: '10px',
                              }
                            : {
                                position: 'absolute',
                                zIndex: 2,
                                marginTop: '-29px',
                              }
                          : data.name === 'Body'
                          ? data.progressValue === 'Completed'
                            ? {
                                position: 'absolute',
                                zIndex: 2,
                                marginTop: '-19px',
                                marginLeft: '7px',
                              }
                            : {
                                position: 'absolute',
                                zIndex: 2,
                                marginTop: '-27px',
                                marginLeft: '-4px',
                              }
                          : data.name === 'Food'
                          ? data.progressValue === 'Completed'
                            ? {
                                position: 'absolute',
                                zIndex: 2,
                                marginTop: '-22px',
                                marginLeft: '13px',
                              }
                            : {
                                position: 'absolute',
                                zIndex: 2,
                                marginTop: '-29px',
                                marginLeft: '1px',
                              }
                          : ''
                      }
                    >
                      {data.subIcon}
                    </Box>
                  </Box>
                  <Typography
                    ml={0.7}
                    color={
                      data.progressValue === 'Not yet'
                        ? '#888888'
                        : data.progressValue === 'Inprogress'
                        ? '#6552CC'
                        : '#25C460'
                    }
                    alignSelf="center"
                    fontWeight={500}
                    fontSize={12}
                  >
                    {data.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      {isDraftCard && (
        <Box
          display="flex"
          sx={{
            p: 0.5,
            borderRadius: '0 0 8px 8px',
            pl: 1,
            // boxShadow:
            //   'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
            boxShadow:
              'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 0px 2px -4px',
          }}
        >
          <Typography fontSize={12} color="#888888">
            Last saved :
          </Typography>{' '}
          <Typography fontSize={12} color="#888888" pl={0.5}>
            {lastSavedDuration}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

ExpertRequestCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  planStatus: PropTypes.string,
  requestDuration: PropTypes.string,
  avatar: PropTypes.string,
  userName: PropTypes.string,
  gender: PropTypes.string,
  age: PropTypes.number,
  mind: PropTypes.string,
  body: PropTypes.string,
  food: PropTypes.string,
  lastSavedDuration: PropTypes.string,
  isDraftCard: PropTypes.bool,
  isHistoryCard: PropTypes.bool,
  historyStatus: PropTypes.string,
  changeRequestDescription: PropTypes.string,
  HistoryDate: PropTypes.string,
  onClick: PropTypes.func,
};

export { ExpertRequestCard };

export const new_request_stepper_style = {
  rootSx: {
    pt: 14,
    pb: 4,
  },
  buttonSx: {
    bgcolor: 'primary.lighter',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primary.lighter',
    },
    textTransform: 'capitalize',
  },
  drawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    p: 2.5,
  },
  cancelBtnSx: {
    textTransform: 'none',
    backgroundColor: '#F3F0FF',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: '#F3F0FF',
    },
  },
  applyBtnSx: {
    textTransform: 'none',
  },
  buttonActiveNumSx: {
    border: '2px solid #EE8438',
    width: 'max-content',
    minHeight: '40px',
    borderRadius: '10px',
    padding: '5px 10px',
    bgcolor: '#FFF6F0',
    color: '#EE8438',
    '&:hover': {
      backgroundColor: '#FFF6F0',
      color: '#EE8438',
    },
  },
  buttonInactiveNumSx: {
    border: '1px solid ',
    borderRadius: '10px',
    width: 'max-content',
    bgcolor: '#FFF6F0',
    minHeight: '40px',
    padding: '5px 10px',
    color: '#5C6266',
    '&:hover': {
      backgroundColor: '#FFF6F0',
      color: '#5C6266',
    },
  },
};

import { QueryClient } from '@tanstack/react-query';
import { config } from '@lib/config/envConfig';
import { httpRequest } from '@hc/utils/axios';

export const defaultQueryFn = async ({ queryKey }) => {
  const { data } = await httpRequest(
    queryKey[1] ? queryKey[1] : 'post',
    `${config.api_url}${queryKey[0]}`,
    queryKey[2] ? queryKey[2] : {},
    true
  );
  return data;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
});

export const add_profile_style = {
  buttonRootSx: {
    textTransform: 'capitalize',
  },
  appBarSx: {
    bgcolor: '#fff ',
  },
  buttonSx: {
    bgcolor: 'primary.lighter',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primary.lighter',
    },
    textTransform: 'capitalize',
  },
};

export const ActivityNotes_style = {
  rootSx: {
    mt: 6,
    p: 2,
  },
  boxRootSx: {
    display: 'grid',
    gap: 2,
    pt: 1,
  },
  appBarStyleSx: {
    backgroundColor: 'purple.A100',
  },
  screenLayoutChildernSx: {
    pt: 3,
    pb: 8.5,
  },

  buttonSx: {
    background: ' #6552CC',
    borderRadius: '8px',
    width: '150px',
    color: ' #FFFFFF',
    '&:hover': {
      background: ' #6552CC',
    },
  },
  backbuttonSx: {
    width: '150px',
    background: '#F3F0FF',
    color: ' #6552CC',
    '&:hover': {
      background: '#F3F0FF',
    },
  },
  expertAppbarSx: {
    pl: 2.5,
    pr: 2.5,
    py: 2,
    boxSizing: 'border-box',
    bgcolor: '#fff',
    borderRadius: '0px 0px 24px 24px',
    boxShadow: '0px 0px 10px #00000029',
    position: 'fixed',
    top: '6%',
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },
};

import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { expertPlanCalendar_style } from './style';

function ExpertPlanCalendar(props) {
  const {
    className = '',
    rootStyle = {},
    onSelect = () => {},
    value,
    data = [],
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...expertPlanCalendar_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {data.map((val, i) => (
        <Box
          key={i}
          mx={0.7}
          sx={
            value === i
              ? { ...expertPlanCalendar_style.calendarActiveParentRootSx }
              : { ...expertPlanCalendar_style.calendarParentRootSx }
          }
          onClick={() => onSelect(i)}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
            >
              <Box sx={expertPlanCalendar_style.calendarChildRootSx}>
                <Typography
                  alignSelf="center"
                  color={value === i ? '#EE8438' : '#000'}
                  fontSize={12}
                  fontWeight={500}
                >
                  {val?.day.charAt(0) + val?.day.slice(1).toLowerCase()}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
            >
              <Box>
                <Typography
                  textAlign="center"
                  color={value === i ? '#fff' : '#5C6266'}
                  fontSize={12}
                >
                  {val?.taskCount}
                </Typography>
                <Typography
                  color={value === i ? '#fff' : '#5C6266'}
                  fontSize={8}
                >
                  Task
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
}

ExpertPlanCalendar.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  data: PropTypes.array,
  onSelect: PropTypes.func,
  value: PropTypes.number,
};

export { ExpertPlanCalendar };

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { ProgressWithLabel_style } from './style';

export function CircularProgressWithLabel(props) {
  const {
    size = {},
    moodState = '',
    thickness = {},
    typo = {},
    value = 0,
    image = '',
    className = '',
    color = { mainColor: '#6552CC', backgroundColor: '#F3F0FF' },
    centerIcon = false,
    centerLabel = false,
    isMoodState = false,
    isPercenteageValue = false,
    ...rest
  } = props;
  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={[
            ProgressWithLabel_style.circularSx,
            { color: color.backgroundColor },
          ]}
          size={size}
          thickness={thickness}
          value={100}
        />
        <Box sx={ProgressWithLabel_style.circularbarSx}>
          <CircularProgress
            sx={{
              strokeLinecap: 'round',
              color: color.mainColor,
            }}
            variant="determinate"
            value={value}
            size={size}
            thickness={thickness}
          />

          <Box>
            {centerLabel && (
              <Typography
                sx={{
                  ...ProgressWithLabel_style.valueSx,
                  fontSize: '18px',
                  color: 'mbf.main',
                  ...typo,
                }}
              >
                {centerLabel ?? ''}
              </Typography>
            )}
            {!centerIcon && !centerLabel && (
              <Typography
                sx={{
                  ...ProgressWithLabel_style.valueSx,
                  color: color.mainColor,
                  ...typo,
                }}
              >
                {value}
              </Typography>
            )}
            {centerIcon && (
              <Box sx={ProgressWithLabel_style.valueSx}>
                <Avatar src={image} />
              </Box>
            )}
          </Box>
        </Box>

        <Box width={size} sx={{ textAlign: 'center' }}>
          {isPercenteageValue && (
            <Typography
              variant="h6"
              sx={ProgressWithLabel_style.mbfPercentageLabelSx}
              color={color.mainColor}
            >
              {`${value}%`}
            </Typography>
          )}

          {isMoodState && (
            <Typography color="black" component="div">
              {moodState}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
CircularProgressWithLabel.propTypes = {
  size: PropTypes.object,
  typo: PropTypes.object,
  value: PropTypes.object,
  thickness: PropTypes.object,
  moodState: PropTypes.string,
  centerIcon: PropTypes.bool,
  image: PropTypes.string,
  color: PropTypes.arrayOf(
    PropTypes.shape({
      backgroundColor: PropTypes.string,
      mainColor: PropTypes.string,
    }),
  ),
  centerLabel: PropTypes.bool,
  isMoodState: PropTypes.bool,
  isPercenteageValue: PropTypes.bool,
  className: PropTypes.string,
};

export const master_style = {
  rootSx: { height: '80vh' },
  tabButtonSx: {
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'capitalize',
    color: '#5C6266',
    '&.Mui-selected': {
      color: '#6552cc',
    },
  },
};

export const Byme_style = {
  SearchSelectSx: {
    display: 'grid',
    width: '100%',
    // gridTemplateColumns: '80% 15%',
    boxSizing: 'border-box',

    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    // mt: 2,
  },
  speedDialRootSx: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    bgcolor: '#6552CC',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  buttonActiveNumSx: {
    border: '2px solid #EE8438',
    width: 'max-content',
    minHeight: '40px',
    borderRadius: '10px',
    padding: '5px 10px',
    bgcolor: '#FFF6F0',
    color: '#EE8438',
    '&:hover': {
      backgroundColor: '#FFF6F0',
      color: '#EE8438',
    },
  },
  buttonInactiveNumSx: {
    border: '1px solid ',
    borderRadius: '10px',
    width: 'max-content',
    bgcolor: '#FFF6F0',
    minHeight: '40px',
    padding: '5px 10px',
    color: '#5C6266',
    '&:hover': {
      backgroundColor: '#FFF6F0',
      color: '#5C6266',
    },
  },

  textFieldStyle: {
    width: '100%',
    marginTop: '4px',
    '& .MuiInputBase-root': { backgroundColor: '#fff', borderRadius: '8px' },
    '& .MuiOutlinedInput-input': {
      overflow: 'hidden',
      width: '100%',
      fontSize: '14px',
      fontWeight: '500',
    },
  },
  filterIconSx: {
    border: '1px solid #DBEAE8',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    height: '38px',
    width: '40px',
    ml: 'auto',
    cursor: 'pointer',
    mt: 0.4,
  },
  drawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    p: 2.5,
  },
  cancelBtnSx: {
    textTransform: 'none',
    backgroundColor: '#F3F0FF',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: '#F3F0FF',
    },
  },
  applyBtnSx: {
    textTransform: 'none',
  },
  onTimeSx: {
    textAlign: 'left',
    font: '12px/18px Poppins',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#000',
    py: 1,
  },
  buttonactivenumSX: {
    border: '1px solid',
    minWidth: '36px',
    minHeight: '40px',
    borderRadius: '10px',
    padding: '5px 10px',
    backgroundColor: '#EE8438',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#EE8438',
      color: '#fff',
    },
  },
  buttoninactivenumSX: {
    border: '1px solid ',
    borderColor: '#E3E3E3',
    borderRadius: '10px',
    minWidth: '36px',
    minHeight: '40px',
    padding: '5px 10px',
    color: '#5C6266',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#5C6266',
    },
  },
};

import { ExpertLoginTopImage } from '@hc/ui/atoms';
import { ExpertSignin } from '@hc/ui/components/expert/expertSignin';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { signIn_style } from './style';

export default function ExpertLogin(props) {
  const { className = '', ...rest } = props;

  return (
    <Box sx={signIn_style.rootSx} className={`${className}`} {...rest}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={signIn_style.imgContSx}>
            <ExpertLoginTopImage rootStyle={signIn_style.imgSx} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box>
            <ExpertSignin />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

ExpertLogin.propTypes = {
  className: PropTypes.string,
};

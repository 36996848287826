export const expertCreateAccount_style = {
  rootSx: {
    borderRadius: '24px 24px 0px 0px',
    px: 2.5,
    pt: 3,
    pb: 4,
    bgcolor: '#FDFFFF',
    // display: 'grid',
    // gap: 2,
    height: '74vh',
  },
  titleSx: {
    color: 'text.primary',
    fontSize: '20px',
    fontWeight: 600,
  },

  buttonsStyle: {
    textTransform: 'none',
    height: '40px',
  },
  eyeSx: {
    color: '#848484',
  },
};

import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Toaster } from 'react-hot-toast';
import { Outlet } from 'react-router-dom';
import { expertRootLayout_style } from './style';

function ExpertRootLayout(props) {
  const {
    className = '',
    rootStyle = {},
    backgroundStyle = {},
    ...rest
  } = props;

  return (
    <Box
      sx={{ ...expertRootLayout_style.backgroundSx, ...backgroundStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box>
        <Toaster />
        <Box sx={{ ...expertRootLayout_style.rootSx, ...rootStyle }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

ExpertRootLayout.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  backgroundStyle: PropTypes.object,
};

export { ExpertRootLayout };

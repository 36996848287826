export const healthConditionChips_style = {
  boxRootSx: {
    bgcolor: '#fff',
    border: '1px solid #EEEEEE',
    borderRadius: '8px',
    p: 2,
  },
  shipsSx: {
    bgcolor: '#EEEEEE',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    p: 0.5,
    mr: 1,
  },
};

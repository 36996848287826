/* eslint-disable jsx-a11y/anchor-is-valid */
import { expertRoutes } from '@hc/routes';
import { useExpertOnboarding } from '@hc/store';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { Button, ExpertLoginSplashLogo, Input, Label } from '../../../atoms';
import { expertForgotPassword_style } from './style';

function ExpertForgotPasswordLink(props) {
  const { className = '', rootStyle = {}, ...rest } = props;
  const navigate = useNavigate();
  const { expertOnboardingState, updateOnboarding, forgotPassword, loading } =
    useExpertOnboarding((state) => ({
      expertOnboardingState: state.expertOnboardingState,
      forgotPassword: state.forgotPassword,
      loading: state.loading,
      updateOnboarding: state.updateOnboarding,
    }));
  const isValidToSave = () => {
    const expertUserCopy = JSON.parse(JSON.stringify(expertOnboardingState));
    const errorCopy = expertOnboardingState?.error;
    let isValid = true;

    // checking email
    const filter = /\S+@\S+\.\S+/;
    if (
      !expertUserCopy?.email?.length > 0 &&
      !filter.test(expertUserCopy?.email)
    ) {
      isValid = false;
      errorCopy.email = 'Please enter the valid mail';
    } else {
      errorCopy.email = '';
    }

    updateOnboarding('error', errorCopy);
    return isValid;
  };

  const handleChange = (key, value) => {
    updateOnboarding(key, value);
  };

  const onForgotPassword = async () => {
    const result = isValidToSave();
    if (result) {
      const reponse = await forgotPassword();
      if (reponse?.code === 200) {
        navigate(expertRoutes?.signIn);
      }
    }
  };

  return (
    <Box
      sx={{
        ...expertForgotPassword_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid container rowSpacing={2}>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <ExpertLoginSplashLogo />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <Typography variant="h5" sx={expertForgotPassword_style.titleSx}>
            Forgot Password
          </Typography>
        </Grid>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <Typography fontSize={14} color="#5C6266">
            Provide us the registered email to reset your password.
          </Typography>
        </Grid>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <Label htmlFor="Email" isRequired>
            Email
          </Label>
          <Input
            placeholder="Email"
            value={expertOnboardingState?.email ?? ''}            
            isError={expertOnboardingState?.error?.email?.length > 0}
            errorMessage={expertOnboardingState?.error?.email}
            onChange={(e) => handleChange('email', e.target.value)}
          />
        </Grid>

        <Grid item mt={2} sm={12} md={12} lg={12} xs={12}>
          <Button
            id="getLink"
            fullWidth
            onClick={() => onForgotPassword()}
            loading={loading}
            buttonStyle={expertForgotPassword_style.buttonsStyle}
          >
            Get Link
          </Button>
        </Grid>
        <Grid item alignSelf="end" mt={2} sm={12} md={12} lg={12} xs={12}>
          <Box sx={expertForgotPassword_style.signInLinkSx}>
            <Typography fontSize={14} pr={1} color="#5C6266">
              Remembered the password?
            </Typography>
            <Link
              to={expertRoutes.signIn}
              style={{ width: 'max-content', m: 1, color: '#6552CC' }}
            >
              <Typography
                sx={expertForgotPassword_style.signInSx}
                variant="subtitle2"
              >
                Sign in
              </Typography>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

ExpertForgotPasswordLink.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ExpertForgotPasswordLink };

import { Box, Grid, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { expertTemplateCardSkeleton_style } from './style';

function ExpertTemplateCardSkeleton(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  return (
    <Box
      sx={{
        ...expertTemplateCardSkeleton_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={expertTemplateCardSkeleton_style.cardRootSx}>
        <Grid container>
          <Grid item xs={12}>
            <Skeleton
              variant="rounded"
              sx={{ borderRadius: 2, background: '#e0ddf3' }}
              width="100%"
              height={60}
            />
          </Grid>
          <Grid item xs={12}py={1.5}>
            <Box display="flex" justifyContent="space-around" height={60}>
              {Array(3)
                .fill(1)
                .map((val, i) => (
                  <Box alignSelf="center" key={i}>
                    <Skeleton
                      variant="rounded"
                      sx={{ borderRadius: 2, background: '#e0ddf3' }}
                      width={100}
                      height={30}
                    />
                  </Box>
                ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

ExpertTemplateCardSkeleton.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ExpertTemplateCardSkeleton };


import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ExpertWelcome } from '../../../atoms';
import { expertVerification_style } from './style';

function ExpertVerification(props) {
  const { className = '', expertName = '', rootStyle = {}, ...rest } = props;

  return (
    <Box
      sx={{
        ...expertVerification_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Typography sx={expertVerification_style.doctorSx}>
          {`Welcome  ${expertName}`}
        </Typography>
        <Typography sx={expertVerification_style.descriptionSx}>
          We&apos;ve received your account details.
        </Typography>
        <ExpertWelcome sx={expertVerification_style.illustartionSx} />
        <Typography sx={expertVerification_style.descriptionSx}>
          We are verifying your documents, will notify you shortly after
          <br />
          the verification process is completed.
        </Typography>
      </Box>
    </Box>
  );
}

ExpertVerification.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  expertName: PropTypes.string,
};

export { ExpertVerification };

import { Avatar, Box, Checkbox, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ExpertCheckIcon, ExpertunCheckIcon } from '../../../atoms';
import { addActivityCard_style } from './style';

function AddActivityCard(props) {
  const {
    className = '',
    rootStyle = {},
    activityName = 'Jogging',
    image = '',
    selectable = true,
    onSelected = () => {},
    selected = false,
    ...rest
  } = props;

  const imageUrl = image;
  const imageUrlRegex = /\.(jpeg|jpg|gif|png)$/i;
  const isValidImageUrl = imageUrlRegex.test(imageUrl);

  return (
    <Box
      sx={{
        ...addActivityCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          border: '1px solid #E2E2E2',
          borderRadius: '8px',
          px: 1.5,
          py: 1.5,
        }}
      >
        <Stack flexDirection="row" alignItems="center">
          <Box sx={addActivityCard_style.cardContentSx}>
            <Box
              sx={{
                backgroundColor: '#F5F5F5',
                borderRadius: '10px',
              }}
            >
              <Avatar
                sx={{
                  height: '44px',
                  width: '44px',
                  borderRadius: '8%',
                  alignSelf: 'center',
                  bgcolor: '#888888',
                }}
                src={isValidImageUrl ? image : ''}
              >
                {activityName.slice(0, 1) ?? 'A'}
              </Avatar>
            </Box>
            <Box sx={addActivityCard_style.relationSX}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: '500',
                  textAlign: 'left',
                  fontSize: '14px',
                  color: '#0E1824',
                }}
              >
                {activityName}
              </Typography>
            </Box>
          </Box>
        </Stack>
        <Stack flexDirection="row" alignItems="center">
          {selectable && (
            <Checkbox
              id={activityName}
              disableRipple
              label="CheckCircleIcon"
              onClick={onSelected}
              checked={selected}
              icon={<ExpertunCheckIcon />}
              checkedIcon={<ExpertCheckIcon />}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
}

AddActivityCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  activityName: PropTypes.string,
  image: PropTypes.string,
  selectable: PropTypes.bool,
  onSelected: PropTypes.func,
  selected: PropTypes.bool,
};

export { AddActivityCard };

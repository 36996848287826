/* eslint-disable no-sparse-arrays */
// import { envConfig } from '@hc/config';
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import toast from 'react-hot-toast';
import { create } from 'zustand';

export const useByMePlan = create((set, get) => ({
  useByMePlanState: {
    planMasterByMeData: [],
    planMasterByMeDataCount: 0,
    planMasterlimit: 4,
  },
  loading: null,

  // GET ALL ACTIVITY TEMPLATE DATA
  getAllByMePlanListData: async (searchValue, offset, isSearch) => {
    const { useByMePlanState } = get();
    const { planMasterByMeData, planMasterlimit } = useByMePlanState;
    try {
      queryClient.invalidateQueries({
        queryKey: [`plan-Byme-list-${offset}`],
      });
      set({ loading: true });
      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: [`plan-Byme-list-${offset}`],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/expert/expert-plan-master-list-byme`,
            {
              search: searchValue,
              limit: planMasterlimit,
              offset,
            },
            true
          );

          return response;
        },
        staleTime: Infinity,
      });

      if (status?.code === 200) {
        if (isSearch) {
          set(() => ({
            useByMePlanState: {
              ...useByMePlanState,
              planMasterByMeData: data?.data ?? [],
              planMasterByMeDataCount: data.count ?? 0,
            },
            loading: false,
          }));
        } else {
          set(() => ({
            useByMePlanState: {
              ...useByMePlanState,
              planMasterByMeData: [...planMasterByMeData, ...data.data],
              planMasterByMeDataCount: data.count ? +data?.count : 0,
            },
          }));
        }
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  onDeleteByMeplan: async (plan_id) => {
    try {
      set({ loading: true });
      const { status } = await queryClient.fetchQuery({
        queryKey: [`expert-delete-expert-plan`],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/expert/delete-expert-plan`,
            {
              plan_id,
            },
            true
          );

          return data;
        },
        staleTime: Infinity,
      });

      set(() => ({ loading: false }));
      if (status?.code === 200) {
        toast.success(status?.message);
      }
      return status;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  handlePlanChange: (key, value) => {
    set({
      [key]: value,
    });
  },

  clearByMeState: () => {
    const { useByMePlanState } = get();
    set({
      useByMePlanState: {
        ...useByMePlanState,
        planMasterByMeData: [],
        planMasterByMeDataCount: 0,
      },
    });
  },
}));

export const edit_profile_style = {
  buttonSx: {
    bgcolor: 'primary.lighter',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primary.lighter',
    },
    textTransform: 'capitalize',
  },
};

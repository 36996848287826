import { Box, Divider, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { RightArrowIcon } from '../../../atoms';
import { selectActivitiesFooter_style } from './style';

function SelectActivitiesFooter(props) {
  const {
    className = '',
    rootStyle = {},
    activities = 0,
    viewOnClick = () => {},
    proceedOnClick = () => {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...selectActivitiesFooter_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Stack flexDirection="row" sx={selectActivitiesFooter_style.containerSx}>
        <Box>
          <Typography sx={selectActivitiesFooter_style.selectedFont}>
            Selected
          </Typography>
          <Typography sx={selectActivitiesFooter_style.activityFont}>
            {activities}&nbsp;Activities
          </Typography>
        </Box>
        <Stack flexDirection="row" gap={1} alignItems="center">
          <Typography
            sx={selectActivitiesFooter_style.viewFont}
            onClick={viewOnClick}
          >
            View
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: '#fff', width: '2px', mx: 1 }}
          />
          <Typography
            id="Proceed"
            sx={selectActivitiesFooter_style.proceedFont}
            onClick={proceedOnClick}
          >
            Proceed
          </Typography>
          <RightArrowIcon
            rootStyle={{
              color: '#fff',
              width: '12px',
              height: '12px',
              cursor: 'pointer',
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

SelectActivitiesFooter.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  activities: PropTypes.number,
  viewOnClick: PropTypes.func,
  proceedOnClick: PropTypes.func,
};

export { SelectActivitiesFooter };

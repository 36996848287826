export const expertRequestCardList_style = {
  searchFiledSx: {
    '& .MuiOutlinedInput-root': {
      bgcolor: '#fff',
      py: 0.3,
      color: '#C5C5C5',
      borderRadius: '8px',
      borderColor: '#EEEEEE',
      '&.Mui-focused fieldset': {
        // borderColor: '#EEEEEE',
        color: '#0E1824',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: '#EEEEEE',
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      color: '#0E1824',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#EEEEEE',
    },
  },
  inputGroubSx: {
    pl: 2.5,
    pr: 2.5,
    boxSizing: 'border-box',
    pt: '135px',
    bgcolor: '#f7f7f7',
    position: 'fixed',
    top: 0,
    zIndex: '3',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },
  filterSx: {
    bgcolor: '#fff',
    height: '42px',
    width: '42px',
    borderRadius: '8px',
    border: '1px solid #EEEEEE',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    alignItems: 'center',
  },
  centerIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

import { Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { DateRangePicker } from '../datePicker';
import { weeklyCalendar_Style } from './style';

export function WeeklyCalendar(props) {
  const {
    startDate = '',
    endDate = '',
    leftIcon = true,
    iconStyle = {},
    readOnly = false,
    onWeekChange = () => {},
    className = '',
    rightIcon = true,
    ...rest
  } = props;
  return (
    <Box
      display="flex"
      alignItems="center"
      className={`${className}`}
      {...rest}
    >
      {leftIcon && (
        <IconButton sx={{ p: 0 }} disableRipple>
          <BiChevronLeft
            onClick={() => {
              onWeekChange('previous', new Date(startDate), new Date(endDate));
            }}
            style={{ ...weeklyCalendar_Style.iconSx, ...iconStyle }}
          />
        </IconButton>
      )}
      <Box sx={weeklyCalendar_Style.dateRangeSx}>
        <DateRangePicker
          readOnly={readOnly}
          selectsRange
          // new Date('2022-03-25')
          startDate={new Date(startDate)}
          endDate={new Date(endDate)}
          unStyled="true"
        />
      </Box>
      {rightIcon && (
        <IconButton
          sx={{ p: 0 }}
          disableRipple
          onClick={() => {
            onWeekChange('next', new Date(startDate), new Date(endDate));
          }}
        >
          <BiChevronRight
            style={{ ...weeklyCalendar_Style.iconSx, ...iconStyle }}
          />
        </IconButton>
      )}
    </Box>
  );
}
WeeklyCalendar.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  rightIcon: PropTypes.bool,
  leftIcon: PropTypes.bool,
  classes: PropTypes.object,
  sx: PropTypes.object,
  iconStyle: PropTypes.object,
  readOnly: PropTypes.bool,
  onWeekChange: PropTypes.func,
  className: PropTypes.string,
};

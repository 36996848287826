export const expertRootLayout_style = {
  rootSx: {
    bgcolor: '#F7F7F7',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },
  backgroundSx: {
    bgcolor: {
      sm: '#F1F5F9',
    },
    boxShadow: {
      sm: '0px 0px 10px #efefef',
    },
    minHeight: '95vh',
    width: '100%',
    maxHeight: 'auto',
  },
};

/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import { getDateFormat } from '@hc/dayjs';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient
} from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useDoctorAppointment = create((set, get) => ({
  doctorAppointmentData: {
    doctorName: '',
    newAppointments: '',
    followUps: '',
    referralToday: '',
    patientDetails: [],
    patientDetailsIntialState: [],
    appointmentCurrentDate: new Date(),
    chatEnabled: false,
    chatAvailabilityId: null,
  },
  dashBoardLoading: true,
  loading: false,
  error: null,

  getDoctorAppointmentData: async (key) => {
    try {
      const { doctorAppointmentData, getDashBoardData } = get();
      const { appointmentCurrentDate } = doctorAppointmentData;
      const utcDate = appointmentCurrentDate.toISOString();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const keydata = parseJwt(authToken);

      set({ loading: true });
      const payload = {
        date: utcDate ?? '',
        doctor_id: keydata?.user_id,
      };

      if (key) {
        queryClient.invalidateQueries({
          queryKey: ['doctorAppointments', utcDate],
        });
      }

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['doctorAppointments', utcDate],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/doctor/appointments/get`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      return set(() => ({
        loading: false,
        doctorAppointmentData: {
          ...doctorAppointmentData,
          patientDetails: data?.appointments,
          patientDetailsIntialState: data?.appointments,
        },
      }));
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  deleteAppoinment: (appt_id) => {
    const { doctorAppointmentData } = get();
    const { patientDetails } = doctorAppointmentData;
    let patientDetailsCopy = JSON.parse(JSON.stringify(patientDetails));
    const childDelete = patientDetailsCopy?.filter(
      (val) => val?.appointment_id !== appt_id,
    );

    set(() => ({
      loading: false,
      doctorAppointmentData: {
        ...doctorAppointmentData,
        patientDetails: childDelete,
        patientDetailsIntialState: childDelete,
      },
    }));
  },

  getDashBoardData: async (key) => {
    try {
      const { doctorAppointmentData } = get();
      const { appointmentCurrentDate } = doctorAppointmentData;
      const utcDate = appointmentCurrentDate.toISOString();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const keydata = parseJwt(authToken);

      const payload = {
        date: utcDate ?? '',
        user_id: keydata?.user_id,
      };

      if (key) {
        await queryClient.invalidateQueries({
          queryKey: ['doctorDashBoard', utcDate],
        });
      }

      const newDate = getDateFormat(new Date(), 'YYYY-MM-DD');
      const curDate = getDateFormat(utcDate, 'YYYY-MM-DD');

      if (curDate === newDate) {
        set({ dashBoardLoading: true });
        const { data, status } = await queryClient.fetchQuery({
          queryKey: ['doctorDashBoard', utcDate],
          queryFn: async () => {
            const { data } = await httpRequest(
              'post',
              `${envConfig.api_url}/doctor/dashboard`,
              {
                ...payload,
              },
              true,
            );
            return data;
          },
          staleTime: 120000,
        });
        set(() => ({
          dashBoardLoading: false,
          doctorAppointmentData: {
            ...doctorAppointmentData,
            patientDetailsIntialState:
              doctorAppointmentData?.patientDetailsIntialState !==
                data?.appointments && data?.appointments,
            patientDetails:
              doctorAppointmentData?.patientDetails !== data?.appointments &&
              data?.appointments,
            doctorName: data?.doctor_name,
            newAppointments: data?.appointment_count,
            followUps: data?.follow_up_count,
            referralToday: data?.referral_count,
            chatEnabled: data?.chat_enabled ?? false,
            chatAvailabilityId: data?.chat_availability_id ?? null,
          },
        }));

        return data?.chat_availability_id;
      }
    } catch (error) {
      set({ dashBoardLoading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  updateAppointmentDate: (start) => {
    const { doctorAppointmentData } = get();
    set({
      doctorAppointmentData: {
        ...doctorAppointmentData,
        date: start,
      },
    });
  },

  updateAppointmentStore: (key, value) => {
    const { doctorAppointmentData } = get();
    // const { appointmentCurrentDateinitial } = get();
    set({
      doctorAppointmentData: {
        ...doctorAppointmentData,
        [key]: value,
      },
    });
  },

  setFilter: (value) => {
    const { doctorAppointmentData } = get();
    let patientDetailsCopy = JSON.parse(
      JSON.stringify(doctorAppointmentData?.patientDetails),
    );
    if (value === 'ASC' || value === true) {
      patientDetailsCopy?.sort(
        (objA, objB) =>
          new Date(objA.appointment_date_time).getTime() -
          new Date(objB.appointment_date_time).getTime(),
      );
      set({
        doctorAppointmentData: {
          ...doctorAppointmentData,
          patientDetails: patientDetailsCopy,
        },
      });
    }

    if (value === 'DESC' || value === false) {
      set({
        doctorAppointmentData: {
          ...doctorAppointmentData,
          patientDetails: doctorAppointmentData?.patientDetailsIntialState,
        },
      });
    }
  },

  setSeachFilter: (value) => {
    const { doctorAppointmentData } = get();
    let patientDetailsCopy = JSON.parse(
      JSON.stringify(doctorAppointmentData?.patientDetailsIntialState),
    );

    let Arr = patientDetailsCopy?.filter(function (val) {
      return val?.name
        ?.toLocaleLowerCase()
        .includes(value?.toLocaleLowerCase());
    });

    if (value) {
      set({
        doctorAppointmentData: {
          ...doctorAppointmentData,
          patientDetails: Arr,
        },
      });
    } else {
      set({
        doctorAppointmentData: {
          ...doctorAppointmentData,
          patientDetails: doctorAppointmentData?.patientDetailsIntialState,
        },
      });
    }
  },
}));

import { Box, Grid, Skeleton, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { skeletonCard_style } from './style';

function SkeletonCard(props) {
  const {
    color,
    isCardDetailsRequired = true,
    award = false,
    isByme,
    isExpertDetailCard,
    awardTitle = true,
  } = props;

  return award ? (
    <>
      <Grid
        xs={6}
        sx={{
          paddingLeft: '10px',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          paddingTop: 2.5,
        }}
      >
        {awardTitle && (
          <Skeleton
            width="30%"
            height={25}
            animation="wave"
            sx={{
              ...skeletonCard_style.skeletonRoot,
              ...(color && { background: color }),
            }}
          />
        )}
      </Grid>
      <Grid container spacing={2} px={2}>
        <Grid item xs={6}>
          <Box p={0.5}>
            <Grid
              p={1}
              py={1.5}
              mt={2}
              sx={{
                alignItems: 'center',
                border: '1px solid #DCF5F1',
                borderRadius: '10px',
                '&.MuiGrid-root>.MuiGrid-item': { paddingTop: '0px' },
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Grid
                pt={0}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Skeleton
                  variant="circular"
                  width={85}
                  height={85}
                  animation="wave"
                  sx={{
                    ...skeletonCard_style.skeletonRoot,
                    ...(color && { background: color }),
                  }}
                />

                <Skeleton
                  width={80}
                  height={30}
                  animation="wave"
                  pt={2}
                  sx={{
                    ...skeletonCard_style.skeletonRoot,
                    ...(color && { background: color }),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box p={0.5}>
            <Grid
              p={1}
              py={1.5}
              mt={2}
              sx={{
                alignItems: 'center',
                border: '1px solid #DCF5F1',
                borderRadius: '10px',
                '&.MuiGrid-root>.MuiGrid-item': { paddingTop: '0px' },
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Grid
                pt={0}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Skeleton
                  variant="circular"
                  width={85}
                  height={85}
                  animation="wave"
                  sx={{
                    ...skeletonCard_style.skeletonRoot,
                    ...(color && { background: color }),
                  }}
                />

                <Skeleton
                  width={80}
                  height={30}
                  animation="wave"
                  pt={2}
                  sx={{
                    ...skeletonCard_style.skeletonRoot,
                    ...(color && { background: color }),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  ) : (
    <Box p={0.5}>
      <Grid
        container
        p={1}
        py={1.5}
        mt={2}
        sx={{
          alignItems: 'center',
          border: '1px solid #DCF5F1',
          borderRadius: '10px',
          '&.MuiGrid-root>.MuiGrid-item': { paddingTop: '0px' },
        }}
      >
        <Grid
          pt={0}
          item
          xs={6}
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          {!isByme && (
            <Skeleton
              variant="circular"
              width={55}
              height={55}
              animation="wave"
              sx={{
                ...skeletonCard_style.skeletonRoot,
                ...(color && { background: color }),
              }}
            />
          )}

          <Box>
            <Skeleton
              width={80}
              height={20}
              animation="wave"
              sx={{
                ...skeletonCard_style.skeletonRoot,
                ...(color && { background: color }),
              }}
            />
            <Skeleton
              width={100}
              height={20}
              animation="wave"
              sx={{
                ...skeletonCard_style.skeletonRoot,
                ...(color && { background: color }),
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <Skeleton
            width="60%"
            height={20}
            animation="wave"
            sx={{
              ...skeletonCard_style.skeletonRoot,
              ...(color && { background: color }),
            }}
          />
        </Grid>
        <Grid item xs={12} mt={isCardDetailsRequired ? 1.5 : null}>
          {isCardDetailsRequired && (
            <Grid container>
              {' '}
              <Grid item xs={6} pl={2}>
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <Skeleton
                    width={20}
                    height={20}
                    animation="wave"
                    variant="circular"
                    sx={{
                      ...skeletonCard_style.skeletonRoot,
                      ...(color && { background: color }),
                    }}
                  />
                  <Skeleton
                    width="80px"
                    height="12px"
                    animation="wave"
                    variant="rounded"
                    sx={{
                      ...skeletonCard_style.skeletonRoot,
                      ...(color && { background: color }),
                    }}
                  />
                </Stack>
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <Skeleton
                    width={20}
                    height={20}
                    animation="wave"
                    variant="circular"
                    sx={{
                      ...skeletonCard_style.skeletonRoot,
                      ...(color && { background: color }),
                    }}
                  />
                  <Skeleton
                    width="80px"
                    height="12px"
                    animation="wave"
                    variant="rounded"
                    sx={{
                      ...skeletonCard_style.skeletonRoot,
                      ...(color && { background: color }),
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Skeleton
                  width="80%"
                  height="100%"
                  animation="wave"
                  variant="rounded"
                  sx={{
                    ...skeletonCard_style.skeletonRoot,
                    ...(color && { background: color }),
                  }}
                />
              </Grid>{' '}
            </Grid>
          )}
          {isExpertDetailCard && (
            <Grid container mt={3}>
              {' '}
              <Grid
                item
                xs={4}
                sx={{ display: 'flex', justifyContent: 'start' }}
              >
                <Skeleton
                  width="80px"
                  height="12px"
                  animation="wave"
                  variant="rounded"
                  sx={{
                    ...skeletonCard_style.skeletonRoot,
                    ...(color && { background: color }),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Skeleton
                  width="80px"
                  height="12px"
                  animation="wave"
                  variant="rounded"
                  sx={{
                    ...skeletonCard_style.skeletonRoot,
                    ...(color && { background: color }),
                  }}
                />
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Skeleton
                  width="80px"
                  height="12px"
                  animation="wave"
                  variant="rounded"
                  sx={{
                    ...skeletonCard_style.skeletonRoot,
                    ...(color && { background: color }),
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

SkeletonCard.propTypes = {
  color: PropTypes.string,
  isCardDetailsRequired: PropTypes.bool,
  award: PropTypes.bool,
  isExpertDetailCard: PropTypes.bool,
  isByme: PropTypes.bool,
};

export { SkeletonCard };

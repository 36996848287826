import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { expertPreviousPlanCard_style } from './style';
import {
  BrainIcon,
  CircularProgressWithLabel,
  FoodIcon,
  RuningIcon,
} from '../../../atoms';

function ExpertPreviousPlanCard(props) {
  const {
    className = '',
    rootStyle = {},
    overAllProgress = '',
    data = [],
    ...rest
  } = props;

  const progressData = [
    {
      name: 'Mind',
      icon: <BrainIcon color="#888888" />,
      progressValue: Math.round(data[2]?.percentage),
      CircularProgressMainColor: 'sky.800',
      CircularProgressBackGroundColorColor: 'sky.200',
    },
    {
      name: 'Body',
      icon: <RuningIcon color="#888888" />,
      progressValue: Math.round(data[0]?.percentage),
      CircularProgressMainColor: 'lime.800',
      CircularProgressBackGroundColorColor: 'lime.200',
    },
    {
      name: 'Food',
      icon: <FoodIcon color="#888888" />,
      progressValue: Math.round(data[1]?.percentage),
      CircularProgressMainColor: '#55D19D',
      CircularProgressBackGroundColorColor: '#d4dcd6',
    },
  ];

  return (
    <Box
      sx={{
        ...expertPreviousPlanCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={expertPreviousPlanCard_style.cardRootSx}>
        <Grid
          container
          sx={{ bgcolor: '#F7F7F7', borderRadius: '8px', pb: 1.5 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            display="flex"
            justifyContent="center"
            sx={{
              bgcolor: '#25C460',
              height: '25px',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            <Typography
              color="#fff"
              textAlign="center"
              fontSize={12}
              alignSelf="center"
            >{`Over All progress: ${overAllProgress}%`}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Grid
              mt={1}
              px={1}
              container
              display="flex"
              justifyContent="center"
            >
              {progressData.map((data, i) => (
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  key={i}
                  sx={
                    data.name === 'Mind' || data.name === 'Body'
                      ? { borderRight: '1px solid #E3E3E3' }
                      : ''
                  }
                >
                  <Box
                    display="flex"
                    justifyContent={
                      data.name === 'Body'
                        ? 'center'
                        : data.name === 'Food'
                        ? 'end'
                        : 'start'
                    }
                    p={
                      data.name === 'Mind' || data.name === 'Food'
                        ? '3.2px'
                        : '6.6px'
                    }
                  >
                    <Box>
                      <Box>{data?.icon}</Box>
                    </Box>
                    <Typography
                      ml={0.7}
                      color="#888888"
                      alignSelf="center"
                      fontSize={12}
                    >
                      {data.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container display="flex">
              {progressData.map((value, index) => (
                <Grid
                  key={index}
                  item
                  xs={4}
                  sm={4}
                  display="flex"
                  justifyContent={
                    value.name === 'Body'
                      ? 'center'
                      : value.name === 'Food'
                      ? 'end'
                      : 'start'
                  }
                  pl={value.name === 'Mind' ? 3 : 0}
                  pr={value.name === 'Food' ? 3 : 0}
                  md={4}
                >
                  <CircularProgressWithLabel
                    value={Math.round(value?.progressValue)}
                    size={35}
                    thickness={5}
                    typo={{ fontSize: '12px' }}
                    color={{
                      mainColor: value?.CircularProgressMainColor,
                      backgroundColor:
                        value?.CircularProgressBackGroundColorColor,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

ExpertPreviousPlanCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  data: PropTypes.array,
  overAllProgress: PropTypes.number,
};

export { ExpertPreviousPlanCard };

export const expertRequestCard_style = {
  rootSx: {
    bgcolor: '#EEEEEE',
    borderRadius: '8px',
    cursor: 'pointer',
    // border: '1px solid #EEEEEE',
    // boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    // boxShadow:
    //   'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
    boxShadow:
      'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 2px 2px -4px',
  },
  requestCardRootSx: {
    bgcolor: '#fff',
    // border: '1px solid #E3E3E3',
    borderRadius: '8px',
    p: 1.6,
    cursor: 'pointer',
  },
};

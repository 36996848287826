export const ActivityReference_style = {
  rootSx: { p: 3, background: '#F7F7F7' },
  appBarStyleSx: {
    backgroundColor: 'purple.A100',
  },
  screenLayoutChildernSx: {
    pt: 6,
    pb: 8.5,
  },

  buttonSx: {
    textTransform: 'none',
    background: ' #6552CC',
    borderRadius: '8px',
    width: '150px',
    color: ' #FFFFFF',
    '&:hover': {
      background: ' #6552CC',
    },
  },
  backbuttonSx: {
    textTransform: 'none',
    width: '150px',
    background: '#F3F0FF',
    color: ' #6552CC',
    '&:hover': {
      background: '#F3F0FF',
    },
  },
  ImgaeRemoveSx: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#F44F5A',
    cursor: 'pointer',
  },
  FlexSx: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FEEAEA ',
    borderRadius: '4px',
    px: 0.5,
  },
  expertAppbarSx: {
    pl: 2.5,
    pr: 2.5,
    py: 2,
    boxSizing: 'border-box',
    bgcolor: '#fff',
    borderRadius: '0px 0px 24px 24px',
    boxShadow: '0px 0px 10px #00000029',
    position: 'fixed',
    top: '6%',
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },

  boxRootSx: { background: '#FFFFFF', p: 2, borderRadius: '8px' },
  SaveButtonSx: {
    bgcolor: 'primary.main',
    textTransform: 'capitalize',
    color: '#fff',
    width: '180px',
    borderRadius: '8px',
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },
  cancleButtonSx: {
    border: '1px solid',
    width: '180px',
    borderColor: 'primary.main',
    color: 'primary.main',
    textTransform: 'capitalize',
    borderRadius: '8px',
    bgcolor: '#fff',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  inputGroupSx: { mb: 1.5 },
  imginputGroupSx: { mb: 1.5 },
  labelSx: {
    color: 'text.label',
    mb: 1,
  },
  inputFieldSx: {
    marginTop: '4px',
  },
  getlinkSx: {
    textAlign: 'center',

    color: 'primary.main',
  },
};

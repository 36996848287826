import { expertRoutes } from '@hc/routes';
import { useExpertOnboarding } from '@hc/store';
import { ExpertLoginTopImage } from '@hc/ui/atoms';
import { ExpertCreateAccount } from '@hc/ui/components';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { signup_style } from './style';

export default function ExpertSignUp(props) {
  const { className = '', ...rest } = props;
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { updateOnboarding, verifyOnboardUrl } = useExpertOnboarding(
    (state) => ({
      verifyOnboardUrl: state.verifyOnboardUrl,
      updateOnboarding: state.updateOnboarding,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const checkOnboardURL = async () => {
    const reponse = await verifyOnboardUrl();
    if (reponse?.isExpired === true) {
      toast("It looks like you've already signed up for Health Circles...", {
        icon: '⚠️',
      });
      navigate(expertRoutes?.signIn);
    }
  };

  useEffect(() => {
    if (searchParams.get('token')?.length > 0) {
      localStorage.clear();
      localStorage.setItem(
        localStorageKeys.authToken,
        searchParams.get('token'),
      );
      if (location?.pathname === expertRoutes?.createAccount) {
        checkOnboardURL();
      }
      const keyData = parseJwt(searchParams.get('token'));
      updateOnboarding('email', keyData?.email_id);
    }
  }, [location]);

  return (
    <Box sx={signup_style.rootSx} className={`${className}`} {...rest}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={signup_style.imgContSx}>
            <ExpertLoginTopImage rootStyle={signup_style.imgSx} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box>
            <ExpertCreateAccount
              password="newPassword"
              confirmPassword="confirmPassword"
              emailId="emailId"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

ExpertSignUp.propTypes = {
  className: PropTypes.string,
};

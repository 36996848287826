export const profilePersonalInfo_style = {
  rootSx: {
    py: 2,
  },
  customtextareaSx: {
    bgcolor: '#fff',
    borderRadius: '8px',

    '& .MuiOutlinedInput-input': {
      fontSize: '16px',
      fontWeight: 500,
      color: '#0E1824',
      bgcolor: '#fff',
      lineHeight: '25px',
      p: 0,
    },
    '& .MuiOutlinedInput-root': {
      bgcolor: '#fff',

      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: '#E5E0FF',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
  },
  customtextSx: {
    bgcolor: '#fff',
    borderRadius: '8px',

    '& .MuiOutlinedInput-input': {
      height: '40px!important',
      fontSize: '16px',
      fontWeight: 500,
      color: '#0E1824',
      bgcolor: '#fff',
      lineHeight: '25px',
      p: 0,
    },
    '& .MuiOutlinedInput-root': {
      bgcolor: '#fff',

      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: '#E5E0FF',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
  },

  labelStyle: {
    color: '#262C33',
    lineHeight: '24px',
    fontWeight: 400,
    fontSize: '12px',
  },
  inputGroupSx: { display: 'grid', gap: 0.5, mb: 0.5 },
};

import { SvgIcon } from '@mui/material';

export function SpoIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 41,
    width: 41,
    ...rootStyle,
  };
  return (
    <SvgIcon
      data-name="Group 5615"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41 41"
      sx={rootSx}
      {...rest}
    >
      <g
        data-name="Rectangle 44700"
        fill="#f0fcff"
        stroke="#dcf0f5"
        strokeWidth={1}
      >
        <rect width={41} height={41} rx={4} stroke="none" />
        <rect x={0.5} y={0.5} width={40} height={40} rx={3.5} fill="none" />
      </g>
      <path
        d="M15 3c-5.976 0-7.556 5.058-7.556 7.733V12.3A1.952 1.952 0 007 13.578a2.136 2.136 0 001.632 2.083 12.31 12.31 0 002.844 4.539 3.2 3.2 0 002.167 2.089l.024.01v-1.153l-.444-.444v-1.258a1.778 1.778 0 013.556 0V20.7l-.444.444V22.3l.023-.01a3.2 3.2 0 002.169-2.093 12.311 12.311 0 002.843-4.574A2.074 2.074 0 0023 13.578a1.947 1.947 0 00-.454-1.287l.01-1.558C22.556 5.891 19.731 3 15 3zm-2.4 4.526c1.046 1.274 2.614 1.259 3.93 1.248 1.742-.014 2.748.053 3.18 1.57a3.463 3.463 0 001.755 2.162l.273.136a.433.433 0 00.1.181 1.031 1.031 0 01.269.753A1.207 1.207 0 0121 14.78a.442.442 0 00-.368.284 13.357 13.357 0 01-2.141 3.889.306.306 0 01-.112.033c-.519-1.6-2.08-4.431-3.377-4.431s-2.86 2.834-3.378 4.434a.306.306 0 01-.112-.033 13.329 13.329 0 01-2.141-3.85.443.443 0 00-.371-.28 1.249 1.249 0 01-1.114-1.247 1.03 1.03 0 01.269-.753.439.439 0 00.092-.138A7.8 7.8 0 0012.6 7.526zm-.516 5.674c-.121.01-.2.065-.2.249a.667.667 0 101.333 0c0-.368-.3-.222-.667-.222-.178-.005-.35-.041-.466-.027zm4.889 0c-.121.01-.2.065-.2.249a.667.667 0 101.333 0c0-.368-.3-.222-.667-.222-.179-.005-.345-.041-.466-.027zM15 18.556a.889.889 0 00-.889.889v.889l.444.444v2.667h.889v-2.667l.444-.444v-.889a.889.889 0 00-.888-.889z"
        transform="translate(5.5 8)"
        fill="#4fb9d6"
      />
    </SvgIcon>
  );
}
export function TemperatureIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 41,
    height: 41,
    ...rootStyle,
  };
  return (
    <SvgIcon
      data-name="Group 5615"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41 41"
      sx={rootSx}
      {...rest}
    >
      <g
        data-name="Rectangle 44700"
        fill="#fff0f7"
        stroke="#f5dce8"
        strokeWidth={1}
      >
        <rect width={41} height={41} rx={4} stroke="none" />
        <rect x={0.5} y={0.5} width={40} height={40} rx={3.5} fill="none" />
      </g>
      <path
        d="M7.734 4v6.63A3.959 3.959 0 006 13.828a4.047 4.047 0 108.094 0 3.959 3.959 0 00-1.734-3.2v-.847h1.156V8.625h-1.157V7.469h1.156V6.312h-1.156V5.156h1.156V4zM16.7 4a2.023 2.023 0 102.023 2.023A2.026 2.026 0 0016.7 4zM8.9 5.156h2.3v6.016l.289.163a2.891 2.891 0 11-2.891 0l.289-.163zm7.8 0a.867.867 0 11-.867.867.868.868 0 01.867-.867zm-7.231 5.781V12.2a1.734 1.734 0 101.156 0v-1.263z"
        transform="translate(8.141 10.063)"
        fill="#db649b"
      />
    </SvgIcon>
  );
}
export function PulseVitalsIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 16.536,
    height: 14.567,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.536 14.567"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M12.267 6a.591.591 0 00-.594.474l-1.511 7.556-.889-2.889a.591.591 0 00-1.107-.059l-1.027 2.4H4.771a.984.984 0 100 1.181h2.757a.591.591 0 00.543-.358l.548-1.277 1.1 3.58a.591.591 0 001.143-.058l1.3-6.5 1.473 10.017a.591.591 0 001.157.057l1.463-5.852h1.508a.984.984 0 100-1.181H15.8a.591.591 0 00-.573.448l-.851 3.406-1.539-10.44a.591.591 0 00-.57-.5z"
        transform="translate(-3 -6.001)"
        fill="rgba(24,87,76,0.48)"
      />
    </SvgIcon>
  );
}
export function SpoVitalsIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 20.444,
    width: 16,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 16 20.444"
      {...rest}
    >
      <path
        d="M15 3c-5.976 0-7.556 5.058-7.556 7.733V12.3A1.952 1.952 0 007 13.578a2.136 2.136 0 001.632 2.083 12.31 12.31 0 002.844 4.539 3.2 3.2 0 002.167 2.089l.024.01v-1.153l-.444-.444v-1.258a1.778 1.778 0 013.556 0V20.7l-.444.444V22.3l.023-.01a3.2 3.2 0 002.169-2.093 12.311 12.311 0 002.843-4.574A2.074 2.074 0 0023 13.578a1.947 1.947 0 00-.454-1.287l.01-1.558C22.556 5.891 19.731 3 15 3zm-2.4 4.526c1.046 1.274 2.614 1.259 3.93 1.248 1.742-.014 2.748.053 3.18 1.57a3.463 3.463 0 001.755 2.162l.273.136a.433.433 0 00.1.181 1.031 1.031 0 01.269.753A1.207 1.207 0 0121 14.78a.442.442 0 00-.368.284 13.357 13.357 0 01-2.141 3.889.306.306 0 01-.112.033c-.519-1.6-2.08-4.431-3.377-4.431s-2.86 2.834-3.378 4.434a.306.306 0 01-.112-.033 13.329 13.329 0 01-2.141-3.85.443.443 0 00-.371-.28 1.249 1.249 0 01-1.114-1.247 1.03 1.03 0 01.269-.753.439.439 0 00.092-.138A7.8 7.8 0 0012.6 7.526zm-.516 5.674c-.121.01-.2.065-.2.249a.667.667 0 101.333 0c0-.368-.3-.222-.667-.222-.178-.005-.35-.041-.466-.027zm4.889 0c-.121.01-.2.065-.2.249a.667.667 0 101.333 0c0-.368-.3-.222-.667-.222-.179-.005-.345-.041-.466-.027zM15 18.556a.889.889 0 00-.889.889v.889l.444.444v2.667h.889v-2.667l.444-.444v-.889a.889.889 0 00-.888-.889z"
        transform="translate(-7 -3)"
        fill="rgba(24,87,76,0.48)"
      />
    </SvgIcon>
  );
}
export function TemperatureVitalsIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 12.719,
    height: 13.875,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12.719 13.875"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M7.734 4v6.63A3.959 3.959 0 006 13.828a4.047 4.047 0 108.094 0 3.959 3.959 0 00-1.734-3.2v-.847h1.156V8.625h-1.157V7.469h1.156V6.312h-1.156V5.156h1.156V4zM16.7 4a2.023 2.023 0 102.023 2.023A2.026 2.026 0 0016.7 4zM8.9 5.156h2.3v6.016l.289.163a2.891 2.891 0 11-2.891 0l.289-.163zm7.8 0a.867.867 0 11-.867.867.868.868 0 01.867-.867zm-7.231 5.781V12.2a1.734 1.734 0 101.156 0v-1.263z"
        transform="translate(-6 -4)"
        fill="rgba(24,87,76,0.48)"
      />
    </SvgIcon>
  );
}
export function MindBodyFoodLogo(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    color: '#6552CC',
    strokeWidth: 0.2,
    width: 151,
    height: 30,
    ...rootStyle,
  };

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="166"
      height="30"
      viewBox="0 0 166 30"
      sx={rootSx}
      {...rest}
    >
      <g
        id="Group_5477"
        data-name="Group 5477"
        transform="translate(-20 -54.5)"
      >
        <text
          id="Mind_Body_Food"
          data-name="Mind Body Food"
          transform="translate(57 75)"
          fill="#6552cc"
          fontSize="16"
          fontFamily="Poppins-SemiBold, Poppins"
          fontWeight="600"
        >
          <tspan x="0" y="0">
            Mind Body Food
          </tspan>
        </text>
        <g id="MBF" transform="translate(20 54.5)">
          <path
            id="Path_4863"
            data-name="Path 4863"
            d="M9,12.736a15.59,15.59,0,0,1,1.114-1.48,2.325,2.325,0,0,0,.338-2.515,3.288,3.288,0,0,0-.421-.792A5.272,5.272,0,0,1,13.2.192,5.61,5.61,0,0,1,14.639.013q3.8-.025,7.6-.007a5.349,5.349,0,0,1,5.212,6.213,4.73,4.73,0,0,1-.661,1.649c-.921,1.5-.911,2.3.151,3.719.275.367.529.751.818,1.157a.682.682,0,0,1-.97-.239,10.927,10.927,0,0,0-16.779-.051,3.087,3.087,0,0,1-.848.505L9,12.736Zm9.45-9.276c-1.264,0-2.53-.007-3.795,0a2.335,2.335,0,0,0-.785.122,1.92,1.92,0,0,0-1.159,2.189,1.624,1.624,0,0,0,1.647,1.5q3.995.069,7.99.01a1.714,1.714,0,0,0,1.766-1.977,1.831,1.831,0,0,0-1.949-1.843c-1.239-.008-2.475,0-3.714,0ZM9.9,6.028a5.732,5.732,0,0,1,1.376-3.389,22.953,22.953,0,0,1,2.555-2.1C11.508.61,9.209,3.6,9.9,6.028Z"
            transform="translate(-4.25 0)"
            fill="#6552cc"
          />
          <path
            id="Path_4864"
            data-name="Path 4864"
            d="M27.762,46.086c-2.223,3.364-5.28,5.314-9.225,5.334-4.026.022-7.2-1.861-9.537-5.5a6.062,6.062,0,0,1,3.789.875,10.464,10.464,0,0,0,3.794,1.969A8.181,8.181,0,0,0,23.906,46.9a2.978,2.978,0,0,1,2.187-.858,10.682,10.682,0,0,0,1.6-.14l.069.186Z"
            transform="translate(-4.25 -21.42)"
            fill="#6552cc"
          />
          <path
            id="Path_4865"
            data-name="Path 4865"
            d="M28.474,43.4c-1.056.1-2.075.2-3.1.278-1.676.126-3.353.24-5.029.35a2.879,2.879,0,0,0-1.629.6,7.572,7.572,0,0,1-9.066-.05,2.594,2.594,0,0,0-1.232-.51c-2.4-.213-4.811-.361-7.217-.54-.4-.029-.79-.1-1.168-.145-.155-.619.215-.623.588-.623h4.56c1.357,0,2.716-.029,4.071.018a2.213,2.213,0,0,1,1.176.39,5.927,5.927,0,0,0,7.631-.013,1.979,1.979,0,0,1,1.093-.379c2.877-.03,5.754-.019,8.631-.013.347,0,.836-.126.686.636Z"
            transform="translate(0 -19.95)"
            fill="#6552cc"
          />
          <path
            id="Path_4866"
            data-name="Path 4866"
            d="M0,24.21a2.155,2.155,0,0,1,.672-.355c1.261-.137,2.527-.234,3.794-.337,1.427-.116,2.857-.192,4.279-.352a2.462,2.462,0,0,0,1.076-.512,7.307,7.307,0,0,1,8.834-.034,5.306,5.306,0,0,0,2.935.722c2.256.2,4.518.36,6.818.539.028.188.054.36.093.617H26.588c-2.435,0-4.87.031-7.3-.007a2.491,2.491,0,0,1-1.329-.445,5.909,5.909,0,0,0-7.43.075,1.9,1.9,0,0,1-1.026.367c-3.029.028-6.06.015-9.089.011A1.881,1.881,0,0,1,.1,24.446L0,24.208Z"
            transform="translate(0 -9.87)"
            fill="#6552cc"
          />
          <path
            id="Path_4867"
            data-name="Path 4867"
            d="M17.258,31v4.8H16.127V32.921L15.089,35.8h-.913l-1.045-2.886V35.8H12V31h1.336l1.3,3.323L15.929,31Z"
            transform="translate(-5.667 -14.467)"
            fill="#6552cc"
          />
          <path
            id="Path_4868"
            data-name="Path 4868"
            d="M28.069,33.338a1.07,1.07,0,0,1,.648.417,1.186,1.186,0,0,1,.245.738,1.2,1.2,0,0,1-.41.957,1.675,1.675,0,0,1-1.131.349H25.258V31h2.09a1.661,1.661,0,0,1,1.1.335,1.234,1.234,0,0,1,.185,1.614A1.092,1.092,0,0,1,28.069,33.338Zm-1.68-.4h.741a.639.639,0,0,0,.423-.123.541.541,0,0,0,0-.752.616.616,0,0,0-.423-.13h-.741Zm.833,1.915a.654.654,0,0,0,.437-.13.487.487,0,0,0,.159-.39.5.5,0,0,0-.165-.4.637.637,0,0,0-.443-.144h-.82v1.06Z"
            transform="translate(-11.927 -14.467)"
            fill="#6552cc"
          />
          <path
            id="Path_4869"
            data-name="Path 4869"
            d="M38.3,31v.937H36.4v1.012h1.415v.909H36.4V35.8H35.273V31Z"
            transform="translate(-16.657 -14.467)"
            fill="#6552cc"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
export function MorningIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 42,
    height: 22,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42 22"
      {...rest}
      sx={rootSx}
    >
      <path
        data-name="Path 6680"
        d="M8.309 20.952a12.691 12.691 0 0125.383 0z"
        fill="#fcca3d"
      />
      <path
        data-name="Path 6681"
        d="M40.914 19.869h-6.192a13.41 13.41 0 00-3.259-7.8l4.381-4.407a1.046 1.046 0 000-1.517 1.083 1.083 0 00-1.521 0l-4.417 4.371a13.711 13.711 0 00-7.821-3.251V1.084a1.086 1.086 0 00-2.172 0v6.177a13.472 13.472 0 00-7.821 3.251L7.676 6.141a1.074 1.074 0 00-1.521 1.517l4.381 4.371a13.647 13.647 0 00-3.259 7.8H1.086a1.084 1.084 0 100 2.167h39.828A1.066 1.066 0 0042 20.916a1.036 1.036 0 00-1.086-1.047zM21 9.392a11.649 11.649 0 0111.55 10.477H9.45A11.649 11.649 0 0121 9.392z"
        fill="#444b54"
      />
    </SvgIcon>
  );
}
export function JourneyIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 44,
    height: 44,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 44"
      sx={rootSx}
      {...rest}
    >
      <g data-name="Group 5629">
        <g
          data-name="Rectangle 44708"
          transform="translate(-29 -331) translate(29 331)"
          fill="#fcfbff"
          stroke="#dcd7f0"
          strokeWidth={1}
        >
          <rect width={44} height={44} rx={6} stroke="none" />
          <rect x={0.5} y={0.5} width={43} height={43} rx={5.5} fill="none" />
        </g>
        <path
          d="M20.921 5a2.134 2.134 0 00-1.6.683 2.077 2.077 0 00-.418.738h-7.34a4.141 4.141 0 00-3.475 1.9A2.129 2.129 0 006.535 9a2.23 2.23 0 000 2.9 2.111 2.111 0 001.412.674 4.142 4.142 0 003.619 2.141h5.921a2.707 2.707 0 012.064.953 1.907 1.907 0 00-.227.2 2.23 2.23 0 000 2.9 1.915 1.915 0 00.34.3 2.709 2.709 0 01-2.178 1.1h-7.34a2.077 2.077 0 00-.418-.738 2.209 2.209 0 00-3.194 0 2.23 2.23 0 000 2.9 2.209 2.209 0 003.194 0 2.077 2.077 0 00.418-.738h7.34a4.143 4.143 0 003.62-2.141 2.11 2.11 0 001.411-.674 2.23 2.23 0 000-2.9 2.129 2.129 0 00-1.556-.681 4.141 4.141 0 00-3.475-1.9h-5.92a2.709 2.709 0 01-2.178-1.1 1.914 1.914 0 00.34-.3 2.23 2.23 0 000-2.9A1.907 1.907 0 009.5 8.8a2.707 2.707 0 012.064-.953h7.34a2.077 2.077 0 00.418.738 2.209 2.209 0 003.194 0 2.23 2.23 0 000-2.9A2.134 2.134 0 0020.921 5zm0 1.421a.625.625 0 01.535.205.814.814 0 010 1.01.8.8 0 01-1.069 0 .76.76 0 01-.17-.434.71.71 0 000-.144.759.759 0 01.169-.432.625.625 0 01.535-.205zM8.132 9.737a.625.625 0 01.535.205.814.814 0 010 1.01.625.625 0 01-.535.205.625.625 0 01-.535-.205.814.814 0 010-1.01.625.625 0 01.535-.205zm12.789 6.868a.625.625 0 01.535.205.814.814 0 010 1.01.8.8 0 01-1.069 0 .814.814 0 010-1.01.625.625 0 01.534-.205zM8.132 20.158a.625.625 0 01.535.205.76.76 0 01.17.434.711.711 0 000 .144.759.759 0 01-.169.432.625.625 0 01-.535.205.625.625 0 01-.535-.205.814.814 0 010-1.01.625.625 0 01.534-.205z"
          transform="translate(-29 -331) translate(36.474 339)"
          fill="#6552cc"
        />
      </g>
    </SvgIcon>
  );
}
export function MbfStatusIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 335,
    height: 120,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 335 120"
      sx={rootSx}
      {...rest}
    >
      <defs>
        <clipPath id="a">
          <rect
            data-name="Rectangle 44809"
            width={335}
            height={120}
            rx={8}
            transform="translate(3202 7572)"
            fill="#eae6ff"
          />
        </clipPath>
      </defs>
      <g data-name="MBF Status Tracking">
        <g data-name="Group 102585" transform="translate(-20 -127)">
          <path
            data-name="Polygon 36"
            d="M3.628 1.549a1 1 0 011.743 0l2.79 4.96A1 1 0 017.29 8H1.71a1 1 0 01-.872-1.49z"
            transform="translate(50 169)"
            fill="#d675ff"
            opacity={0.47}
          />
          <path
            data-name="Union 41"
            d="M2.889 6.889V5.111H1.112a1.111 1.111 0 110-2.222h1.777V1.112a1.111 1.111 0 112.222 0v1.777h1.778a1.111 1.111 0 110 2.222H5.111v1.778a1.111 1.111 0 11-2.222 0z"
            transform="translate(35.99 209)"
            fill="#ff9a9a"
            opacity={0.47}
          />
          <path
            data-name="Union 42"
            d="M2.889 6.889V5.111H1.112a1.111 1.111 0 110-2.222h1.777V1.112a1.111 1.111 0 112.222 0v1.777h1.778a1.111 1.111 0 110 2.222H5.111v1.778a1.111 1.111 0 11-2.222 0z"
            transform="rotate(14.98 -476.33 345.043)"
            fill="#a1c4ff"
            opacity={0.47}
          />
          <rect
            data-name="Rectangle 44805"
            width={5}
            height={5}
            rx={1}
            transform="translate(70 226)"
            fill="#72d286"
            opacity={0.47}
          />
          <circle
            data-name="Ellipse 39515"
            cx={3}
            cy={3}
            r={3}
            transform="translate(126 233)"
            fill="#70cbff"
            opacity={0.47}
          />
          <circle
            data-name="Ellipse 39516"
            cx={3}
            cy={3}
            r={3}
            transform="translate(140 163)"
            fill="#db96ff"
            opacity={0.47}
          />
        </g>
        <g
          data-name="Mask Group 3"
          transform="translate(-20 -127) translate(-3182 -7445)"
          clipPath="url(#a)"
        >
          <circle
            data-name="Ellipse 39521"
            cx={30}
            cy={30}
            r={30}
            transform="translate(3340 7523)"
            fill="#dad2ff"
            opacity={0.7}
          />
          <circle
            data-name="Ellipse 39519"
            cx={30}
            cy={30}
            r={30}
            transform="translate(3174 7673)"
            fill="#dad2ff"
            opacity={0.7}
          />
          <rect
            data-name="Rectangle 44810"
            width={33.149}
            height={33.149}
            rx={6}
            transform="rotate(-45 11027.982 -392.5)"
            fill="#ddd7f7"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
export function MoodStatsIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 44,
    height: 44,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 44"
      sx={rootSx}
      {...rest}
    >
      <g data-name="Group 5630">
        <g
          data-name="Rectangle 44708"
          transform="translate(-29 -330) translate(29 330)"
          fill="#fcfbff"
          stroke="#dcd7f0"
          strokeWidth={1}
        >
          <rect width={44} height={44} rx={6} stroke="none" />
          <rect x={0.5} y={0.5} width={43} height={43} rx={5.5} fill="none" />
        </g>
        <path
          d="M14 4a10 10 0 1010 10A10.011 10.011 0 0014 4zm0 1.5A8.5 8.5 0 115.5 14 8.489 8.489 0 0114 5.5zM9.75 10a.75.75 0 100 1.5h2a.75.75 0 100-1.5zm6.5 0a.75.75 0 100 1.5h2a.75.75 0 100-1.5zm-6.5 4a.75.75 0 00-.75.75A5.2 5.2 0 0014 20a5.2 5.2 0 005-5.25.75.75 0 00-.75-.75zm.9 1.5h6.7a3.646 3.646 0 01-3.35 3 3.646 3.646 0 01-3.35-3z"
          transform="translate(-29 -330) translate(37 338)"
          fill="#6552cc"
        />
      </g>
    </SvgIcon>
  );
}
export function PerformanceIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 44,
    height: 44,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 44"
      sx={rootSx}
      {...rest}
    >
      <g data-name="Group 5630">
        <g
          data-name="Rectangle 44708"
          transform="translate(-29 -330) translate(29 330)"
          fill="#fcfbff"
          stroke="#dcd7f0"
          strokeWidth={1}
        >
          <rect width={44} height={44} rx={6} stroke="none" />
          <rect x={0.5} y={0.5} width={43} height={43} rx={5.5} fill="none" />
        </g>
        <path
          d="M13.06 0a.619.619 0 00-.4.156.631.631 0 00-.12.8.623.623 0 00.335.267l.963.324-5.99 2.69-5.81 2.511a.625.625 0 00-.307.286.632.632 0 00.158.778.6.6 0 00.638.086l5.818-2.512 6.181-2.772-.384 1.164a.632.632 0 00.034.479.623.623 0 00.36.314.594.594 0 00.2.033.618.618 0 00.364-.119.627.627 0 00.226-.311l.8-2.407a.631.631 0 00-.195-.827.62.62 0 00-.286-.108l-2.377-.8A.617.617 0 0013.06 0zm.013 6.7a1.448 1.448 0 00-1.027.43 1.473 1.473 0 00-.426 1.035v8.372a1.474 1.474 0 00.426 1.036 1.448 1.448 0 001.027.43h1.66a1.448 1.448 0 001.027-.43 1.473 1.473 0 00.426-1.036V8.163a1.473 1.473 0 00-.426-1.035 1.448 1.448 0 00-1.027-.43zm0 1.256h1.66a.207.207 0 01.147.061.21.21 0 01.061.148v8.372a.21.21 0 01-.061.148.206.206 0 01-.147.061h-1.66a.206.206 0 01-.147-.061.21.21 0 01-.061-.148V8.163a.21.21 0 01.061-.148.206.206 0 01.147-.061zm-5.81.419a1.448 1.448 0 00-1.027.43 1.473 1.473 0 00-.426 1.032v6.7a1.473 1.473 0 00.426 1.036A1.448 1.448 0 007.263 18h1.66a1.448 1.448 0 001.027-.43 1.474 1.474 0 00.426-1.036v-6.7A1.474 1.474 0 009.949 8.8a1.448 1.448 0 00-1.027-.43zm0 1.256h1.66a.206.206 0 01.147.061.21.21 0 01.061.148v6.7a.21.21 0 01-.061.148.206.206 0 01-.147.061h-1.66a.206.206 0 01-.147-.061.21.21 0 01-.061-.148v-6.7a.21.21 0 01.061-.148.207.207 0 01.147-.064zm-5.81 2.093a1.448 1.448 0 00-1.027.43A1.473 1.473 0 000 13.186v3.349a1.473 1.473 0 00.426 1.035 1.448 1.448 0 001.027.43h1.66a1.448 1.448 0 001.027-.43 1.473 1.473 0 00.426-1.036v-3.348a1.473 1.473 0 00-.426-1.036 1.448 1.448 0 00-1.027-.43zm0 1.256h1.66a.207.207 0 01.147.061.21.21 0 01.061.148v3.349a.21.21 0 01-.061.148.206.206 0 01-.147.061h-1.66a.206.206 0 01-.147-.061.21.21 0 01-.061-.148v-3.352a.21.21 0 01.061-.148.207.207 0 01.147-.061z"
          transform="translate(-29 -330) translate(42.9 343)"
          fill="#6552cc"
        />
      </g>
    </SvgIcon>
  );
}
export function SheduleAppointment(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    color: '#6552CC',
    // strokeWidth: 3,
    width: '60.6px',
    height: '60.6px',
    ...rootStyle,
  };

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="63.6"
      height="63.6"
      viewBox="0 0 63.6 63.6"
      sx={rootSx}
      {...rest}
    >
      <g
        id="Group_5143"
        data-name="Group 5143"
        transform="translate(-14.5 -14.5)"
      >
        <path
          id="Path_943"
          data-name="Path 943"
          d="M50.033,25A25.033,25.033,0,1,0,75.066,50.033,25.033,25.033,0,0,0,50.033,25Z"
          transform="translate(-3.266 -3.466)"
          fill="#d7eae7"
        />
        <path
          id="Path_944"
          data-name="Path 944"
          d="M46.3,16A30.3,30.3,0,1,0,76.6,46.3,30.3,30.3,0,0,0,46.3,16Z"
          fill="none"
          stroke="#444b54"
          strokeWidth="3"
        />
        <path
          id="Appointments"
          d="M8.214,0a8.214,8.214,0,1,0,8.214,8.214A8.231,8.231,0,0,0,8.214,0Zm0,2.143A6.071,6.071,0,1,1,2.143,8.214,6.055,6.055,0,0,1,8.214,2.143ZM8.2,3.556A1.072,1.072,0,0,0,7.143,4.643V8.214a1.072,1.072,0,0,0,.314.758L8.885,10.4A1.072,1.072,0,1,0,10.4,8.885L9.286,7.771V4.643A1.072,1.072,0,0,0,8.2,3.556Zm8.817.73a9.541,9.541,0,0,1,.671,2.143h7.672a2.5,2.5,0,0,1,2.5,2.5V10H17.686a9.615,9.615,0,0,1-.671,2.143H27.857V25.357a2.5,2.5,0,0,1-2.5,2.5H8.929a2.5,2.5,0,0,1-2.5-2.5V17.686a9.615,9.615,0,0,1-2.143-.671v8.343A4.648,4.648,0,0,0,8.929,30H25.357A4.648,4.648,0,0,0,30,25.357V8.929a4.648,4.648,0,0,0-4.643-4.643ZM17.143,15a1.786,1.786,0,1,0,1.786,1.786A1.786,1.786,0,0,0,17.143,15Zm6.071,0A1.786,1.786,0,1,0,25,16.786,1.786,1.786,0,0,0,23.214,15ZM11.071,21.429a1.786,1.786,0,1,0,1.786,1.786A1.786,1.786,0,0,0,11.071,21.429Zm6.071,0a1.786,1.786,0,1,0,1.786,1.786A1.786,1.786,0,0,0,17.143,21.429Z"
          transform="translate(31 31.8)"
          fill="#444b54"
        />
      </g>
    </SvgIcon>
  );
}
export function BgMoodSelectionIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 600.993,
    height: 605.573,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 600.993 605.573"
      {...rest}
      sx={rootSx}
    >
      <g data-name="v767-wan-03 [Converted]" opacity={0.04}>
        <g
          data-name="Group 90850"
          transform="rotate(-30 427.639 89) translate(79.637 -14.896)"
        >
          <circle
            data-name="Ellipse 129105"
            cx={27.395}
            cy={27.395}
            r={27.395}
            transform="rotate(-76.72 33.689 26.663)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20756"
            d="M235.12 45.941a2.421 2.421 0 112.421 2.421 2.422 2.422 0 01-2.421-2.421z"
            transform="translate(-216.924 -19.358)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20757"
            d="M288.05 45.941a2.421 2.421 0 112.421 2.421 2.422 2.422 0 01-2.421-2.421z"
            transform="translate(-245.164 -19.358)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20758"
            d="M47.691 37.545a15.431 15.431 0 01-29.462 0"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Line 435"
            transform="translate(16.591 36.873)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
            d="M2.808 0L0 1.343"
          />
          <path
            data-name="Line 436"
            transform="translate(46.52 36.873)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
            d="M0 0L2.808 1.343"
          />
        </g>
        <g
          data-name="Group 90851"
          transform="rotate(-30 427.639 89) translate(-22.194 -20.682)"
        >
          <circle
            data-name="Ellipse 129106"
            cx={27.395}
            cy={27.395}
            r={27.395}
            transform="rotate(-45 46.767 19.372)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20759"
            d="M29.2 45.941a2.421 2.421 0 112.421 2.421 2.422 2.422 0 01-2.421-2.421z"
            transform="translate(-5.227 -13.572)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20760"
            d="M82.14 45.941a2.421 2.421 0 112.421 2.421 2.422 2.422 0 01-2.421-2.421z"
            transform="translate(-33.473 -13.572)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20761"
            d="M25.47 49.955a14.461 14.461 0 0126.537 0"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
        </g>
        <g
          data-name="Group 90852"
          transform="rotate(-30 427.639 89) translate(-10.741 374.245)"
          fill="none"
          stroke="#6552cc"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle
            data-name="Ellipse 129107"
            cx={27.395}
            cy={27.395}
            r={27.395}
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20762"
            d="M14.125 38.605a14.461 14.461 0 0126.537 0"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20763"
            d="M12.081 20.8a3.4 3.4 0 005.765.2"
            strokeWidth={5}
          />
          <path
            data-name="Path 20764"
            d="M42.7 20.8a3.4 3.4 0 01-5.765.2"
            strokeWidth={5}
          />
        </g>
        <g
          data-name="Group 90853"
          transform="rotate(-30 427.639 89) translate(169.959 -20.681)"
        >
          <path
            data-name="Path 20765"
            d="M441.14 45.941a2.421 2.421 0 112.421 2.421 2.422 2.422 0 01-2.421-2.421z"
            transform="translate(-417.166 -13.573)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20766"
            d="M494.08 45.941a2.421 2.421 0 112.421 2.421 2.422 2.422 0 01-2.421-2.421z"
            transform="translate(-445.411 -13.573)"
            fill="#6552cc"
          />
          <circle
            data-name="Ellipse 129108"
            cx={27.395}
            cy={27.395}
            r={27.395}
            transform="rotate(-45 46.767 19.372)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20767"
            d="M53.469 43.329a15.431 15.431 0 01-29.462 0h29.457z"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
        </g>
        <g
          data-name="Group 90854"
          transform="rotate(-30 427.639 89) translate(171.175 76.266)"
          fill="none"
          stroke="#6552cc"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle
            data-name="Ellipse 129109"
            cx={27.395}
            cy={27.395}
            r={27.395}
            transform="rotate(-58.96 41.521 23.473)"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20768"
            d="M52.36 42.063a15.428 15.428 0 01-29.457.224z"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20769"
            d="M22.614 35.906l5.229-2.883-5.276-2.808"
            strokeWidth={5.31}
          />
          <path
            data-name="Path 20770"
            d="M52.556 35.677l-5.271-2.8 5.229-2.883"
            strokeWidth={5.31}
          />
        </g>
        <g
          data-name="Group 90855"
          transform="rotate(-30 427.639 89) translate(277.323 -9.43)"
        >
          <path
            data-name="Path 20771"
            d="M646.98 45.731a2.421 2.421 0 112.421 2.421 2.422 2.422 0 01-2.421-2.421z"
            transform="translate(-634.353 -24.712)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20772"
            d="M699.92 45.731a2.421 2.421 0 112.421 2.421 2.422 2.422 0 01-2.421-2.421z"
            transform="translate(-662.598 -24.712)"
            fill="#6552cc"
          />
          <circle
            data-name="Ellipse 129110"
            cx={27.395}
            cy={27.395}
            r={27.395}
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20773"
            d="M15.048 40.391a12.932 12.932 0 0124.695 0z"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
        </g>
        <g
          data-name="Group 90856"
          transform="rotate(-30 427.639 89) translate(373.498 -10.041)"
        >
          <path
            data-name="Path 20774"
            d="M853.16 44.421a2.421 2.421 0 112.421 2.421 2.419 2.419 0 01-2.421-2.421z"
            transform="translate(-840.533 -23.402)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20775"
            d="M906.1 44.421a2.421 2.421 0 112.421 2.421 2.419 2.419 0 01-2.421-2.421z"
            transform="translate(-868.778 -23.402)"
            fill="#6552cc"
          />
          <circle
            data-name="Ellipse 129111"
            cx={27.395}
            cy={27.395}
            r={27.395}
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <ellipse
            data-name="Ellipse 129112"
            cx={6.377}
            cy={9.334}
            rx={6.377}
            ry={9.334}
            transform="translate(21.019 26.514)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
        </g>
        <g
          data-name="Group 90859"
          transform="rotate(-30 427.639 89) translate(367.922 80.838)"
          fill="none"
          stroke="#6552cc"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle
            data-name="Ellipse 129113"
            cx={27.395}
            cy={27.395}
            r={27.395}
            transform="rotate(-76.72 33.689 26.663)"
            strokeWidth={4.25}
          />
          <ellipse
            data-name="Ellipse 129114"
            cx={6.377}
            cy={9.334}
            rx={6.377}
            ry={9.334}
            transform="translate(26.595 32.089)"
            strokeWidth={4.25}
          />
          <g
            data-name="Group 90857"
            transform="translate(17.653 23.32)"
            strokeWidth={5.31}
          >
            <path data-name="Line 437" d="M0 0L5.943 5.938" />
            <path data-name="Line 438" d="M5.943 0L0 5.938" />
          </g>
          <g
            data-name="Group 90858"
            transform="translate(42.348 23.32)"
            strokeWidth={5.31}
          >
            <path data-name="Line 439" d="M0 0L5.943 5.938" />
            <path data-name="Line 440" d="M5.943 0L0 5.938" />
          </g>
        </g>
        <g
          data-name="Group 90862"
          transform="rotate(-30 427.639 89) translate(362.153 171.515)"
          fill="none"
          stroke="#6552cc"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle
            data-name="Ellipse 129115"
            cx={27.395}
            cy={27.395}
            r={27.395}
            transform="rotate(-45 46.767 19.372)"
            strokeWidth={4.25}
          />
          <g
            data-name="Group 90860"
            transform="translate(23.422 29.094)"
            strokeWidth={5.31}
          >
            <path data-name="Line 441" d="M0 0L5.943 5.943" />
            <path data-name="Line 442" d="M5.943 0L0 5.943" />
          </g>
          <g
            data-name="Group 90861"
            transform="translate(48.117 29.094)"
            strokeWidth={5.31}
          >
            <path data-name="Line 443" d="M0 0L5.943 5.943" />
            <path data-name="Line 444" d="M5.943 0L0 5.943" />
          </g>
          <path
            data-name="Path 20776"
            d="M55.226 47.809a3.41 3.41 0 01-3.321 2.388 3.5 3.5 0 01-3.345-2.542h0a3.474 3.474 0 00-3.27-2.3 3.528 3.528 0 00-3.321 2.458 3.41 3.41 0 01-3.321 2.388 3.5 3.5 0 01-3.344-2.542 3.5 3.5 0 00-3.256-2.3 3.542 3.542 0 00-3.321 2.458 3.41 3.41 0 01-3.321 2.388 3.5 3.5 0 01-3.345-2.542"
            strokeWidth={4.25}
          />
        </g>
        <g
          data-name="Group 90863"
          transform="rotate(-30 427.639 89) translate(-22.089 75.11)"
        >
          <circle
            data-name="Ellipse 129116"
            cx={27.395}
            cy={27.395}
            r={27.395}
            transform="rotate(-45 46.767 19.372)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20777"
            d="M29.43 251.3a2.421 2.421 0 112.421 2.421 2.419 2.419 0 01-2.421-2.421z"
            transform="translate(-5.455 -218.932)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20778"
            d="M82.37 251.3a2.421 2.421 0 112.421 2.421 2.419 2.419 0 01-2.421-2.421z"
            transform="translate(-33.7 -218.932)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20779"
            d="M27.623 45.594l-3.648-6.848-3.644 6.848a5.052 5.052 0 00-.886 2.687 4.534 4.534 0 109.068 0 4.954 4.954 0 00-.89-2.687z"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20780"
            d="M31.01 43.326a14.458 14.458 0 0116.354.555"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
        </g>
        <g
          data-name="Group 90864"
          transform="rotate(-30 427.639 89) translate(-16.298 177.29)"
        >
          <circle
            data-name="Ellipse 129117"
            cx={27.395}
            cy={27.395}
            r={27.395}
            transform="rotate(-76.72 33.689 26.663)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20781"
            d="M29.43 461.681a2.421 2.421 0 112.421 2.421 2.419 2.419 0 01-2.421-2.421z"
            transform="translate(-11.246 -433.357)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20782"
            d="M82.37 461.681a2.421 2.421 0 112.421 2.421 2.419 2.419 0 01-2.421-2.421z"
            transform="translate(-39.491 -433.357)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20783"
            d="M19.682 45.91a14.461 14.461 0 0126.537 0"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Line 445"
            transform="translate(18.557 20.025)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
            d="M0 0L6.335 3.839"
          />
          <path
            data-name="Line 446"
            transform="translate(41.008 20.025)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
            d="M6.335 0L0 3.839"
          />
        </g>
        <g
          data-name="Group 90865"
          transform="rotate(-30 427.639 89) translate(-22.195 266.905)"
        >
          <circle
            data-name="Ellipse 129118"
            cx={27.395}
            cy={27.395}
            r={27.395}
            transform="rotate(-45 46.767 19.372)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20784"
            d="M29.2 666.171a2.421 2.421 0 112.421 2.421 2.419 2.419 0 01-2.421-2.421z"
            transform="translate(-5.226 -632.075)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20785"
            d="M82.14 666.171a2.421 2.421 0 112.421 2.421 2.419 2.419 0 01-2.421-2.421z"
            transform="translate(-33.471 -632.075)"
            fill="#6552cc"
          />
          <path
            data-name="Line 447"
            transform="translate(24.347 25.798)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
            d="M0 0L6.335 3.839"
          />
          <path
            data-name="Line 448"
            transform="translate(46.798 25.798)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
            d="M6.339 0L0 3.839"
          />
          <ellipse
            data-name="Ellipse 129119"
            cx={6.377}
            cy={9.334}
            rx={6.377}
            ry={9.334}
            transform="translate(32.366 37.86)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
        </g>
        <g
          data-name="Group 90867"
          fill="none"
          stroke="#6552cc"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            data-name="Group 90866"
            strokeWidth={4.25}
            transform="rotate(-30 427.639 89) translate(76.886 78.064)"
          >
            <circle
              data-name="Ellipse 129120"
              cx={27.395}
              cy={27.395}
              r={27.395}
              transform="rotate(-22.5 52.704 10.484)"
            />
            <path
              data-name="Path 20786"
              d="M47.405 45.947a15.422 15.422 0 01-23.221 0"
            />
          </g>
          <path
            data-name="Path 20787"
            d="M20.485 30.815a3.4 3.4 0 015.765-.2"
            strokeWidth={5}
            transform="rotate(-30 427.639 89) translate(76.886 78.064)"
          />
          <path
            data-name="Path 20788"
            d="M51.103 30.815a3.4 3.4 0 00-5.765-.2"
            strokeWidth={5}
            transform="rotate(-30 427.639 89) translate(76.886 78.064)"
          />
          <path
            data-name="Path 20789"
            d="M23.783 45.43a4.638 4.638 0 111.348-3.265 4.62 4.62 0 01-1.348 3.265z"
            strokeWidth={4.25}
            transform="rotate(-30 427.639 89) translate(76.886 78.064)"
          />
          <path
            data-name="Path 20790"
            d="M47.81 45.43a4.638 4.638 0 10-1.348-3.265 4.62 4.62 0 001.348 3.265z"
            strokeWidth={4.25}
            transform="rotate(-30 427.639 89) translate(76.886 78.064)"
          />
        </g>
        <g
          data-name="Group 90868"
          transform="rotate(-30 427.639 89) translate(277.44 374.147)"
        >
          <circle
            data-name="Ellipse 129121"
            cx={27.395}
            cy={27.395}
            r={27.395}
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20791"
            d="M647.23 868.041a2.421 2.421 0 112.421 2.421 2.419 2.419 0 01-2.421-2.421z"
            transform="translate(-634.603 -847.022)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20792"
            d="M700.17 868.041a2.421 2.421 0 112.421 2.421 2.419 2.419 0 01-2.421-2.421z"
            transform="translate(-662.848 -847.022)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20793"
            d="M42.364 31.981a22.273 22.273 0 01-29.947 0"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Line 449"
            transform="translate(11.027 31.304)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
            d="M2.808 0L0 1.348"
          />
          <path
            data-name="Line 450"
            transform="translate(40.951 31.304)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
            d="M0 0L2.813 1.348"
          />
          <path
            data-name="Path 20794"
            d="M21.196 36.893v5.481a6.195 6.195 0 0012.389 0v-5.481"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Line 451"
            transform="translate(27.395 37.765)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
            d="M0 0L0 4.012"
          />
        </g>
        <g
          data-name="Group 90869"
          transform="rotate(-30 427.639 89) translate(79.639 272.679)"
          fill="none"
          stroke="#6552cc"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle
            data-name="Ellipse 129122"
            cx={27.395}
            cy={27.395}
            r={27.395}
            transform="rotate(-76.72 33.689 26.663)"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20795"
            d="M47.931 37.553a22.273 22.273 0 01-29.947 0"
            strokeWidth={4.25}
          />
          <path
            data-name="Line 452"
            transform="translate(16.59 36.882)"
            strokeWidth={4.25}
            d="M2.808 0L0 1.343"
          />
          <path
            data-name="Line 453"
            transform="translate(46.518 36.882)"
            strokeWidth={4.25}
            d="M0 0L2.808 1.343"
          />
          <path
            data-name="Path 20796"
            d="M26.763 42.465v5.481a6.195 6.195 0 1012.389 0v-5.481"
            strokeWidth={4.25}
          />
          <path
            data-name="Line 454"
            transform="translate(32.958 43.338)"
            strokeWidth={4.25}
            d="M0 0L0 4.012"
          />
          <path
            data-name="Path 20797"
            d="M17.984 31.168l5.252-2.845-5.252-2.845"
            strokeWidth={5.31}
          />
          <path
            data-name="Path 20798"
            d="M47.931 31.168l-5.252-2.845 5.252-2.845"
            strokeWidth={5.31}
          />
        </g>
        <g
          data-name="Group 90870"
          transform="rotate(-30 427.639 89) translate(277.44 182.151)"
          fill="none"
          stroke="#6552cc"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle
            data-name="Ellipse 129123"
            cx={27.395}
            cy={27.395}
            r={27.395}
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20799"
            d="M12.422 25.6l5.252-2.845-5.252-2.845"
            strokeWidth={5.31}
          />
          <path
            data-name="Path 20800"
            d="M42.364 25.6l-5.248-2.845 5.248-2.845"
            strokeWidth={5.31}
          />
          <path
            data-name="Path 20801"
            d="M43.969 36.463a3.41 3.41 0 01-3.321 2.388 3.5 3.5 0 01-3.345-2.542h0a3.474 3.474 0 00-3.27-2.3 3.528 3.528 0 00-3.321 2.458 3.41 3.41 0 01-3.321 2.388 3.5 3.5 0 01-3.344-2.542 3.5 3.5 0 00-3.256-2.3 3.542 3.542 0 00-3.313 2.45 3.41 3.41 0 01-3.321 2.388 3.5 3.5 0 01-3.344-2.542"
            strokeWidth={4.25}
          />
        </g>
        <g
          data-name="Group 90871"
          fill="none"
          stroke="#6552cc"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            data-name="Path 20802"
            d="M50.663 12.926a27.395 27.395 0 11-6.414-7.132"
            strokeWidth={4.25}
            transform="rotate(-30 427.639 89) translate(181.451 374.198)"
          />
          <path
            data-name="Path 20803"
            d="M42.122 31.981a15.431 15.431 0 01-29.462 0h29.457z"
            strokeWidth={4.25}
            transform="rotate(-30 427.639 89) translate(181.451 374.198)"
          />
          <path
            data-name="Path 20804"
            d="M12.081 22.414a3.4 3.4 0 015.765-.2"
            strokeWidth={5}
            transform="rotate(-30 427.639 89) translate(181.451 374.198)"
          />
          <path
            data-name="Path 20805"
            d="M42.701 22.414a3.4 3.4 0 00-5.766-.2"
            strokeWidth={5}
            transform="rotate(-30 427.639 89) translate(181.451 374.198)"
          />
          <path
            data-name="Path 20806"
            d="M50.304 8.107l-3.648-6.848-3.648 6.848a5.053 5.053 0 00-.886 2.687 4.534 4.534 0 009.068 0 4.954 4.954 0 00-.886-2.687z"
            strokeWidth={4.25}
            transform="rotate(-30 427.639 89) translate(181.451 374.198)"
          />
        </g>
        <g
          data-name="Group 90872"
          fill="none"
          stroke="#6552cc"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            data-name="Path 20807"
            d="M3.467 29.821c-.07-.8-.107-1.609-.107-2.426a27.386 27.386 0 115.127 15.962"
            strokeWidth={4.25}
            transform="rotate(-30 427.639 89) translate(275.647 86.465)"
          />
          <path
            data-name="Path 20808"
            d="M15.446 22.414a3.4 3.4 0 015.765-.2"
            strokeWidth={5}
            transform="rotate(-30 427.639 89) translate(275.647 86.465)"
          />
          <path
            data-name="Path 20809"
            d="M46.065 22.414a3.4 3.4 0 00-5.765-.2"
            strokeWidth={5}
            transform="rotate(-30 427.639 89) translate(275.647 86.465)"
          />
          <path
            data-name="Path 20810"
            d="M33.07 42.592a3.888 3.888 0 01-4.977-5.933 3.913 3.913 0 011.166-.812h0a3.887 3.887 0 011.59-7.435 3.857 3.857 0 012.216.7"
            strokeWidth={4.25}
            transform="rotate(-30 427.639 89) translate(275.647 86.465)"
          />
          <path
            data-name="Path 20811"
            d="M14.895 30.223a4.334 4.334 0 00-5.863 1.8 3.412 3.412 0 00-.434 1.651 3.464 3.464 0 00-.434-1.651A4.337 4.337 0 00.02 33.614c-.285 2.766 2.491 4.487 4.329 6.073 1.4 1.213 2.817 2.412 4.264 3.6q1.9-1.581 3.778-3.2c.91-.784 1.819-1.572 2.71-2.384 1.2-1.1 2.178-2.094 2.1-3.83a4.382 4.382 0 00-2.3-3.652z"
            strokeWidth={4.25}
            transform="rotate(-30 427.639 89) translate(275.647 86.465)"
          />
        </g>
        <g
          data-name="Group 90873"
          transform="rotate(-30 427.639 89) translate(85.317 182.356)"
        >
          <circle
            data-name="Ellipse 129124"
            cx={27.395}
            cy={27.395}
            r={27.395}
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20812"
            d="M288.3 456.881a2.421 2.421 0 112.421 2.421 2.419 2.419 0 01-2.421-2.421z"
            transform="translate(-250.978 -435.862)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20813"
            d="M42.124 31.981a15.431 15.431 0 01-29.462 0"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Line 455"
            transform="translate(11.027 31.309)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
            d="M2.808 0L0 1.343"
          />
          <path
            data-name="Line 456"
            transform="translate(40.955 31.309)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
            d="M0 0L2.808 1.343"
          />
          <path
            data-name="Path 20814"
            d="M12.086 22.418a3.4 3.4 0 015.766-.2"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={5}
          />
        </g>
        <g
          data-name="Group 90874"
          transform="rotate(-30 427.639 89) translate(271.864 272.263)"
        >
          <circle
            data-name="Ellipse 129125"
            cx={27.395}
            cy={27.395}
            r={27.395}
            transform="rotate(-76.72 33.689 26.663)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20815"
            d="M700.17 661.581a2.421 2.421 0 112.421 2.419 2.422 2.422 0 01-2.421-2.419z"
            transform="translate(-657.273 -634.984)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20816"
            d="M17.657 27.997a3.4 3.4 0 015.765-.2"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={5}
          />
          <path
            data-name="Path 20817"
            d="M47.94 37.564a22.273 22.273 0 01-29.947 0"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Line 457"
            transform="translate(16.603 36.887)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
            d="M2.808 0L0 1.343"
          />
          <path
            data-name="Line 458"
            transform="translate(46.526 36.887)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
            d="M0 0L2.813 1.343"
          />
          <path
            data-name="Path 20818"
            d="M26.772 42.476v5.481a6.195 6.195 0 0012.389 0v-5.481"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Line 459"
            transform="translate(32.971 43.348)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
            d="M0 0L0 4.012"
          />
        </g>
        <g
          data-name="Group 90875"
          transform="rotate(-30 427.639 89) translate(169.963 266.902)"
          stroke="#6552cc"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle
            data-name="Ellipse 129126"
            cx={27.395}
            cy={27.395}
            r={27.395}
            transform="rotate(-45 46.767 19.372)"
            fill="none"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20819"
            d="M29.069 30.494a1.868 1.868 0 00-2.528.774.381.381 0 11-.373 0 1.871 1.871 0 00-3.512.686c-.121 1.194 1.073 1.931 1.866 2.617.606.522 1.213 1.04 1.838 1.549.546-.453 1.087-.914 1.628-1.376a46.98 46.98 0 001.166-1.026 2.031 2.031 0 00.9-1.651 1.885 1.885 0 00-.985-1.573z"
            fill="#6552cc"
            strokeWidth={5}
          />
          <path
            data-name="Path 20820"
            d="M48.412 30.494a1.868 1.868 0 012.528.774.381.381 0 10.373 0 1.871 1.871 0 013.512.686c.121 1.194-1.073 1.931-1.866 2.617a86.815 86.815 0 01-1.838 1.549c-.546-.453-1.087-.914-1.628-1.376a46.98 46.98 0 01-1.166-1.026 2.031 2.031 0 01-.9-1.651 1.885 1.885 0 01.985-1.573z"
            fill="#6552cc"
            strokeWidth={5}
          />
          <path
            data-name="Path 20821"
            d="M53.466 43.331a15.431 15.431 0 01-29.462 0"
            fill="none"
            strokeWidth={4.25}
          />
          <path
            data-name="Line 460"
            transform="translate(22.371 42.659)"
            fill="none"
            strokeWidth={4.25}
            d="M2.808 0L0 1.343"
          />
          <path
            data-name="Line 461"
            transform="translate(52.299 42.659)"
            fill="none"
            strokeWidth={4.25}
            d="M0 0L2.808 1.343"
          />
        </g>
        <g
          data-name="Group 90876"
          transform="rotate(-30 427.639 89) translate(81.249 371.445)"
          fill="none"
          stroke="#6552cc"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle
            data-name="Ellipse 129127"
            cx={27.395}
            cy={27.395}
            r={27.395}
            transform="rotate(-80.78 31.785 27.042)"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20822"
            d="M46.162 35.993a15.431 15.431 0 01-29.462 0"
            strokeWidth={4.25}
          />
          <path
            data-name="Line 462"
            transform="translate(15.063 35.322)"
            strokeWidth={4.25}
            d="M2.808 0L0 1.343"
          />
          <path
            data-name="Line 463"
            transform="translate(44.991 35.322)"
            strokeWidth={4.25}
            d="M0 0L2.808 1.343"
          />
          <path
            data-name="Path 20823"
            d="M16.122 24.812a3.4 3.4 0 005.765.2"
            strokeWidth={5}
          />
          <path
            data-name="Path 20824"
            d="M46.74 24.812a3.4 3.4 0 01-5.765.2"
            strokeWidth={5}
          />
        </g>
        <g
          data-name="Group 90877"
          transform="rotate(-30 427.639 89) translate(364.05 268.799)"
          fill="none"
          stroke="#6552cc"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle
            data-name="Ellipse 129128"
            cx={27.395}
            cy={27.395}
            r={27.395}
            transform="matrix(.891 -.453 .453 .891 0 24.832)"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20825"
            d="M21.857 35.108l5.229-2.883-5.271-2.808"
            strokeWidth={5.31}
          />
          <path
            data-name="Path 20826"
            d="M51.804 34.884l-5.276-2.808 5.234-2.883"
            strokeWidth={5.31}
          />
          <path
            data-name="Path 20827"
            d="M22.459 60.115a5.717 5.717 0 01-.588-2.519 5.961 5.961 0 011.423-3.722 6.72 6.72 0 001.712-3.853c.009-.14.014-.275.014-.415a6.517 6.517 0 00-1.348-3.825 12.084 12.084 0 01-1.2-1.717 5.692 5.692 0 01-.578-2.132 6.391 6.391 0 01-.014-.415 5.722 5.722 0 01.644-2.64"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20828"
            d="M15.798 54.327a6.29 6.29 0 01.34-.457 6.719 6.719 0 001.712-3.853c.009-.14.014-.275.014-.415a6.517 6.517 0 00-1.348-3.825 12.084 12.084 0 01-1.2-1.717 5.692 5.692 0 01-.578-2.132 6.391 6.391 0 01-.014-.415 5.722 5.722 0 01.644-2.64"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20829"
            d="M51.365 60.027a5.662 5.662 0 00.564-2.477 5.961 5.961 0 00-1.423-3.722 6.72 6.72 0 01-1.712-3.853 6.391 6.391 0 01-.014-.415 6.517 6.517 0 011.348-3.825 12.065 12.065 0 001.2-1.717 5.693 5.693 0 00.578-2.132c.009-.14.014-.275.014-.415a5.721 5.721 0 00-.644-2.64"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20830"
            d="M57.976 54.229c-.093-.135-.2-.271-.3-.4a6.719 6.719 0 01-1.712-3.853 6.391 6.391 0 01-.014-.415 6.517 6.517 0 011.348-3.825 12.079 12.079 0 001.2-1.717 5.692 5.692 0 00.578-2.132c.009-.14.014-.275.014-.415a5.723 5.723 0 00-.644-2.64"
            strokeWidth={4.25}
          />
          <path
            data-name="Path 20831"
            d="M28.667 41.373a14.458 14.458 0 0116.354.555"
            strokeWidth={4.25}
          />
        </g>
        <g
          data-name="Group 90878"
          fill="none"
          stroke="#6552cc"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            data-name="Path 20832"
            d="M55.434 39.361a27.406 27.406 0 01-49.3 0"
            strokeWidth={4.25}
            transform="rotate(-30 427.639 89) translate(178.022 182.309)"
          />
          <path
            data-name="Path 20833"
            d="M3.582 30.671a26.689 26.689 0 01-.191-3.069 27.4 27.4 0 1154.59 3.121"
            strokeWidth={4.25}
            transform="rotate(-30 427.639 89) translate(178.022 182.309)"
          />
          <path
            data-name="Path 20834"
            d="M45.55 31.865a15.428 15.428 0 01-29.457.224z"
            strokeWidth={4.25}
            transform="rotate(-30 427.639 89) translate(178.022 182.309)"
          />
          <path
            data-name="Path 20835"
            d="M15.799 25.708l5.229-2.887-5.271-2.8"
            strokeWidth={5.31}
            transform="rotate(-30 427.639 89) translate(178.022 182.309)"
          />
          <path
            data-name="Path 20836"
            d="M45.746 25.478l-5.276-2.8 5.229-2.887"
            strokeWidth={5.31}
            transform="rotate(-30 427.639 89) translate(178.022 182.309)"
          />
          <path
            data-name="Path 20837"
            d="M9.021 35.779l2.23-7.431-7.412 2.29a5.08 5.08 0 00-2.524 1.283 4.535 4.535 0 006.442 6.386 4.946 4.946 0 001.264-2.533z"
            strokeWidth={4.25}
            transform="rotate(-30 427.639 89) translate(178.022 182.309)"
          />
          <path
            data-name="Path 20838"
            d="M52.552 35.779l-2.23-7.431 7.412 2.29a5.08 5.08 0 012.524 1.283 4.535 4.535 0 01-6.442 6.386 4.946 4.946 0 01-1.264-2.533z"
            strokeWidth={4.25}
            transform="rotate(-30 427.639 89) translate(178.022 182.309)"
          />
        </g>
        <g
          data-name="Group 90879"
          transform="rotate(-30 427.639 89) translate(373.405 374.147)"
        >
          <path
            data-name="Path 20839"
            d="M852.96 868.041a2.421 2.421 0 112.421 2.421 2.419 2.419 0 01-2.421-2.421z"
            transform="translate(-840.333 -847.022)"
            fill="#6552cc"
          />
          <path
            data-name="Path 20840"
            d="M905.9 868.041a2.421 2.421 0 112.421 2.421 2.419 2.419 0 01-2.421-2.421z"
            transform="translate(-868.578 -847.022)"
            fill="#6552cc"
          />
          <circle
            data-name="Ellipse 129129"
            cx={27.395}
            cy={27.395}
            r={27.395}
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
          />
          <path
            data-name="Line 464"
            transform="translate(13.252 34.873)"
            fill="none"
            stroke="#6552cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4.25}
            d="M0 0L28.286 0"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
export function DarkpurpleIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 51,
    height: 51,
    color: '#f4f2ff',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51 51"
      {...rest}
      sx={rootSx}
    >
      <circle
        data-name="Ellipse 129144"
        cx={25.5}
        cy={25.5}
        r={25.5}
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export function LightPurpleIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 41,
    height: 41,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41 41"
      {...rest}
      sx={rootSx}
    >
      <g
        data-name="Ellipse 129147"
        fill="#f7f6ff"
        stroke="#f9f7ff"
        strokeWidth={1}
      >
        <circle cx={20.5} cy={20.5} r={20.5} stroke="none" />
        <circle cx={20.5} cy={20.5} r={20} fill="none" />
      </g>
    </SvgIcon>
  );
}
export function ProfileIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 14,
    width: 14,
    color: '#5C6266',
    stroke: '#5c6266',
    strokeWidth: '0.4',
    ...rootStyle,
  };

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="9.629"
      height="12.2"
      viewBox="0 0 9.629 12.2"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-user"
        d="M12.714,4A3,3,0,1,0,15.66,7,2.98,2.98,0,0,0,12.714,4Zm0,.9A2.1,2.1,0,1,1,10.652,7,2.075,2.075,0,0,1,12.714,4.9ZM9.172,11.2A1.19,1.19,0,0,0,8,12.394v.456a2.8,2.8,0,0,0,1.563,2.419A6.534,6.534,0,0,0,12.714,16a6.534,6.534,0,0,0,3.151-.731A2.893,2.893,0,0,0,17.358,13.3h.071v-.906A1.19,1.19,0,0,0,16.256,11.2Zm0,.9h7.084a.285.285,0,0,1,.289.294V12.4h0v.45a1.828,1.828,0,0,1-1.107,1.631,5.786,5.786,0,0,1-2.724.619,5.786,5.786,0,0,1-2.724-.619A1.828,1.828,0,0,1,8.884,12.85v-.456A.284.284,0,0,1,9.172,12.1Z"
        transform="translate(-7.9 -3.9)"
        fill="currentColor"
        stroke="#888"
        strokeWidth="0.8"
      />
    </SvgIcon>
  );
}

export const SelectActivity_Style = {
  rootSx: { mt: 5.2, p: 2 },
  appBarStyleSx: {
    backgroundColor: 'purple.A100',
  },
  screenLayoutChildernSx: {
    pt: 3,

    pb: 8.5,
  },
  speedDialRootSx: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    bgcolor: '#6552CC',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  SecSpeedDialRootSx: {
    height: '50px',
    boxShadow: '0px 0px 10px #00000029',
    width: '50px',
    borderRadius: '50%',
    mt: '88px',
    bgcolor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  buttonSx: {
    background: ' #6552CC',
    borderRadius: '8px',
    width: '150px',
    color: ' #FFFFFF',
    '&:hover': {
      background: ' #6552CC',
    },
  },
  backbuttonSx: {
    width: '150px',
    background: '#F3F0FF',
    color: ' #6552CC',
    '&:hover': {
      background: '#F3F0FF',
    },
  },
  expertAppbarSx: {
    pl: 2.5,
    pr: 2.5,
    py: 2,
    boxSizing: 'border-box',
    bgcolor: '#fff',
    borderRadius: '0px 0px 24px 24px',
    boxShadow: '0px 0px 10px #00000029',
    position: 'fixed',
    top: '6%',
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },
  SearchSelectSx: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '85% 10%',
    boxSizing: 'border-box',
    gap: '15px',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    // mt: 2,
  },
  textFieldStyle: {
    width: '100%',
    marginTop: '4px',
    '& .MuiInputBase-root': { backgroundColor: '#fff', borderRadius: '8px' },
    '& .MuiOutlinedInput-input': {
      width: '100%',
      fontSize: '14px',
      fontWeight: '500',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: '#EEEEEE',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
  },
  filterIconSx: {
    border: '1px solid #DBEAE8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    height: '38px',
    width: '40px',
    ml: 'auto',
    cursor: 'pointer',
    mt: 0.4,
  },
  drawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    px: 2.5,
    pb: 2,
  },
  cancelBtnSx: {
    textTransform: 'none',
    backgroundColor: '#F3F0FF',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: '#F3F0FF',
    },
  },
  applyBtnSx: {
    textTransform: 'none',
  },
  drawerTitleSx: {
    textAlign: 'left',
    fontSize: '12px',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#5C6266',
    py: 1,
  },
  buttonactivenumSX: {
    border: '1px solid',
    width: 'max-content',

    minHeight: '40px',
    borderRadius: '10px',
    padding: '5px 10px',
    backgroundColor: '#EE8438',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#EE8438',
      color: '#fff',
    },
  },
  buttoninactivenumSX: {
    border: '1px solid ',
    borderColor: '#E3E3E3',
    borderRadius: '10px',
    width: 'max-content',

    minHeight: '40px',
    padding: '5px 10px',
    color: '#5C6266',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#5C6266',
    },
  },
};

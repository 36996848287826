import { QusAnsListing, QusAnsSelection } from '@components';
import { Box, Divider } from '@mui/material';

import PropTypes from 'prop-types';
import { qusAnsSelectionLisiting_style } from './style';

export function QusAnsSelectionLisiting(props) {
  const {
    id = '',
    QusAnsSelectionHeading = '',
    QusAnsListingAdded = '',
    QusAnsSelectionListing,
    sugesstionClick = () => {},
    QusAnsListingHeading = '',
    displaySelectionValues = [],
    quickSuggestionRequired = [],
    OnClearAll = () => false,
    OnDelete = () => false,
    qsOptionViewKey,
    quickSuggestion,
    qusAnsSelectionHeadingStyle = {},
    className = '',
    qusAnsListingCardStyle = {},
    rootStyle = {},
    showDivider = true,
    isShowClearAll,
    isShowCount,
    QusAnsListingHeadStyle = {},
    ListCardTypoSX,
    ...rest
  } = props;

  return (
    <Box
      sx={{ ...qusAnsSelectionLisiting_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      {/* QusAnsSelection Card */}
      <QusAnsSelection
        QusAnsSelectionHeading={QusAnsSelectionHeading}
        QusAnsSelectionListing={QusAnsSelectionListing}
        quickSuggestion={quickSuggestion}
        displaySelectionValues={displaySelectionValues}
        quickSuggestionRequired={quickSuggestionRequired}
        sugesstionClick={sugesstionClick}
        qsOptionViewKey={qsOptionViewKey}
        qusAnsSelectionHeadingStyle={qusAnsSelectionHeadingStyle}
      />

      {/* QusAnsListing Card */}
      {displaySelectionValues?.length > 0 && (
        <>
          {showDivider ? (
            <Divider sx={{ my: 2, borderStyle: 'dashed', color: '#C5C5C5' }} />
          ) : (
            <div style={{ margin: '16px 0px' }} />
          )}
          <QusAnsListing
            id={id}
            OnClearAll={OnClearAll}
            QusAnsListingAdded={QusAnsListingAdded}
            QusAnsListingHeading={QusAnsListingHeading}
            displaySelectionValues={displaySelectionValues}
            OnDelete={OnDelete}
            qusAnsListingCardStyle={qusAnsListingCardStyle}
            isShowClearAll={isShowClearAll}
            isShowCount={isShowCount}
            QusAnsListingHeadStyle={QusAnsListingHeadStyle}
            ListCardTypoSX={ListCardTypoSX}
          />
        </>
      )}
    </Box>
  );
}
QusAnsSelectionLisiting.propTypes = {
  id: PropTypes.string,
  sugesstionClick: PropTypes.func,
  qusAnsSelectionHeadingStyle: PropTypes.object,
  isShowClearAll: PropTypes.bool,
  isShowCount: PropTypes.bool,
  QusAnsListingHeadStyle: PropTypes.object,
  rootStyle: PropTypes.object,
  qusAnsListingCardStyle: PropTypes.object,
  QusAnsListingHeading: PropTypes.string,
  QusAnsListingAdded: PropTypes.string,
  OnDelete: PropTypes.func,
  QusAnsSelectionHeading: PropTypes.string,
  QusAnsSelectionListing: PropTypes.any,
  quickSuggestion: PropTypes.bool,
  quickSuggestionRequired: PropTypes.array,
  displaySelectionValues: PropTypes.array,
  dividerStyle: PropTypes.object,
  onClick: PropTypes.func,
  OnClearAll: PropTypes.func,
  qsOptionViewKey: PropTypes.any,
  className: PropTypes.object,
  sx: PropTypes.object,
  showDivider: PropTypes.bool,
};

export const expertPlanCard_style = {
  cardRootSx: {
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    p: 1.5,
    mb: 1.5,
    bgcolor: '#fff',
  },
  imgBoxSx: {
    height: '63px',
    width: '65px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    background: '#00000080',
  },
  imgBox2Sx: {
    height: '63px',
    width: '65px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    background: '#F5F5F5',
  },
};

/* eslint-disable import/no-cycle */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  previewPlanDataContsruct,
  queryClient
} from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';
import { usePlanAddEditTool } from './planAddEditTool';

export const useExpertRequest = create((set, get) => ({
  requestState: {
    tabIndex: '1',
    requestData: [],
    requestCount: '',
    planTemplateData: [],
    planTemplateDataById: {},
    previousPlanData: [],
    previousPlanDataById: {},
    planConstructedData: {},
    filters: {
      search: '',
      gender_filter: '',
      date: '',
      date_filter: {
        start_date: '',
        end_date: '',
      },
      type_filter: '',
    },
    requestDataById: {},
    planCounts: {
      planTemplateCount: '',
      previousPlanCount: '',
    },
  },
  templateLoading: null,
  loading: null,
  setReqDataLocalStorage: () => {
    const { requestState } = get();
    localStorage.setItem(
      localStorageKeys?.requestState,
      JSON.stringify(requestState),
    );
  },
  getReqDataLocalStorage: () => {
    const data = localStorage.getItem(localStorageKeys?.requestState);
    if (data !== 'undefined') {
      const jsonData = JSON.parse(data);
      if (jsonData) {
        set({
          requestState: jsonData,
        });
      }
    }
  },

  clearRequestState: () => {
    const { requestState, setReqDataLocalStorage } = get();
    set({
      requestState: {
        ...requestState,
        requestData: [],
        planTemplateData: [],
        planTemplateDataById: {},
        planConstructedData: {},
        previousPlanData: [],
        previousPlanDataById: {},
        filters: {
          search: '',
          gender_filter: '',
          date: '',
          date_filter: {
            start_date: '',
            end_date: '',
          },
          type_filter: '',
        },
        requestDataById: {},
        planCounts: {
          planTemplateCount: '',
          previousPlanCount: '',
        },
      },
    });
    setReqDataLocalStorage();
  },
  getRequest: async (offset, isInitial) => {
    try {
      const { requestState, setReqDataLocalStorage } = get();
      const { filters } = requestState;
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);

      set({ loading: true });
      const payload = {
        user_id: tokenData?.user_id ?? '',
        page: offset ?? 1,
        limit: 10,
        search: filters?.search ?? '',
        gender_filter: filters?.gender_filter ?? '',
        date_filter: filters?.date_filter ?? '',
        type_filter: filters?.type_filter ?? '',
      };

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['expert-request'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/expert/plan-request/get/all`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      let array = [];
      for (const val of data?.plan_requests) {
        let obj = {};
        let mind = tokenData?.is_mind_expert ? 'Inprogress' : 'Not yet';
        let body = tokenData?.is_body_expert ? 'Inprogress' : 'Not yet';
        let food = tokenData?.is_food_expert ? 'Inprogress' : 'Not yet';

        if (val?.mbf_expert_plan_statuses?.length > 0) {
          for (const object of val?.mbf_expert_plan_statuses) {
            if (object?.master_mbf_category?.category_name === 'Mind') {
              mind = 'Completed';
            } else if (object?.master_mbf_category?.category_name === 'Body') {
              body = 'Completed';
            } else if (object?.master_mbf_category?.category_name === 'Food') {
              food = 'Completed';
            }
          }
        }

        obj = {
          ...val,
          name: val?.mbf_user?.profile_data?.name,
          profile_pic: val?.mbf_user?.profile_data?.profile_pic,
          age: val?.mbf_user?.profile_data?.age,
          gender: val?.mbf_user?.profile_data?.gender,
          mind,
          body,
          food,
        };
        array.push(obj);
      }

      const arrayData = [...requestState?.requestData, ...array];

      const key = 'id';
      const result = [
        ...new Map(arrayData.map((item) => [item[key], item])).values(),
      ];

      if (status?.code === 200) {
        const { requestState } = get();
        set({
          loading: false,
          requestState: {
            ...requestState,
            requestData: isInitial ? array : result,
            requestCount: data?.count ?? 0,
          },
        });
      }
      setReqDataLocalStorage();
      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  getRequestById: async (user_plan_id, val, key) => {
    try {
      const { setReqDataLocalStorage } = get();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: [`expert-request-by-id${user_plan_id}`],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/expert/plan-request/get?user_id=${tokenData?.user_id}&user_plan_id=${user_plan_id}`,
            {},
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      const object = {
        mind: val?.mind,
        body: val?.body,
        food: val?.food,
        ...data,
      };

      if (status?.code === 200) {
        const { requestState } = get();
        const { onUpdateToolData } = usePlanAddEditTool.getState();
        if (key !== 'profile') {
          onUpdateToolData('userData', object);
        }

        set({
          loading: false,
          requestState: {
            ...requestState,
            requestDataById: object,
          },
        });
      }
      setReqDataLocalStorage();
      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  updateUserStatus: async (user_plan_id, statusId) => {
    try {
      const { setReqDataLocalStorage } = get();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });

      const payload = {
        user_id: tokenData?.user_id,
        user_plan_id,
        status: statusId,
      };

      const { status } = await queryClient.fetchQuery({
        queryKey: [`expert-update-status`],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/expert/plan-status-and-indraft-expert/update`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        set({
          loading: false,
        });
      }
      setReqDataLocalStorage();
      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  getPlanCount: async (mbfUseId) => {
    try {
      const { setReqDataLocalStorage } = get();
      set({ loading: true });
      const payload = {
        mbf_users_id: mbfUseId,
      };

      const { data, status } = await queryClient.fetchQuery({
        queryKey: [`expert-plan-count`],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/expert/previous-and-plan-template-count`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 12000,
      });

      if (status?.code === 200) {
        const { requestState } = get();
        const { planCounts } = requestState;
        set({
          loading: false,
          requestState: {
            ...requestState,
            planCounts: {
              ...planCounts,
              planTemplateCount: data?.planTemplateCount ?? '',
              previousPlanCount: data?.previousPlanCount ?? '',
            },
          },
        });
      }
      setReqDataLocalStorage();
      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getTemplateList: async (offset, value) => {
    try {
      const { setReqDataLocalStorage, requestState } = get();
      set({ templateLoading: true });
      const payload = {
        offset,
        limit: 10,
      };

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['expert-plan-template-list', payload],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/expert/plan-template-list`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 12000,
      });

      const obj = {
        data: value
          ? [...requestState?.planTemplateData?.data, ...data.data]
          : data?.data,
        dataCount: data?.dataCount ?? 0,
      };

      if (status?.code === 200) {
        const { requestState } = get();
        set({
          templateLoading: false,
          requestState: {
            ...requestState,
            planTemplateData: obj,
          },
        });
      }
      setReqDataLocalStorage();
      return status;
    } catch (err) {
      set({ templateLoading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getTemplateDataById: async (PlanId, previousPlandata) => {
    const { setReqDataLocalStorage } = get();
    try {
      set({ loading: true });
      const payload = {
        plan_id: PlanId,
      };

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['expert-plan-template-detail-view', payload],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/expert/plan-template-detail-view`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 12000,
      });

      if (status?.code === 200) {
        const { requestState } = get();
        const { onUpdateToolData, onDurationUpdation } =
          usePlanAddEditTool.getState();
        onUpdateToolData('overAllPlanData', data);
        onDurationUpdation('plan_name', data?.planData?.plan_name);
        onDurationUpdation('duration_type', data?.planData?.duration_type);
        onDurationUpdation('duration', data?.planData?.duration);
        if (previousPlandata?.activityData?.length > 0) {
          const reObj = {
            planData: data?.planData,
            activityData: previousPlandata?.activityData ?? [],
          };
          const constructPreviousPlanData = previewPlanDataContsruct(reObj);
          const constructData = previewPlanDataContsruct(data);

          const resArr = [
            {
              parentKey: 'mind',
              expert_category_id: 1,
            },
            {
              parentKey: 'body',
              expert_category_id: 2,
            },
            {
              parentKey: 'food',
              expert_category_id: 3,
            },
          ];
          let objectData = {};
          for (const object of resArr) {
            if (
              constructPreviousPlanData?.[object?.parentKey]?.planDayData
                ?.length > 0
            ) {
              objectData = {
                ...objectData,
                [object?.parentKey]: {
                  ...constructPreviousPlanData?.[object?.parentKey],
                },
              };
            } else {
              objectData = {
                ...objectData,
                [object?.parentKey]: {
                  ...constructData?.[object?.parentKey],
                },
              };
            }
          }
          onUpdateToolData('overAllConstructedPlanData', objectData);
          set({
            loading: false,
            requestState: {
              ...requestState,
              planTemplateDataById: data,
              planConstructedData: objectData,
            },
          });
        } else {
          const constructData = previewPlanDataContsruct(data);
          onUpdateToolData('overAllConstructedPlanData', constructData);
          set({
            loading: false,
            requestState: {
              ...requestState,
              planTemplateDataById: data,
              planConstructedData: constructData,
            },
          });
        }
      }
      setReqDataLocalStorage();
      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  getPreviousPlanList: async (previousPlanOffset, value) => {
    try {
      const { requestState, setReqDataLocalStorage } = get();
      const { requestDataById } = requestState;

      set({ loading: true });
      const payload = {
        mbf_user_id: requestDataById?.mbf_users_id ?? '',
        limit: 10,
        offset: previousPlanOffset,
      };

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['previous-plan-list'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/expert/previous-plan-list`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        let array = value ? requestState?.previousPlanData : [];
        for (const value of data?.previousPlanList) {
          const obj = {
            id: value?.id ?? '',
            date: value?.plan_start_date ?? '',
            month: `${value?.duration} ${value?.duration_type_name}`,
            activities: `${value?.activity_count ?? 0} activities`,
            planName: value?.plan_name ?? '',
            mind: value?.mindBodyFoodProgress[2]?.percentage ?? '',
            body: value?.mindBodyFoodProgress[0]?.percentage ?? '',
            food: value?.mindBodyFoodProgress[1]?.percentage ?? '',
          };
          array.push(obj);
        }
        set({
          loading: false,
          requestState: {
            ...requestState,
            previousPlanData: array,
          },
        });
      }

      setReqDataLocalStorage();
      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  updateFilters: (key, value) => {
    const { requestState, setReqDataLocalStorage } = get();
    const { filters } = requestState;

    set({
      requestState: {
        ...requestState,
        filters: {
          ...filters,
          [key]: value,
        },
      },
    });
    setReqDataLocalStorage();
  },
  updateRequestState: (key, value) => {
    const { requestState, setReqDataLocalStorage } = get();

    set({
      requestState: {
        ...requestState,
        [key]: value,
      },
    });
    setReqDataLocalStorage();
  },
  updateTabIndex: (key, value) => {
    const { requestState, setReqDataLocalStorage } = get();

    set({
      requestState: {
        ...requestState,
        tabIndex: value,
      },
    });
    setReqDataLocalStorage();
  },
  clearFilterState: () => {
    const { requestState, setReqDataLocalStorage } = get();
    const { filters } = requestState;

    set({
      requestState: {
        ...requestState,
        filters: {
          ...filters,
          gender_filter: '',
          date: '',
          date_filter: {
            start_date: '',
            end_date: '',
          },
          type_filter: '',
        },
      },
    });
    setReqDataLocalStorage();
  },
}));

import { getDateFormat } from '@hc/dayjs';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import {
  BrainIcon,
  CalendarIcon,
  CircularProgressWithLabel,
  FoodIcon,
  LeftArrowClickIcon,
  RuningIcon,
} from '../../../atoms';
import { previousPlansCard_style } from './style';

function PreviousPlansCard(props) {
  const {
    className = '',
    rootStyle = {},
    data = {},
    onClick = () => {},
    index,
    ...rest
  } = props;

  const progressData = [
    {
      name: 'Mind',
      icon: <BrainIcon color="#C5C5C5" />,
      progressValue: data?.mind,
      CircularProgressMainColor: '#55B4D1',
      CircularProgressBackGroundColorColor: '#fff',
    },
    {
      name: 'Body',
      icon: <RuningIcon rootStyle={{ height: '24px' }} color="#C5C5C5" />,
      progressValue: data?.body,
      CircularProgressMainColor: '#97C540',
      CircularProgressBackGroundColorColor: '#fff',
    },
    {
      name: 'Food',
      icon: <FoodIcon color="#C5C5C5" />,
      progressValue: data?.food,
      CircularProgressMainColor: '#55D19D',
      CircularProgressBackGroundColorColor: '#fff',
    },
  ];

  return (
    <Box
      id={`previousPlan${index}`}
      sx={{
        ...previousPlansCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
      onClick={onClick}
    >
      <Stack>
        <Stack flexDirection="row" justifyContent="space-between" mb={2}>
          <Stack flexDirection="row">
            <CalendarIcon rootStyle={{ color: '#888888' }} />
            <Typography sx={previousPlansCard_style.dateFontSx}>
              &nbsp;{getDateFormat(data?.date, 'DD MMM YYYY, hh:mm A')}
            </Typography>
          </Stack>
          <Typography sx={previousPlansCard_style.completedTextSx}>
            Completed
          </Typography>
        </Stack>

        <Stack flexDirection="row" justifyContent="space-between" mb={2}>
          <Box>
            <Typography sx={previousPlansCard_style.planFontSx}>
              {' '}
              {data?.planName}
            </Typography>{' '}
            <Stack flexDirection="row" alignItems="center">
              <Typography sx={previousPlansCard_style.monthFontSx}>
                {' '}
                {data?.month} &nbsp;
              </Typography>
              <RiCheckboxBlankCircleFill
                style={{ fontSize: '4px', pl: 1, pr: 0.5, color: 'grey' }}
              />
              <Typography sx={previousPlansCard_style.activityFontSx}>
                {' '}
                &nbsp; {data?.activities}{' '}
              </Typography>
            </Stack>
          </Box>
          <Box>
            <IconButton disableFocusRipple disableRipple>
              <LeftArrowClickIcon rootStyle={{ color: '#6552CC' }} />
            </IconButton>
          </Box>
        </Stack>
        <Stack flexDirection="row" justifyContent="space-between" mb={1}>
          {progressData.map((val, i) => (
            <>
              <Stack alignItems="center" key={i} pr={0.6}>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  mb={0.8}
                >
                  <Box> {val.icon}</Box>
                  <Typography sx={previousPlansCard_style.typeFont}>
                    {' '}
                    &nbsp; {val.name}
                  </Typography>
                </Stack>
                <CircularProgressWithLabel
                  value={Math.round(val.progressValue)}
                  size={35}
                  thickness={4}
                  typo={{ fontSize: '12px', color: '#888888' }}
                  color={{
                    mainColor: val.CircularProgressMainColor,
                    backgroundColor: val.CircularProgressBackGroundColorColor,
                  }}
                />
              </Stack>
              {i === progressData.length - 1 ? null : (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: '20px', mt: 0.3 }}
                />
              )}
            </>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}

PreviousPlansCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  data: PropTypes.object,
  onClick: PropTypes.func,
};

export { PreviousPlansCard };

/* eslint-disable import/no-cycle */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable no-lonely-if */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, localStorageKeys, queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';
import { usePlanAddEditTool } from './planAddEditTool';

export const useCreateAndAddActivity = create((set, get) => ({
  activityState: {
    activityData: {
      activityCount: '',
      activityData: [],
    },
    selectedActivityData: [],
    activity_image_obj: {},
    createNewActivityData: {
      id: '',
      activity_name: '',
      activity_image: '',
      category_id: '',
      goal_id: '',
      is_measurable: true,
      quantity: null,
      unit: 1,
      activity_days: [],
      activity_time: '',
      specific_time: {
        from: '',
        to: '',
      },
      activity_notes: [],
      activity_references: [],
      activity_dont: [],
      goal_name: '',
      is_active: null,
      activity_time_name: '',
      category_name: '',
      unit_name: '',
      isNew: true,
    },
    filters: {
      search: '',
      goal_id: [],
    },
    addActivitySelectedData: [],
    error: {
      activity_name: '',
      activity_days: '',
      activity_time: '',
      quantity: '',
      unit: '',
      goal_id: '',
    },
  },
  loading: null,

  setActivityLocalStorage: () => {
    const { activityState } = get();
    localStorage.setItem(
      localStorageKeys?.activityState,
      JSON.stringify(activityState),
    );
  },
  getActivityLocalStorage: () => {
    const data = localStorage.getItem(localStorageKeys?.activityState);
    const jsonData = JSON.parse(data);
    if (jsonData) {
      set({
        basicInfoState: jsonData,
      });
    }
  },
  clearCreateNewActivityData: () => {
    const { activityState, setActivityLocalStorage } = get();
    const { createNewActivityData } = activityState;
    set({
      activityState: {
        ...activityState,
        activity_image_obj: {},
        createNewActivityData: {
          ...createNewActivityData,
          id: '',
          activity_name: '',
          activity_image: '',
          category_id: '',
          goal_id: '',
          is_measurable: true,
          quantity: null,
          unit: 1,
          goal_name: '',
          activity_days: [],
          activity_time: '',
          specific_time: {
            from: '',
            to: '',
          },
          activity_notes: [],
          activity_references: [],
          activity_dont: [],
          is_active: null,
          activity_time_name: '',
          category_name: '',
          unit_name: '',
          isNew: true,
        },
      },
    });
    setActivityLocalStorage();
  },

  clearActivitystate: () => {
    const { activityState, setActivityLocalStorage } = get();
    set({
      activityState: {
        ...activityState,
        activityData: {
          activityCount: '',
          activityData: [],
        },
        selectedActivityData: [],
        activity_image_obj: {},
        createNewActivityData: {
          id: '',
          activity_name: '',
          activity_image: '',
          category_id: '',
          goal_id: '',
          goal_name: '',
          is_measurable: true,
          quantity: null,
          unit: 1,
          activity_days: [],
          activity_time: '',
          specific_time: {
            from: '',
            to: '',
          },
          activity_notes: [],
          activity_references: [],
          activity_dont: [],
          is_active: null,
          activity_time_name: '',
          category_name: '',
          unit_name: '',
          isNew: true,
        },
        filters: {
          search: '',
          goal_id: [],
        },
        addActivitySelectedData: [],
        error: {
          activity_name: '',
          activity_image: '',
          quantity: '',
          unit: '',
          goal_id: '',
        },
      },
    });
    setActivityLocalStorage();
  },
  getActivities: async (categoryId) => {
    try {
      // debugger;
      const { activityState, setActivityLocalStorage } = get();
      const { activityData } = activityState;
      const { planAddEditToolState } = usePlanAddEditTool.getState();
      set({ loading: true });
      const payload = {
        search: activityState?.filters?.search ?? '',
        goal_id: activityState?.filters?.goal_id ?? [],
        category_id: categoryId ?? ['1'],
      };

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['get-activity-by-category-id'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/activityTemplate/get-activity-by-category-id`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        const category =
          categoryId[0] === '1'
            ? 'mind'
            : categoryId[0] === '2'
            ? 'body'
            : 'food';
        const resArr = [];

        for (const object of planAddEditToolState?.overAllConstructedPlanData?.[
          category
        ]?.planDayData) {
          for (const val of object?.plan) {
            resArr?.push(val?.id);
          }
        }
        function onlyUnique(value, index, array) {
          return array.indexOf(value) === index;
        }

        const unique = resArr.filter(onlyUnique);

        let arrData = [];
        let mapData = data?.activityData;
        if (unique?.length > 0) {
          for (const string of unique) {
            for (const object of mapData) {
              if (string !== object?.id) {
                arrData.push(object);
              }
            }
            mapData = arrData;
            arrData = [];
          }
        } else {
          arrData = data?.activityData;
        }
        arrData = mapData;

        set({
          loading: false,
          activityState: {
            ...activityState,
            activityData: {
              ...activityData,
              activityCount: data?.activityCount ?? 0,
              activityData: arrData ?? [],
            },
          },
        });
      }
      setActivityLocalStorage();
      return data;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  createActivity: async () => {
    try {
      const { activityState, onStateUpdate, setActivityLocalStorage } = get();

      set({ loading: true });
      const payload = {
        ...(activityState?.createNewActivityData ?? {}),
      };

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['activityTemplateRequest-upsert-activity-request'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/activityTemplateRequest/upsert-activity-request`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        set({ loading: false });
        onStateUpdate('id', data?.activityRequestId);
      }
      setActivityLocalStorage();
      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  onFilterUpdate: (key, value) => {
    const { activityState, setActivityLocalStorage } = get();
    const { filters } = activityState;
    set({
      activityState: {
        ...activityState,
        filters: {
          ...filters,
          [key]: value,
        },
      },
    });
    setActivityLocalStorage();
  },

  onStateUpdate: (key, value, subKey) => {
    const { activityState, setActivityLocalStorage } = get();
    const { createNewActivityData } = activityState;
    const { specific_time } = createNewActivityData;
    if (key === 'specific_time') {
      set({
        activityState: {
          ...activityState,
          createNewActivityData: {
            ...createNewActivityData,
            specific_time: {
              ...specific_time,
              [subKey]: value,
            },
          },
        },
      });
    } else if (
      key === 'activity_image_obj' ||
      key === 'error' ||
      key === 'createNewActivityData' ||
      key === 'selectedActivityData'
    ) {
      set({
        activityState: {
          ...activityState,
          [key]: value,
        },
      });
    } else {
      set({
        activityState: {
          ...activityState,
          createNewActivityData: {
            ...createNewActivityData,
            [key]: value,
          },
        },
      });
    }

    setActivityLocalStorage();
  },
  onCardSelect: (activityId) => {
    const { activityState, setActivityLocalStorage } = get();
    const { addActivitySelectedData } = activityState;

    if (addActivitySelectedData?.includes(activityId)) {
      let arr = [];
      for (const val of addActivitySelectedData) {
        if (val?.id !== activityId?.id) {
          arr.push(val);
        }
      }

      set({
        activityState: {
          ...activityState,
          addActivitySelectedData: arr,
        },
      });
    } else {
      const array = addActivitySelectedData;
      array.push(activityId);
      set({
        activityState: {
          ...activityState,
          addActivitySelectedData: array,
        },
      });
    }

    setActivityLocalStorage();
  },
  onEditCustomplan: () => {
    const { activityState } = get();
    const { selectedActivityData, createNewActivityData } = activityState;

    let selectedActivityDataCopy = JSON.parse(
      JSON.stringify(selectedActivityData),
    );
    let resArr = [];
    selectedActivityDataCopy?.forEach((data) => {
      if (data?.id !== createNewActivityData?.id) {
        resArr.push(data);
      }
    });

    resArr.push(createNewActivityData);
    set({
      activityState: {
        ...activityState,
        selectedActivityData: resArr,
      },
    });
  },
}));

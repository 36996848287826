export const expertChoosePlanCard_style = {
  cardRootSx: {
    border: '1px solid #EEEEEE',
    borderRadius: '8px',
    p: 2,
    mb: 1,
    bgcolor: '#fff',
    cursor: 'pointer',
  },
  cardTypoSx: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#0E1824',
  },
};

export const pwaNoticationPrompt_style = {
  pwaCardSx: {
    backgroundColor: 'sky.800',
    p: 2,
  },
  parentSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 3,
  },
  chilldSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 1,
  },
  buttonSx: {
    color: 'orangeTints.600',
    backgroundColor: 'common.white',
    p: 0.5,
    fontSize: '12px',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'common.white',
      color: '#EB6D13',
      boxShadow: 'none',
    },
  },
  closeSx: {
    ml: 2,
    width: '12px',
    height: '12px',
    color: 'common.white',
    cursor: 'pointer',
  },
  textSx: {
    fontSize: '14px',
    color: 'common.white',
  },
  checkMarkSx: {
    color: 'common.green',
    width: '16px',
    height: '16px',
  },
};

import { Box, Grid, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { expertPreviousPlanSkeletonCard_style } from './style';

function ExpertPreviousPlanSkeletonCard(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  return (
    <Box
      sx={{
        ...expertPreviousPlanSkeletonCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={expertPreviousPlanSkeletonCard_style.cardRootSx}>
        <Grid container>
          <Grid item xs={12}>
            <Skeleton
              variant="rounded"
              sx={{ borderRadius: 2, background: '#e0ddf3' }}
              width="100%"
              height={70}
            />
          </Grid>
          <Grid item xs={12} pb={4} pt={2}>
            <Box display="flex" justifyContent="space-around" height={60}>
              {Array(3)
                .fill(1)
                .map((val, i) => (
                  <Box key={i}>
                    <Box alignSelf="center">
                      <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: 2, background: '#e0ddf3' }}
                        width={100}
                        height={30}
                      />
                    </Box>
                    <Box display="flex" mt={1} justifyContent="center">
                      <Skeleton
                        width={40}
                        height={40}
                        animation="wave"
                        variant="circular"
                        sx={{ background: '#e0ddf3' }}
                      />
                    </Box>
                  </Box>
                ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

ExpertPreviousPlanSkeletonCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ExpertPreviousPlanSkeletonCard };

export const ExpertNotes_style = {
  appBarStyleSx: {
    backgroundColor: 'purple.A100',
    boxShadow: '0px 0px',
  },
  screenLayoutChildernSx: {
    pt: 3,
    pb: 8.5,
  },
  addNotesSx: {
    width: '100%',
  },
  viewOnlySx: {
    pt: 2,
    pb: '86px',
    boxSizing: 'border-box',
    position: 'fixed',
    bottom: 0,
    zIndex: '1',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },
  viewOnlyBoxSx: {
    height: '28px',
    borderBottom: '1px solid #E2E2E2',
    borderTop: '2px solid #FACC15',
    bgcolor: '#FCF8E8',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonSx: {
    textTransform: 'capitalize',
    background: ' #6552CC',
    borderRadius: '8px',

    color: ' #FFFFFF',
    '&:hover': {
      background: ' #6552CC',
    },
  },
  backbuttonSx: {
    textTransform: 'capitalize',

    background: '#F3F0FF',
    color: ' #6552CC',
    '&:hover': {
      background: '#F3F0FF',
    },
  },
  expertAppbarSx: {
    pl: 2.5,
    pr: 2.5,
    py: 2,
    boxSizing: 'border-box',
    backgroundColor: 'purple.A100',
    borderRadius: '0px 0px 24px 24px',
    boxShadow: '0px 0px 10px #00000029',
    position: 'fixed',
    top: '5%',
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },

  tabButtonSx: {
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: 500,
    width: '100px',
    height: '20px',
    position: 'relative',
    zIndex: 1,
    color: '#3D3D3D',
    opacity: 1,
    pb: 1.3,
    pt: 0,
    pr: 0.5,
    ml: -1,
    borderRadius: '8px',
    '&.Mui-selected': {
      color: '#fff',
    },
  },
};

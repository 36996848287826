import { Box, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { healthConditionChips_style } from './style';

function HealthConditionChips(props) {
  const { className = '', rootStyle = {}, userData = {}, ...rest } = props;

  const data = [
    {
      name: 'Allergy',
      data: userData?.medical_allergies ?? [],
    },
    {
      name: 'Problem',
      data: userData?.medical_health_problems ?? [],
    },
    {
      name: 'Medications',
      data: userData?.medical_active_medications ?? [],
    },
  ];

  return (
    <Box
      sx={{
        ...healthConditionChips_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={healthConditionChips_style.boxRootSx}>
        <Typography fontSize={14} fontWeight={600}>
          Medical conditions
        </Typography>
        <Box>
          {data?.map((value, i) => (
            <Box key={i}>
              <Typography color="#5C6266" mt={1} fontSize={12}>
                {value?.name ?? ''}
              </Typography>
              <Box display="flex" mt="6px">
                {value?.data?.map((object, i) => (
                  <Box key={i} sx={healthConditionChips_style.shipsSx}>
                    <Typography
                      alignSelf="center"
                      color="#757575"
                      fontWeight={500}
                      fontSize={10}
                    >
                      {object}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {i !== 2 && <Divider sx={{ mt: 1 }} />}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

HealthConditionChips.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  userData: PropTypes.object,
};

export { HealthConditionChips };

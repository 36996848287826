export const CustomPlanSplash_style = {
  rootSx: {
    display: 'grid',
    background: '#F3F0FF',
    minHeight: '100vh',
    placeItems: 'center',
    p: 2.5,
  },
  titleSx: {
    color: '#0E1824',
    fontWeight: '500',
    fontSize: '20px',
    pt: 1,
    textAlign: 'center',
  },
  subtitleSx: {
    fontWeight: '400',
    textAlign: 'center',
    pt: 2.25,
    px: 9,
    color: '#F0F0F0',
  },
  resetSx: {},
  titleFontSx: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 600,
    letterS: '0px',
    color: '#0E1824',
  },
  newPlanFontSx: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 600,
    letterS: '0px',
    color: '#0E1824',
  },
  buttonSx: {
    fontSize: '14px',
    fontWeight: 500,
    background: ' #6552CC',
    borderRadius: '8px',
    textTransform: 'none',
    width: '150px',
    p: 0.4,
    color: ' #FFFFFF',
    '&:hover': {
      background: ' #6552CC',
    },
  },
  backbuttonSx: {
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'none',
    width: '150px',
    background: '#F3F0FF',
    color: ' #6552CC',
    '&:hover': {
      background: '#F3F0FF',
    },
  },
  planCard: {
    background: ' #FFFFFF ',
    boxShadow: '0px 1px 10px #00000014',
    borderRadius: '8px',
    opacity: 1,
    padding: '30px 18px',
  },
  itemCenterSx: {
    display: 'grid',
    placeItems: 'center',
  },
  planVisibleFontSx: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0px',
    color: ' #5C6266',
    opacity: 1,
  },
  drawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    p: 2.5,
  },
  buttonActiveNumSx: {
    border: '2px solid #EE8438',
    width: 'max-content',
    minHeight: '40px',
    borderRadius: '10px',
    padding: '5px 10px',
    bgcolor: '#FFF6F0',
    color: '#EE8438',
    '&:hover': {
      backgroundColor: '#FFF6F0',
      color: '#EE8438',
    },
  },
  buttonInactiveNumSx: {
    border: '1px solid ',
    borderRadius: '10px',
    width: 'max-content',
    bgcolor: '#FFF6F0',
    minHeight: '40px',
    padding: '5px 10px',
    color: '#5C6266',
    '&:hover': {
      backgroundColor: '#FFF6F0',
      color: '#5C6266',
    },
  },
  cancelBtnSx: {
    textTransform: 'none',
    backgroundColor: '#F3F0FF',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: '#F3F0FF',
    },
  },
  applyBtnSx: {
    textTransform: 'none',
  },
};

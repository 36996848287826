export const expertPlanCalendar_style = {
  calendarParentRootSx: {
    width: 46,
    height: 78,
    borderRadius: '24px',
    bgcolor: '#F5F5F5',
    p: 0.5,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  calendarActiveParentRootSx: {
    width: 46,
    height: 78,
    borderRadius: '24px',
    bgcolor: '#EE8438',
    p: 0.5,
    display: 'flex',
    justifyContent: 'center',
  },
  calendarChildRootSx: {
    height: 38,
    width: 38,
    borderRadius: '50%',
    bgcolor: '#fff',
    display: 'flex',
    justifyContent: 'center',
  },
  rootSx: {
    display: 'flex',
    overflowY: 'scroll',
  },
};

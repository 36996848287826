export const qusAnsListing_style = {
  boxTitleSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pb: 2,
  },
  smallSx: {
    backgroundColor: 'text.primary',
    width: '24px',
    height: '24px',
    fontSize: '12px',
    ml: 1,
  },
  clearBtnSx: {
    width: '100%',
    maxWidth: '106px',
    backgroundColor: 'transparent',
    justifyContent: 'right',
    p: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  cardSx: {
    backgroundColor: 'orangeTints.50',
    border: '1.5px solid',
    borderColor: 'amber.200',
    px: 1.25,
    py: 0.5,
    marginBottom: 2,
    boxShadow: 'none',
    '& .MuiCardHeader-root': {
      p: 0,
    },
    '& .MuiTypography-body1 ': {
      fontSize: '14px',
      color: 'text.primary',
      opacity: 1,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '16px',
    },
  },
};

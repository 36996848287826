export const VitalsCard_style = {
  cardSx: {
    borderRadius: '8px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 10px #0000000A',
    '& .MuiCardContent-root': {
      padding: '0px',
      paddingBottom: '0px',
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '0px',
    },
  },
  titleSx: {
    color: 'text.primary',
    fontSize: '14px',
    fontWeight: 600,
    mb: 1,
  },
  addBtnSx: {
    color: 'secondary.main',
    backgroundColor: 'transparent',
    p: 0,
    fontSize: '13px',
    fontWeight: '600',
    textTransform: 'capitalize',
  },
  vitalSx: {
    color: 'text.secondary',
    fontSize: '12px',
    fontWeight: 'medium',
    width: 52,
  },

  vitalValueSx: {
    color: 'text.primary',
    fontSize: '12px',
    fontWeight: 600,
  },

  unitSx: {
    color: 'text.hint',
    fontSize: '12px',
    marginLeft: '4px',
  },

  vitalWrapperSx: {
    display: 'flex',
    gap: 1.5,
  },
  ConditionsTextSx: {
    fontSize: '16px',
    fontWeight: 500,
  },
  headingSx: {
    p: 0.5,
    mr: 1,
    fontWeight: 500,
    fontSize: '14px',
    mb: 1,
  },
  labelSx: {
    backgroundColor: 'grey.100',
    p: 1,
    borderRadius: '6px',
    fontWeight: 600,
    fontSize: '12px',
    color: '#757575',
    textTransform: 'capitalize',
  },
  noDataFound: {
    fontSize: '10px',
    color: 'PrimaryTints.A100',
    pl: 0.5,
  },
  content: {
    fontSize: '10px',
    color: 'PrimaryTints.A100',
  },
  lableContainerSx: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1.5,
    mb: 1,
  },
};

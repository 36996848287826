import { Button } from '@hc/ui/atoms';
import { Screenlayout } from '@hc/ui/components';
import { Box } from '@mui/material';
import { expertRoutes } from '@hc/routes';
import { useCreateAndAddActivity } from '@hc/store';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActivityNotes_style } from './style';

export default function ActivityNotes(props) {
  const { className = '', ...rest } = props;

  const { activityState, onStateUpdate } = useCreateAndAddActivity(
    (state) => ({
      activityState: state.activityState,
      onStateUpdate: state.onStateUpdate,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const location = useLocation();
  const navigate = useNavigate();

  const { createNewActivityData } = activityState;

  const [notesValue, setNotesValue] = useState('');
  function handleChanges(content, delta, source, editor) {
    setNotesValue(editor.getContents());
  }

  const onSave = () => {
    if (location?.state?.notes === 'isNotes') {
      onStateUpdate('activity_notes', notesValue);
      navigate(expertRoutes.addActivity);
    } else if (location?.state?.notes === 'isDonts') {
      onStateUpdate('activity_dont', notesValue);
    }
    navigate(expertRoutes.addActivity, {
      state: {
        category_id: location?.state?.category_id,
        isCustomEdit: location?.state?.isCustomEdit,
        isEdit: location?.state?.isEdit,
      },
    });
  };

  useEffect(() => {
    if (location?.state?.notes === 'isNotes') {
      if (createNewActivityData?.activity_notes?.ops?.length > 0) {
        setNotesValue(createNewActivityData?.activity_notes);
      }
    } else if (location?.state?.notes === 'isDonts') {
      if (createNewActivityData?.activity_dont?.ops?.length > 0) {
        setNotesValue(createNewActivityData?.activity_dont);
      }
    }
  }, []);

  return (
    <Screenlayout
      isExpertAppBar
      appBarStyle={ActivityNotes_style.appBarStyleSx}
      backRequired
      backRoute={expertRoutes.addActivity}
      stateValue={{
        category_id: location?.state?.category_id,
        isCustomEdit: location?.state?.isCustomEdit,
        isEdit: location?.state?.isEdit,
      }}
      backIconStyle={{ color: '#6552CC' }}
      rootStyle={{ backgroundColor: '#FCFBFF' }}
      title="Notes"
      masterchildStyle={ActivityNotes_style.screenLayoutChildernSx}
      notshowFooter={false}
      footerStyle={{ borderTop: '0px', p: 1 }}
      footer={
        <Box
          sx={{
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 2,
          }}
        >
          <Box ml={2}>
            <Button
              onClick={() => navigate(-1)}
              buttonStyle={ActivityNotes_style.backbuttonSx}
            >
              Back
            </Button>
          </Box>
          <Box mr={2}>
            <Button onClick={onSave} buttonStyle={ActivityNotes_style.buttonSx}>
              Save
            </Button>
          </Box>
        </Box>
      }
    >
      <Box sx={ActivityNotes_style.rootSx} className={`${className}`} {...rest}>
        <Box height="70vh" sx={ActivityNotes_style.boxRootSx}>
          <ReactQuill
            placeholder="Type notes here..."
            theme="snow"
            value={notesValue}
            onChange={handleChanges}
          />
        </Box>
      </Box>
    </Screenlayout>
  );
}

ActivityNotes.propTypes = {
  className: PropTypes.string,
};

import { Box, Grid, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { expertActivityCardSkeleton_style } from './style';

function ExpertActivityCardSkeleton(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  return (
    <Box
      sx={{
        ...expertActivityCardSkeleton_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={expertActivityCardSkeleton_style.cardRootSx}>
        <Grid container display="flex">
          <Grid item xs={3}>
            <Skeleton
              animation="wave"
              variant="circular"
              sx={{ borderRadius: 1, background: '#e0ddf3' }}
              width={48}
              height={48}
            />
          </Grid>
          <Grid
            item
            xs={5}
            alignSelf="center"
            display="flex"
            justifyContent="center"
          >
            <Skeleton
              variant="rounded"
              sx={{ borderRadius: 2, background: '#e0ddf3' }}
              width={150}
              height={10}
            />
          </Grid>
          <Grid
            item
            xs={4}
            alignSelf="center"
            display="flex"
            justifyContent="end"
          >
            <Skeleton
              width={25}
              height={25}
              animation="wave"
              variant="circular"
              sx={{ mr: 1, background: '#e0ddf3' }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

ExpertActivityCardSkeleton.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ExpertActivityCardSkeleton };

import {
  CheckMarkPwa,
  CloseIcon,
  Iphone,
  NotificationIcon,
} from '@atoms/icons';
import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { pwaNoticationPrompt_style } from './style';

function PWANotificationPrompt(props) {
  const {
    className = '',
    rootStyle = {},
    notificationEnabled,
    appInstalled,
    isblocked = false,
    onInstall = () => {},
    onEnable = () => {},
    onCloseClick = () => {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...pwaNoticationPrompt_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={pwaNoticationPrompt_style.pwaCardSx}>
        <Box sx={pwaNoticationPrompt_style.parentSx}>
          <Box sx={pwaNoticationPrompt_style.chilldSx}>
            {appInstalled ? (
              <>
                <CheckMarkPwa sx={pwaNoticationPrompt_style.checkMarkSx} />
                <Typography sx={pwaNoticationPrompt_style.textSx}>
                  App installed
                </Typography>
              </>
            ) : (
              <>
                <Iphone />
                <Typography sx={pwaNoticationPrompt_style.textSx}>
                  Get an app like experience
                  <br />
                  <span style={{ fontSize: '10px' }}>
                    Add to your home screen
                  </span>
                </Typography>
              </>
            )}
          </Box>
          <Box>
            {!appInstalled && (
              <Button
                sx={pwaNoticationPrompt_style.buttonSx}
                onClick={onInstall}
              >
                Add
              </Button>
            )}
            <CloseIcon
              id="close"
              sx={pwaNoticationPrompt_style.closeSx}
              onClick={onCloseClick}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={pwaNoticationPrompt_style.chilldSx}>
            {notificationEnabled && !isblocked && (
              <>
                <CheckMarkPwa sx={pwaNoticationPrompt_style.checkMarkSx} />
                <Typography sx={pwaNoticationPrompt_style.textSx}>
                  Notification Enabled
                </Typography>
              </>
            )}

            {!notificationEnabled && !isblocked && (
              <>
                <NotificationIcon sx={{ color: 'common.white', width: 18 }} />
                <Typography sx={pwaNoticationPrompt_style.textSx}>
                  Enable notifications for updates
                </Typography>
              </>
            )}

            {isblocked && !notificationEnabled && (
              <>
                <NotificationIcon sx={{ color: 'common.white', width: 18 }} />
                <Typography sx={pwaNoticationPrompt_style.textSx}>
                  Notifications blocked
                  <br />
                  <span style={{ fontSize: '10px' }}>
                    Unblock to receive latest alerts
                  </span>
                </Typography>
              </>
            )}
          </Box>
          <Box sx={{ mr: 3.2 }}>
            {!notificationEnabled && !isblocked && (
              <Button
                sx={pwaNoticationPrompt_style.buttonSx}
                onClick={onEnable}
              >
                Enable
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

PWANotificationPrompt.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  onInstall: PropTypes.func,
  onEnable: PropTypes.func,
  onCloseClick: PropTypes.func,
  notificationEnabled: PropTypes.bool,
  isblocked: PropTypes.bool,
  appInstalled: PropTypes.bool,
};

export { PWANotificationPrompt };

import { ExpertSplash } from '@hc/ui/atoms/illustration/illustrationSetOne';
import { SplashPageFooter } from '@hc/ui/components/expert/splashPageFooter';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { expert_landing_page_style } from './style';

export default function ExpertLandingPage(props) {
  const { className = '', ...rest } = props;

  return (
    <Box
      sx={expert_landing_page_style.rootSx}
      className={`${className}`}
      {...rest}
    >
      <Box display="flex" justifyContent="center">
        <ExpertSplash />
      </Box>
      <Box p={2}>
        <SplashPageFooter />
      </Box>
    </Box>
  );
}

ExpertLandingPage.propTypes = {
  className: PropTypes.string,
};

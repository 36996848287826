import { create } from 'zustand';

export const usePWANotificationEnabler = create((set, get) => ({
  isPwa: false,
  loading: true,
  notificationStatus: false,
  deferredPrompt: null,
  showPWANotificationPrompt: false,
  showedFirstTime: false,
  setDeferredPrompt: (prompt) => {
    set({
      deferredPrompt: prompt,
    });
  },
  setShowPWANotificationPrompt: (boolean) => {
    const { showedFirstTime } = get();
    if (!showedFirstTime && boolean) {
      set({
        showPWANotificationPrompt: true,
        showedFirstTime: true,
      });
    } else {
      set({
        showPWANotificationPrompt: false,
      });
    }
  },
  pwaNotificationChecker: () => {
    const isPWA = window.matchMedia('(display-mode: standalone)').matches;
    const { updatePwaNotificationStatus } = get();
    if ('Notification' in window) {
      updatePwaNotificationStatus(isPWA, Notification.permission);
    }
  },
  updatePwaNotificationStatus: (pwa, notification) => {
    set({
      isPwa: pwa,
      notificationStatus: notification,
      loading: false,
    });
  },
}));

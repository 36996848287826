export const ProgressWithLabel_style = {
  circularSx: {
    position: 'absolute',
    top: '0px',
    borderRadius: '12px',
  },
  circularbarSx: {
    position: 'relative',
    color: '#6955D1',
    display: 'inline-flex',
  },

  valueSx: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    fontWeight: 600,
    fontSize: '14px',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50px',
    justifyContent: 'center',
  },
  mbfPercentageLabelSx: {
    fontWeight: 600,
  },
};

import { ExpertForgotPasswordTopImage } from '@hc/ui/atoms';
import { ExpertForgotPasswordLink } from '@hc/ui/components';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { forgot_password_style } from './style';

export default function ExpertForgotPassword(props) {
  const { className = '', ...rest } = props;

  return (
    <Box sx={forgot_password_style.rootSx} className={`${className}`} {...rest}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={forgot_password_style.imgContSx}>
            <ExpertForgotPasswordTopImage
              rootStyle={forgot_password_style.imgSx}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box>
            <ExpertForgotPasswordLink />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

ExpertForgotPassword.propTypes = {
  className: PropTypes.string,
};

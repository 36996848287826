import { getDateFormatToString } from '@hc/dayjs';
import { BetweenDateCount } from '@hc/utils';
import { Badge, Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  FilterIcon2,
  HistoryIllustration,
  Input,
  NewRequestIcon,
  SearchIcon,
  UnderDraftIllustratation,
} from '../../../atoms';
import { SkeletonCard } from '../../clinical/skeletonCard';
import { HomeEmptyState } from '../emptyStates/homeEmptyState';
import { ExpertRequestCard } from '../expertRequestCard';
import { expertRequestCardList_style } from './style';

function ExpertRequestCardList(props) {
  const {
    className = '',
    isDraftCard = false,
    isHistoryCard = false,
    onClick = () => {},
    onSearch = () => {},
    onsearchValue = '',
    expertUserProfileId = '',
    showFilterDrop = true,
    data = [],
    loading,
    onOpenDrawer = () => {},
    id,
  } = props;

  return (
    <Box>
      <Box sx={expertRequestCardList_style.inputGroubSx}>
        <Grid container pb={2.2} display="flex" mt={1}>
          <Grid item xs={isHistoryCard ? 12 : 10}>
            <Input
              id={id}
              placeholder="Search"
              textFieldStyle={expertRequestCardList_style.searchFiledSx}
              onChange={(event) => onSearch(event.target.value)}
              value={onsearchValue}
              startAdornment={!onsearchValue && <SearchIcon />}
            />
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="center">
            {!isHistoryCard && (
              <Box
                sx={expertRequestCardList_style.filterSx}
                onClick={onOpenDrawer}
              >
                <Box sx={expertRequestCardList_style.centerIcon}>
                  {!showFilterDrop ? (
                    <FilterIcon2 rootStyle={{ width: '18px' }} />
                  ) : (
                    <Badge color="secondary" badgeContent=" " variant="dot">
                      <FilterIcon2 rootStyle={{ width: '18px' }} />
                    </Badge>
                  )}
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box mt={loading ? 5 : 0}>
        {loading &&
          Array(5)
            .fill(1)
            .map((val, i) => (
              <SkeletonCard
                color="#e0ddf3"
                key={i}
                isCardDetailsRequired={false}
                isExpertDetailCard={!isHistoryCard}
              />
            ))}
      </Box>
      <Box pt={10}>
        <Box display={loading ? 'none' : ''}>
          {data.filter(
            (val) =>
              val?.change_request_assign_expert_id === expertUserProfileId &&
              val?.change_request_description?.length > 0,
          ).length > 0 && (
            <Box pb={1}>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#6856cd',
                  display: 'contents',
                  mr: 2,
                }}
              >
                Change Request
              </Typography>
            </Box>
          )}
          {data?.length > 0 &&
            data
              .filter(
                (val) =>
                  val?.change_request_assign_expert_id ===
                    expertUserProfileId &&
                  val?.change_request_description?.length > 0,
              )
              .map((val, i) => (
                <Box pb={1.5} key={i}>
                  <ExpertRequestCard
                    planStatus={val?.plan_type ?? ''}
                    requestDuration={`${
                      BetweenDateCount(val?.created_at ?? '', new Date()) > 0
                        ? BetweenDateCount(val?.created_at, new Date())
                        : 'Today'
                    } ${
                      BetweenDateCount(val?.created_at ?? '', new Date()) > 0
                        ? BetweenDateCount(
                            val?.created_at ?? '',
                            new Date(),
                          ) === 0
                          ? 'Today'
                          : BetweenDateCount(
                              val?.created_at ?? '',
                              new Date(),
                            ) > 1
                          ? 'days ago'
                          : 'day ago'
                        : ''
                    } `}
                    userName={val?.name ?? ''}
                    gender={val?.gender ?? ''}
                    age={val?.age ?? ''}
                    mind={val?.mind}
                    body={val?.body}
                    food={val?.food}
                    isDraftCard={isDraftCard}
                    lastSavedDuration={
                      `${BetweenDateCount(
                        val?.lastSavedDuration ?? '',
                        new Date(),
                      )} ${
                        BetweenDateCount(
                          val?.lastSavedDuration ?? '',
                          new Date(),
                        ) > 1
                          ? 'days'
                          : 'day'
                      } ago` ?? ''
                    }
                    isHistoryCard={isHistoryCard}
                    historyStatus={
                      val?.status === 4
                        ? 'Inprogress'
                        : val?.status === 3
                        ? 'Yet to start'
                        : val?.status === 5
                        ? 'Completed'
                        : ''
                    }
                    changeRequestDescription={
                      val?.change_request_assign_expert_id ===
                        expertUserProfileId &&
                      val?.change_request_description?.length > 0
                        ? val?.change_request_description
                        : ''
                    }
                    HistoryDate={getDateFormatToString(val?.created_at, 'lll')}
                    avatar={val?.profile_pic ?? ''}
                    onClick={() =>
                      onClick(isHistoryCard ? val?.plan_id : val?.id, val)
                    }
                  />
                </Box>
              ))}
        </Box>
        <Box display={loading ? 'none' : ''}>
          {data.filter(
            (val) =>
              val?.change_request_assign_expert_id === expertUserProfileId &&
              val?.change_request_description?.length > 0,
          ).length > 0 && (
            <Box pb={1}>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#6856cd',
                  display: 'contents',
                  mr: 2,
                }}
              >
                History
              </Typography>
            </Box>
          )}
          {data?.length > 0 &&
            data
              .filter(
                (val) =>
                  !val?.change_request_assign_expert_id &&
                  !val?.change_request_description?.length,
              )
              .map((val, i) => (
                <Box pb={1.5} key={i}>
                  <ExpertRequestCard
                    planStatus={val?.plan_type ?? ''}
                    requestDuration={`${
                      BetweenDateCount(val?.created_at ?? '', new Date()) > 0
                        ? BetweenDateCount(val?.created_at, new Date())
                        : 'Today'
                    } ${
                      BetweenDateCount(val?.created_at ?? '', new Date()) > 0
                        ? BetweenDateCount(
                            val?.created_at ?? '',
                            new Date(),
                          ) === 0
                          ? 'Today'
                          : BetweenDateCount(
                              val?.created_at ?? '',
                              new Date(),
                            ) > 1
                          ? 'days ago'
                          : 'day ago'
                        : ''
                    } `}
                    userName={val?.name ?? ''}
                    gender={val?.gender ?? ''}
                    age={val?.age ?? ''}
                    mind={val?.mind}
                    body={val?.body}
                    food={val?.food}
                    isDraftCard={isDraftCard}
                    lastSavedDuration={
                      `${BetweenDateCount(
                        val?.lastSavedDuration ?? '',
                        new Date(),
                      )} ${
                        BetweenDateCount(
                          val?.lastSavedDuration ?? '',
                          new Date(),
                        ) > 1
                          ? 'days'
                          : 'day'
                      } ago` ?? ''
                    }
                    isHistoryCard={isHistoryCard}
                    historyStatus={
                      val?.status === 4
                        ? 'Inprogress'
                        : val?.status === 3
                        ? 'Yet to start'
                        : val?.status === 5
                        ? 'Completed'
                        : ''
                    }
                    changeRequestDescription={
                      val?.change_request_assign_expert_id ===
                        expertUserProfileId &&
                      val?.change_request_description?.length > 0
                        ? val?.change_request_description
                        : ''
                    }
                    HistoryDate={getDateFormatToString(val?.created_at, 'lll')}
                    avatar={val?.profile_pic ?? ''}
                    onClick={() =>
                      onClick(isHistoryCard ? val?.plan_id : val?.id, val)
                    }
                  />
                </Box>
              ))}
        </Box>
      </Box>
      {!isDraftCard && !loading && !isHistoryCard && data?.length === 0 && (
        <HomeEmptyState
          illustartion={<NewRequestIcon />}
          title="No new requests"
          description={<span>You will receive it once request is raised</span>}
        />
      )}
      {isDraftCard && !loading && data?.length === 0 && (
        <HomeEmptyState
          illustartion={<UnderDraftIllustratation />}
          title="No plans in draft"
          description={
            <span>
              Awesome! You are all caught up. Drafts <br /> will appear when you
              have partially <br /> completed plans.
            </span>
          }
        />
      )}
      {isHistoryCard && !loading && data?.length === 0 && (
        <HomeEmptyState
          illustartion={<HistoryIllustration />}
          title="No history"
          description={
            <span>
              History will appear once you publish <br /> plans
            </span>
          }
        />
      )}
    </Box>
  );
}

ExpertRequestCardList.propTypes = {
  className: PropTypes.string,
  isDraftCard: PropTypes.bool,
  isHistoryCard: PropTypes.bool,
  showFilterDrop: PropTypes.bool,
  onClick: PropTypes.func,
  onSearch: PropTypes.func,
  onsearchValue: PropTypes.string,
  expertUserProfileId: PropTypes.string,
  data: PropTypes.array,
  onOpenDrawer: PropTypes.func,
  loading: PropTypes.bool,
};

export { ExpertRequestCardList };

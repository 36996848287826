/* eslint-disable react/jsx-key */
import { getDateFormat } from '@hc/dayjs';
import { Box, Typography } from '@mui/material';
import { isAfter, isBefore, isEqual } from 'date-fns';
import moment from 'moment';
import React, { useState } from 'react';
import { taskWeeklyCalender_Style } from './style';

export function TaskWeeklyCalender(props) {
  const {
    // wellnessPlan = [],
    selectDate,
    wellnessPlanData,
    selectedDate,
    calenderData,
    planstatus,
    planStartDate,
    planEndDate,
    className = '',
    ...rest
  } = props;

  const [active] = useState();

  return (
    <Box
      className={`${className}`}
      {...rest}
      sx={taskWeeklyCalender_Style.rootSx}
    >
      {Array.isArray(calenderData?.betweenDates) &&
      calenderData?.betweenDates?.length > 0
        ? calenderData?.betweenDates?.map((val,i) => (
            <Box
            key={i}
              sx={{
                display: 'grid',
                placeItems: 'center',
                cursor:
                  isEqual(new Date(planStartDate), new Date(val)) ||
                  isBefore(new Date(planEndDate), new Date(val))
                    ? 'not-allowed'
                    : 'cursor-pointer',
                gap: 0.5,
                ...(getDateFormat(val, 'YYYY-MM-DD') === selectedDate
                  ? taskWeeklyCalender_Style.activeSx
                  : isAfter(
                      new Date(planStartDate),
                      new Date(moment(val).format('YYYY-MM-DD'))
                    )
                  ? taskWeeklyCalender_Style.pastButton
                  : isBefore(
                      new Date(planEndDate),
                      new Date(moment(val).format('YYYY-MM-DD'))
                    )
                  ? taskWeeklyCalender_Style.pastButton
                  : taskWeeklyCalender_Style.inactiveSx),
              }}
              onClick={() =>
                !isEqual(new Date(planStartDate), new Date(val)) &&
                !isBefore(new Date(planEndDate), new Date(val)) &&
                selectDate(val)
              }
            >
              <Box
                sx={
                  val === active
                    ? taskWeeklyCalender_Style.circleSx
                    : taskWeeklyCalender_Style.circleInactiveSx
                }
              >
                <Typography
                  sx={
                    val === active
                      ? taskWeeklyCalender_Style.dateSx
                      : taskWeeklyCalender_Style.dateInactiveSx
                  }
                >
                  {getDateFormat(val, 'DD')}
                </Typography>
                <Typography
                  sx={
                    val === active
                      ? taskWeeklyCalender_Style.weekSx
                      : taskWeeklyCalender_Style.weekInactiveSx
                  }
                >
                  {getDateFormat(val, 'ddd')}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    display: 'grid',
                    placeItems: 'center',
                    ...(getDateFormat(val, 'YYYY-MM-DD') === selectedDate
                      ? taskWeeklyCalender_Style.taskCountInactiveSx
                      : taskWeeklyCalender_Style.taskCountSx),
                  }}
                >
                  {Array.isArray(planstatus) && planstatus?.length > 0
                    ? planstatus.filter((v) => v?.date === val)?.length
                    : 0}
                </Typography>
                <Typography
                  sx={
                    getDateFormat(val, 'YYYY-MM-DD') === selectedDate
                      ? taskWeeklyCalender_Style.taskInactiveSx
                      : taskWeeklyCalender_Style.taskSx
                  }
                >
                  Tasks
                </Typography>
              </Box>
            </Box>
          ))
        : null}
    </Box>
  );
}

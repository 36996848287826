/* eslint-disable func-names */
/* eslint-disable prefer-const */
/* eslint-disable prefer-arrow-callback */
import { envConfig } from '@hc/config';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient,
} from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useReferrals = create((set, get) => ({
  referralsData: [],
  refferralsInitialData: [],
  referralsNotes: {},
  appointmentCurrentDate: new Date(),
  loading: true,
  error: null,
  getReferralsData: async (key) => {
    try {
      const { appointmentCurrentDate } = get();
      const utcDate = appointmentCurrentDate.toISOString();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const keydata = parseJwt(authToken);

      set({ loading: true });
      const payload = {
        date: utcDate ?? '',
        doctor_id: keydata?.user_id,
        search: '',
        sort_by: '',
      };
      if (key) {
        await queryClient.invalidateQueries({
          queryKey: ['doctorReferral', utcDate],
        });
      }

      const { data } = await queryClient.fetchQuery({
        queryKey: ['doctorReferral', utcDate],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/doctor/referrals/get`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      return set(() => ({
        loading: false,
        referralsData: data?.referrals,
        refferralsInitialData: data?.referrals,
      }));
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  getReferralsNotes: async (value) => {
    try {
      const { appointmentCurrentDate } = get();
      const utcDate = appointmentCurrentDate.toISOString();

      set({ loading: true });
      const payload = {
        referral_id: value,
      };

      const { data } = await queryClient.fetchQuery({
        queryKey: ['doctorReferralNotes', utcDate, payload],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/doctor/view-referral`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      return set(() => ({
        loading: false,
        referralsNotes: data,
      }));
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  setSeachReferralsFilter: (value) => {
    const { refferralsInitialData } = get();
    let referralsDataCopy = JSON.parse(JSON.stringify(refferralsInitialData));

    if (value) {
      let Arr = referralsDataCopy?.filter(function (val) {
        return val?.name?.includes(value);
      });
      set({
        referralsData: Arr,
      });
    } else {
      set({
        referralsData: refferralsInitialData,
      });
    }
  },
}));

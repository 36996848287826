/* eslint-disable prefer-const */
import { expertRoutes } from '@hc/routes';
import { useCreateAndAddActivity } from '@hc/store';
import { useActivityTemplate } from '@hc/store/backoffice/activityTemplate';
import { Button } from '@hc/ui/atoms';
import { AddReferences, Screenlayout } from '@hc/ui/components';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActivityReference_style } from './style';

export default function ActivityReference(props) {
  const { className = '', ...rest } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const { Link, clearReference, handleLinkUpdate } = useActivityTemplate(
    (state) => ({
      Link: state.Link,
      handleLinkUpdate: state.handleLinkUpdate,
      clearReference: state.clearReference,
    })
  );

  const { onStateUpdate, activityState } = useCreateAndAddActivity((state) => ({
    activityState: state.activityState,
    onStateUpdate: state.onStateUpdate,
  }));

  const onCreate = () => {
    onStateUpdate('activity_references', Link);
    clearReference();
    navigate(expertRoutes.addActivity, {
      state: {
        category_id: location?.state?.category_id,
        isCustomEdit: location?.state?.isCustomEdit,
        isEdit: location?.state?.isEdit,
      },
    });
  };

  useEffect(() => {
    if (activityState?.createNewActivityData?.activity_references.length > 0) {
      handleLinkUpdate(
        'Link',
        activityState?.createNewActivityData?.activity_references
      );
    }
  }, []);

  return (
    <Screenlayout
      isExpertAppBar
      appBarStyle={ActivityReference_style.appBarStyleSx}
      backRequired
      backIconStyle={{ color: '#6552CC' }}
      rootStyle={{ backgroundColor: '#FCFBFF' }}
      title="References"
      masterchildStyle={ActivityReference_style.screenLayoutChildernSx}
      notshowFooter={false}
      footerStyle={{ borderTop: '0px', p: 1 }}
      footer={
        <Box
          sx={{
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 2,
          }}
        >
          <Box ml={2}>
            <Button
              onClick={() => navigate(expertRoutes?.addActivity)}
              buttonStyle={ActivityReference_style.backbuttonSx}
            >
              Back
            </Button>
          </Box>
          <Box mr={2}>
            <Button
              onClick={onCreate}
              buttonStyle={ActivityReference_style.buttonSx}
            >
              Create
            </Button>
          </Box>
        </Box>
      }
    >
      <Box
        sx={ActivityReference_style.rootSx}
        className={`${className}`}
        {...rest}
      >
        <AddReferences
          isExpert
          boxRootStyle={{
            width: '100%',
            height: '90vh',
            p: 1,
            overflowX: 'scroll',
          }}
        />
      </Box>
    </Screenlayout>
  );
}

ActivityReference.propTypes = {
  className: PropTypes.string,
};

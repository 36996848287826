import { expertRoutes } from '@hc/routes';
import { useExpertOnboarding } from '@hc/store';
import { Button } from '@hc/ui/atoms';
import { ExpertProfileEdit, Screenlayout } from '@hc/ui/components';
import { queryClient } from '@hc/utils';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { edit_profile_style } from './style';

export default function ExpertEditProfile(props) {
  const { className = '', ...rest } = props;
  const navigate = useNavigate();

  const {
    expertOnboardingState,
    userProfileUpsert,
    updateOnboarding,
    loading,
  } = useExpertOnboarding((state) => ({
    updateOnboarding: state.updateOnboarding,
    expertOnboardingState: state.expertOnboardingState,
    userProfileUpsert: state.userProfileUpsert,
    loading: state.loading,
  }));

  const isValidToSave = () => {
    const expertUserCopy = JSON.parse(JSON.stringify(expertOnboardingState));
    const errorCopy = expertOnboardingState?.error;
    let isValid = true;

    // checking about

    if (!expertUserCopy?.about?.length > 0) {
      isValid = false;
      errorCopy.about = 'Please enter the about';
    } else {
      errorCopy.about = '';
    }

    // Checking languages
    if (expertUserCopy?.languages?.length === 0) {
      isValid = false;
      errorCopy.languages = 'please select the languages';
    } else {
      errorCopy.languages = '';
    }

    // checking name
    if (expertUserCopy?.name.length <= 1) {
      isValid = false;
      errorCopy.name = 'please enter your name';
    } else {
      errorCopy.name = '';
    }

    // Checking Mobile Number
    if (expertUserCopy?.mobile.length === 0) {
      isValid = false;
      errorCopy.mobile = 'Enter a valid 10 digit mobile number';
    } else if (expertUserCopy?.mobile.length < 10) {
      isValid = false;
      errorCopy.mobile = 'Enter a valid 10 digit mobile number';
    } else {
      errorCopy.mobile = '';
    }

    // checking Qualification

    if (expertUserCopy?.qualification?.length <= 1) {
      isValid = false;
      errorCopy.qualification = 'Please enter the Qualification';
    } else {
      errorCopy.qualification = '';
    }

    // checking address

    if (expertUserCopy?.address?.length <= 1) {
      isValid = false;
      errorCopy.address = 'Please enter the address';
    } else {
      errorCopy.address = '';
    }

    updateOnboarding('error', errorCopy);
    return isValid;
  };

  const onUpdate = async () => {
    const result = isValidToSave();
    if (result) {
      queryClient.invalidateQueries({
        queryKey: ['expert-profile-update'],
      });
      const response = await userProfileUpsert();
      if (response?.code === 200) {
        navigate(expertRoutes?.profile);
      }
    }
  };

  const onCancel = () => {
    navigate(expertRoutes?.profile);
  };

  return (
    <Screenlayout
      title="Edit profile"
      isExpertAppBar
      backRequired
      backRoute={-1}
      backIconStyle={{ color: '#7A67D9' }}
      footer={
        <Grid container columnSpacing={2}>
          <Grid item xs={6} sm={6} md={6}>
            <Button onClick={onCancel} sx={edit_profile_style.buttonSx}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Button
              id="Save"
              buttonStyle={{ textTransform: 'capitalize' }}
              onClick={onUpdate}
              loading={loading}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Box px={2.5} className={`${className}`} {...rest}>
        <ExpertProfileEdit />
      </Box>
    </Screenlayout>
  );
}

ExpertEditProfile.propTypes = {
  className: PropTypes.string,
};

export const splashPageFooter_style = {
  rootSx: {
    bgcolor: '#5849ac',
    p: 1,
    borderRadius: 2,
  },
  buttonSx: {
    // bgcolor: '#fff',
    // position:'relative',
    // background: 'rgb(204, 204, 204)', /* Fallback for older browsers without RGBA-support */
    bgcolor: '#8376c1',
    height: 44,
    borderRadius: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  buttonTextSx: {
    opacity: 1,
    color: '#ffffff',
    alignSelf: 'center',
    fontSize: '14px',
  },
};

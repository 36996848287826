export const profile_style = {
  rootSx: {
    // height: '90vh',
    pb: 2,
  },
  profileBannerSx: {
    mt: 1.5,
    bgcolor: '#fff',
    borderRadius: '16px',
    boxShadow: '0px 0px 18px -9px #00000029',
    cursor: 'pointer',
  },
  profileVersionSx: {
    color: '#888888',
    fontSize: '10px',
    p: 0.5,
    mt: 'calc(100% - 8%)',
    ml: '50%',
    transform: 'translate(-25px,-25px)',
  },
  drawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    p: 2.5,
  },
  cancelBtnSx: {
    textTransform: 'none',
    backgroundColor: '#F3F0FF',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: '#F3F0FF',
    },
  },
  applyBtnSx: {
    textTransform: 'none',
    bgcolor: '#F44F5A',
    '&:hover': {
      bgcolor: '#F44F5A',
    },
  },
};

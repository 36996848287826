import { Box, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { expertProfileSkeleton_style } from './style';

function ExpertProfileSkeleton(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  return (
    <Box
      sx={{
        ...expertProfileSkeleton_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box pb={1.5}>
        <Skeleton
          variant="rounded"
          sx={{ borderRadius: 2, background: '#e0ddf3' }}
          width="100%"
          height={110}
        />
      </Box>
      <Box>
        <Skeleton
          variant="rounded"
          sx={{ borderRadius: 2, background: '#e0ddf3' }}
          width="100%"
          height={65}
        />
      </Box>
    </Box>
  );
}

ExpertProfileSkeleton.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ExpertProfileSkeleton };

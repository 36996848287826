export const previousPlansCard_style = {
  rootSx: {
    background: ' #FFFFFF ',
    border: '1px solid #E3E3E3',
    borderRadius: '8px',
    padding: '14px 8px 4px 18px ',
    cursor: 'pointer',
  },
  dateFontSx: {
    fontSize: '10px',
    textAlign: 'left',
    letterSpacing: '0px',
    fontWeight: 500,
    color: '#5C6266',
    opacity: 1,
    pl: 0.4,
  },
  monthFontSx: {
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#888888',
    fontSize: '12px',
    fontWeight: 400,
  },
  activityFontSx: {
    textAlign: 'left',
    color: '#888888',
    fontSize: '12px',
    fontWeight: 400,
  },
  planFontSx: {
    color: '#0E1824',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'capitalize',
    pb: 0.4,
  },
  completedTextSx: {
    fontSize: '10px',
    textAlign: 'right',
    letterSpacing: '0px',
    fontWeight: 500,
    color: '#25C460',
    display: 'grid',
    placeItems: 'center',
  },
  typeFont: {
    textAlign: 'left',
    fontSize: '12px',
    fontWeight: '400',
    letterSpacing: '0px',
    color: '#888888',
    mb: 0.6,
  },
};

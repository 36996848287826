import {
  Button,
  Drawer,
  ExpertAddFileIcon,
  Input,
  Label,
  ToggleButton,
} from '@hc/ui/atoms';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';

import { expertRoutes } from '@hc/routes';
import {
  useBasicInfoStates,
  useCreateAndAddActivity,
  useExpertRequest,
  useMasters,
  usePlanAddEditTool,
} from '@hc/store';
import PropTypes from 'prop-types';
import { localStorageKeys, queryClient } from '@hc/utils';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomPlanSplash_style } from './style';

export default function CustomPlanSplash(props) {
  const { className = '', message = '', ...rest } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const {
    planAddEditToolState,
    getLocalStorage,
    upsertplanTemplate,
    onDurationUpdation,
    clearPlanAddEditState,
    onUpdateToolData,
    loading,
  } = usePlanAddEditTool(
    (state) => ({
      onUpdateToolData: state.onUpdateToolData,
      clearPlanAddEditState: state.clearPlanAddEditState,
      onDurationUpdation: state.onDurationUpdation,
      upsertplanTemplate: state.upsertplanTemplate,
      planAddEditToolState: state.planAddEditToolState,
      getLocalStorage: state.getLocalStorage,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { clearActivitystate } = useCreateAndAddActivity(
    (state) => ({
      clearActivitystate: state.clearActivitystate,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  const { clearRequestState } = useExpertRequest(
    (state) => ({
      clearRequestState: state.clearRequestState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  const { clearBasicInfoState } = useBasicInfoStates(
    (state) => ({
      clearBasicInfoState: state.clearBasicInfoState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  const { planDurationData, error } = planAddEditToolState;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState({
    plan_name: planDurationData?.plan_name,
    duration_type: planDurationData?.duration_type,
    duration: planDurationData?.duration,
  });
  const onNoNeed = () => {
    localStorage.removeItem(localStorageKeys?.basicInfoState);
    localStorage.removeItem(localStorageKeys?.requestState);
    localStorage.removeItem(localStorageKeys?.planAddEditToolState);
    localStorage.removeItem(localStorageKeys?.activityState);
    clearPlanAddEditState();
    clearActivitystate();
    clearRequestState();
    clearBasicInfoState();
    navigate(expertRoutes.home);
  };
  const { getPlanDurationType, mastersState } = useMasters(
    (state) => ({
      getPlanDurationType: state.getPlanDurationType,
      mastersState: state.mastersState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { planDurationType } = mastersState;

  const getPlanDurationMasterData = async () => {
    await getPlanDurationType();
  };
  const isValidToSave = () => {
    const errorCopy = JSON.parse(JSON.stringify(error));
    let isValid = true;

    // checking plan name
    if (!drawerData?.plan_name.length > 0) {
      isValid = false;
      errorCopy.plan_name = 'Please enter the plan name';
    } else {
      errorCopy.plan_name = '';
    }

    // checking plan duratio
    if (!drawerData?.duration_type > 0) {
      isValid = false;
      errorCopy.duration_type = 'Please select the duration type ';
    } else {
      errorCopy.duration_type = '';
    }

    // checking plan duration name
    if (!drawerData?.duration > 0) {
      isValid = false;
      errorCopy.duration = 'Please enter the duration';
    } else {
      errorCopy.duration = '';
    }
    onUpdateToolData('error', errorCopy);
    return isValid;
  };

  const handleChange = (value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    // HANDLE PROFILE DATA CAHNGE
    setDrawerData({
      ...drawerData,
      duration: value,
    });
  };

  const onUpdate = () => {
    const result = isValidToSave();
    if (result) {
      onDurationUpdation('plan_name', drawerData?.plan_name);
      onDurationUpdation('duration_type', drawerData?.duration_type);
      onDurationUpdation('duration', drawerData?.duration);
      setOpenDrawer(false);
    }
  };

  const onAddTemplate = async () => {
    queryClient.invalidateQueries({
      queryKey: [`planTemplate-upsert-plan`],
    });
    const result = await upsertplanTemplate();
    if (result?.message === 'Plan Created Successfully') {
      localStorage.removeItem(localStorageKeys?.basicInfoState);
      localStorage.removeItem(localStorageKeys?.requestState);
      localStorage.removeItem(localStorageKeys?.planAddEditToolState);
      localStorage.removeItem(localStorageKeys?.activityState);
      clearPlanAddEditState();
      clearActivitystate();
      clearRequestState();
      clearBasicInfoState();
      navigate(expertRoutes?.home);
    } else if (result?.message === 'Plan Name Already Exist') {
      setOpenDrawer(true);
    }
  };
  useEffect(() => {
    getLocalStorage();
    getPlanDurationMasterData();
    const myIntervalll = setInterval(() => {
      if (!location?.state?.addtemplate) {
        navigate(expertRoutes?.master);
      }
    }, 2500);
    return () => {
      clearInterval(myIntervalll);
    };
  }, []);

  return (
    <Box
      sx={CustomPlanSplash_style.rootSx}
      className={`${className}`}
      {...rest}
    >
      <Box
        sx={{
          ...CustomPlanSplash_style.backgroundrootSx,
        }}
      >
        {/* Splash Design */}
        <Box sx={CustomPlanSplash_style.resetSx}>
          <Box
            display="flex"
            justifyContent="center"
            mb={!location?.state?.addtemplate ? 2 : 3}
          >
            <Typography display="flex" sx={CustomPlanSplash_style.titleFontSx}>
              {planDurationData?.plan_name}
              &nbsp; has been <br /> published
            </Typography>
          </Box>
          {location?.state?.addtemplate && (
            <Stack sx={CustomPlanSplash_style.planCard}>
              <Box mb={2} sx={CustomPlanSplash_style.itemCenterSx}>
                <ExpertAddFileIcon />
              </Box>
              <Typography sx={CustomPlanSplash_style.newPlanFontSx} mb={3}>
                Do you want to add this new plan as a <br /> template for future
                usage?
              </Typography>
              <Stack flexDirection="row" gap={2}>
                <Button
                  onClick={onNoNeed}
                  sx={CustomPlanSplash_style.backbuttonSx}
                >
                  No need
                </Button>
                <Button
                  onClick={onAddTemplate}
                  sx={CustomPlanSplash_style.buttonSx}
                  loading={loading}
                >
                  Add to template
                </Button>
              </Stack>
            </Stack>
          )}

          {!location?.state?.addtemplate && (
            <Typography sx={CustomPlanSplash_style.planVisibleFontSx}>
              {message}
            </Typography>
          )}
        </Box>
      </Box>
      <Drawer
        show={openDrawer}
        rootStyle={CustomPlanSplash_style.drawerSx}
        header="Plan from scratch"
        drawerRightClose
        childrenStyle={{ p: 0 }}
        onCloseDrawer={() => setOpenDrawer(false)}
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        closeStyle={{ color: 'grey', height: '14px', width: '12px' }}
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              onClick={() => setOpenDrawer(false)}
              buttonStyle={CustomPlanSplash_style.cancelBtnSx}
            >
              Cancel
            </Button>
            <Button
              onClick={() => onUpdate()}
              buttonStyle={CustomPlanSplash_style.applyBtnSx}
            >
              Update
            </Button>
          </Box>
        }
      >
        <Box p={2}>
          <Grid container>
            <Grid item xs={12} pb={2} sm={12} md={12}>
              <Label>Name of the plan</Label>
              <Box mt={0.5}>
                <Input
                  value={drawerData?.plan_name}
                  onChange={(e) =>
                    setDrawerData({ ...drawerData, plan_name: e.target?.value })
                  }
                  placeholder="Give a name to identify in future"
                  isError={error?.plan_name?.length > 0}
                  errorMessage={error?.plan_name}
                  id="planName"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Label>Plan Duration</Label>
              <Box display="flex">
                <Box width={161} mt={0.5}>
                  <Input
                    value={drawerData?.duration}
                    placeholder="Type duration"
                    type="number"
                    id="planDuration"
                    onChange={(e) => handleChange(e.target.value, 2)}
                    isError={error?.duration?.length > 0}
                    errorMessage={error?.duration}
                  />
                </Box>
                <Box px={0.5}>
                  <Divider
                    orientation="vertical"
                    sx={{ height: '32px', mt: 1 }}
                  />
                </Box>

                <Box>
                  <Grid container display="flex" columnSpacing={1}>
                    <Grid item>
                      <ToggleButton
                        options={planDurationType}
                        value={drawerData?.duration_type}
                        RootStyle={{ overflow: 'scroll' }}
                        buttonactiveStyle={
                          CustomPlanSplash_style.buttonActiveNumSx
                        }
                        buttoninactiveStyle={
                          CustomPlanSplash_style.buttonInactiveNumSx
                        }
                        onChange={(e) =>
                          setDrawerData({
                            ...drawerData,
                            duration_type: e,
                          })
                        }
                      />
                      {error?.duration_type.length > 0 && (
                        <Typography fontSize={12} color="	#ff3333">
                          {error?.duration_type}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </Box>
  );
}

CustomPlanSplash.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
};

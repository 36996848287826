/* eslint-disable no-restricted-syntax */
import { expertRoutes } from '@hc/routes';
import { useExpertOnboarding } from '@hc/store';
import { Button, CommonSteper } from '@hc/ui/atoms';
import {
  ProfileAdditionalInfo,
  ProfilePersonalInfo,
  Screenlayout,
} from '@hc/ui/components';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { add_profile_style } from './style';

export default function ExpertAddProfile(props) {
  const { className = '', ...rest } = props;

  const [stepValue, setStepValue] = useState(0);
  const navigate = useNavigate();

  const steps = ['Personal Info', 'Additional Info'];

  const icons = {
    1: 1,
    2: 2,
  };

  const {
    expertOnboardingState,
    updateOnboarding,
    userProfileUpsert,
    loading,
    getProfile,
  } = useExpertOnboarding((state) => ({
    expertOnboardingState: state.expertOnboardingState,
    userProfileUpsert: state.userProfileUpsert,
    loading: state.loading,
    updateOnboarding: state.updateOnboarding,
    getProfile: state.getProfile,
  }));

  const isValidToSaveAdditionalInfo = () => {
    const expertUserCopy = JSON.parse(JSON.stringify(expertOnboardingState));
    const errorCopy = expertOnboardingState?.error;
    let isValid = true;

    // checking about

    if (!expertUserCopy?.about?.length > 0) {
      isValid = false;
      errorCopy.about = 'Please enter the about';
    } else {
      errorCopy.about = '';
    }

    // Checking languages
    if (expertUserCopy?.languages?.length === 0) {
      isValid = false;
      errorCopy.languages = 'please select the languages';
    } else {
      errorCopy.languages = '';
    }

    updateOnboarding('error', errorCopy);
    return isValid;
  };

  const isValidToSavePersonalInfo = () => {
    const expertUserCopy = JSON.parse(JSON.stringify(expertOnboardingState));
    const errorCopy = expertOnboardingState?.error;
    let isValid = true;

    // checking name
    if (!expertUserCopy?.name) {
      isValid = false;
      errorCopy.name = 'please enter your name';
    } else {
      errorCopy.name = '';
    }

    // Checking Mobile Number
    if (expertUserCopy?.mobile.length === 0) {
      isValid = false;
      errorCopy.mobile = 'Enter a valid 10 digit mobile number';
    } else if (expertUserCopy?.mobile.length < 10) {
      isValid = false;
      errorCopy.mobile = 'Enter a valid 10 digit mobile number';
    } else {
      errorCopy.mobile = '';
    }

    // checking Qualification

    if (!expertUserCopy?.qualification?.length > 0) {
      isValid = false;
      errorCopy.qualification = 'Please enter the Qualification';
    } else {
      errorCopy.qualification = '';
    }

    // checking address

    if (!expertUserCopy?.address?.length > 0) {
      isValid = false;
      errorCopy.address = 'Please enter the address';
    } else {
      errorCopy.address = '';
    }

    updateOnboarding('error', errorCopy);
    return isValid;
  };

  const onNext = async () => {
    const firstResult = isValidToSavePersonalInfo();
    if (stepValue !== 1)
      if (firstResult) {
        setStepValue(stepValue + 1);
      }

    if (stepValue === 1) {
      const secondResult = isValidToSaveAdditionalInfo();
      if (secondResult) {
        const response = await userProfileUpsert();
        if (response?.code === 200) {
          navigate(expertRoutes.home);
        }
        setStepValue(stepValue + 1);
      }
    }
  };

  const onBack = () => {
    setStepValue(stepValue - 1);
  };

  const handleBasicProfileChange = (key, value) => {
    updateOnboarding(key, value);

    const errorCopy = expertOnboardingState?.error;
    if (key === 'name') {
      if (!value) {
        errorCopy.name = 'Please enter your name';
      } else {
        errorCopy.name = '';
      }
    } else if (key === 'mobile') {
      if (value.mobile.length === 0) {
        errorCopy.mobile = 'Enter a valid 10 digit mobile number';
      } else if (value?.mobile.length < 10) {
        errorCopy.mobile = 'Enter a valid 10 digit mobile number';
      } else {
        errorCopy.mobile = '';
      }
    } else if (key === 'qualification') {
      errorCopy.qualification = '';
      if (!value.length > 0) {
        errorCopy.qualification = 'Please enter the qualification';
      } else {
        errorCopy.qualification = '';
      }
    } else if (key === 'address') {
      errorCopy.address = '';
      if (!value.length > 0) {
        errorCopy.address = 'Please enter the address';
      } else {
        errorCopy.address = '';
      }
    }
  };

  const handleAdditioanlProfileChange = (key, value) => {
    updateOnboarding(key, value);
    let errorCopy = expertOnboardingState?.error;
    if (key === 'about') {
      errorCopy.languages = '';
      if (!value) {
        errorCopy.about = 'Please enter the about';
      } else {
        errorCopy.about = '';
      }
    }
    errorCopy = {
      ...expertOnboardingState?.error,
      errorCopy,
    };
  };

  useEffect(() => {
    getProfile();
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    const reArr = [
      { key: 'name', value: tokenData?.name ?? '' },
      { key: 'mobile', value: tokenData?.mobile_no ?? '' },
      { key: 'country_code', value: tokenData?.country_code ?? '' },
    ];
    for (const val of reArr) {
      updateOnboarding(val?.key, val?.value);
    }
  }, []);

  return (
    <Screenlayout
      stepperform
      footer={
        <Box>
          {stepValue === 0 ? (
            <Button
              id="Next"
              onClick={() => onNext()}
              buttonStyle={add_profile_style.buttonRootSx}
            >
              Next
            </Button>
          ) : (
            <Grid container columnSpacing={2}>
              <Grid item xs={6} sm={6} md={6}>
                <Button
                  id="Back"
                  onClick={() => onBack()}
                  sx={add_profile_style.buttonSx}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Button
                  id="NextA"
                  loading={loading}
                  onClick={() => onNext()}
                  buttonStyle={{ textTransform: 'capitalize' }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      }
      stepperChildren={
        <CommonSteper step={stepValue} icons={icons} stepText={steps} />
      }
      appBarStyle={add_profile_style.appBarSx}
    >
      {stepValue === 0 ? (
        <Box className={`${className}`} {...rest} px={2.5} mt={2.5}>
          <ProfilePersonalInfo handleChange={handleBasicProfileChange} />
        </Box>
      ) : (
        <Box className={`${className}`} {...rest} px={2.5} mt={2.5}>
          <ProfileAdditionalInfo handleChange={handleAdditioanlProfileChange} />
        </Box>
      )}
    </Screenlayout>
  );
}

ExpertAddProfile.propTypes = {
  className: PropTypes.string,
};

import { getDateFormatToString } from '@hc/dayjs';
import { FileSearchIllustration, Input, SearchIcon } from '@hc/ui/atoms';
import { PlanDetailCard } from '@hc/ui/components/expert/planDetailCard';
import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { expertRoutes } from '@hc/routes';
import { useExpertRequest, usePlanAddEditTool } from '@hc/store';
import { useDefaultPlan } from '@hc/store/expert/defaultPlan';
import { HomeEmptyState, SkeletonCard } from '@hc/ui/components';
import { queryClient } from '@hc/utils';
import { isEqual } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Default_style } from './style';

// const useIntersectionObserver = (ref, options) => {
//   const [isIntersecting, setIsIntersecting] = React.useState(false);
//   React.useEffect(() => {
//     const observer = new IntersectionObserver(([entry]) => {
//       setIsIntersecting(entry.isIntersecting);
//     }, options);

//     if (ref.current) {
//       observer.observe(ref.current);
//     }
//   }, []);
//   return isIntersecting;
// };

export default function DefaultPlan(props) {
  const { className, ...rest } = props;
  const [search, setSearch] = React.useState('');
  const [offset, setOffset] = React.useState(0);
  const observerTarget = useRef(null);

  const navigate = useNavigate();

  // ------------------------STORE--------------------------------
  const { useDefaultPlanState, getAllDefaultPlanListData, loading } =
    useDefaultPlan((state) => ({
      getAllDefaultPlanListData: state.getAllDefaultPlanListData,
      useDefaultPlanState: state.useDefaultPlanState,
      loading: state.loading,
    }));

  // const loading = true

  const { getPlanDetailsById, onUpdateToolData } = usePlanAddEditTool(
    (state) => ({
      getPlanDetailsById: state.getPlanDetailsById,
      onUpdateToolData: state.onUpdateToolData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  const { getTemplateDataById } = useExpertRequest(
    (state) => ({
      getTemplateDataById: state.getTemplateDataById,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { planMasterDefaultCount, planMasterDefaultData } = useDefaultPlanState;

  // -----------------------Handle SEACRH------------------------------------------

  const handleChange = async (e) => {
    setOffset(0);
    setSearch(e.target.value);
    await getAllDefaultPlanListData(e.target.value, 0, true);
  };

  // -------------------------CARD CLICK ---------------------------------
  const handleCardClick = async (id) => {
    onUpdateToolData('isHistory', false);
    onUpdateToolData('isDraft', false);
    onUpdateToolData('isScratch', false);
    onUpdateToolData('masterByMe', false);
    onUpdateToolData('masterByDefault', true);
    queryClient.invalidateQueries({
      queryKey: ['get-plan-data-by-plan-id'],
    });
    const result = await getPlanDetailsById(id ?? '');
    if (result?.status?.code === 200) {
      const response = await getTemplateDataById(id, result?.data);
      if (response?.code === 200) {
        navigate(expertRoutes.editplan, {
          state: {
            isPlanEdit: false,
            isPublishPlan: false,
          },
        });
      }
    }
  };

  // -------------------------FEtch ON SCROLL------------------------------
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (
            planMasterDefaultCount > 0 &&
            planMasterDefaultCount > planMasterDefaultData.length
          ) {
            setOffset(offset + 1);
            getAllDefaultPlanListData(search, offset + 1, false);
          }
        }
      },
      { threshold: 1 },
    );
    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }
    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, planMasterDefaultData]);

  // -------------------------FETCH INITIAL STATE--------------------------------

  const initialState = async () => {
    await getAllDefaultPlanListData('', offset, true);
  };

  useEffect(() => {
    initialState();
  }, []);

  return (
    <Box sx={Default_style.rootSx} className={`${className}`} {...rest}>
      <Box>
        <Box
          sx={{
            ...Default_style.SearchSelectSx,
            // width: { xs: '92vw', sm: '400px' },
          }}
        >
          <Input
            value={search}
            id="defaultSearch"
            // disabled={location.state?.doctorId ? true : false}
            onChange={(e) => handleChange(e)}
            textFieldStyle={Default_style.textFieldStyle}
            startAdornment={
              <IconButton aria-label="" edge="start" disableRipple>
                <SearchIcon rootStyle={{ color: 'grey.500' }} />
              </IconButton>
            }
            placeholder="Search plan"
          />
          <Typography sx={{ color: 'grey', fontSize: '12px', mt: 2 }}>
            {`${planMasterDefaultCount}  plans`}
          </Typography>
        </Box>
        {Array.isArray(planMasterDefaultData) &&
          planMasterDefaultData.length > 0 && (
            <Box pb={2} sx={{ height: '58vh', overflow: 'scroll' }}>
              {Array.isArray(planMasterDefaultData) &&
                planMasterDefaultData.length > 0 &&
                planMasterDefaultData.map((data, i) => {
                  return (
                    <Box py={1} key={i}>
                      <PlanDetailCard
                        onClickFun={() => handleCardClick(data.id)}
                        Date={getDateFormatToString(data.created_at, 'lll')}
                        duration={data.duration}
                        durationType={data.duration_type}
                        activities={data.activity_count}
                        planName={data.plan_name}
                        category={data.category_id}
                        cardContainerStyle={{
                          border: '0px solid',
                          boxShadow:
                            'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
                        }}
                      />
                    </Box>
                  );
                })}
              <div ref={observerTarget} style={{ opacity: 0 }}>
                intersection
              </div>
            </Box>
          )}
      </Box>
      {loading && (
        <Box mt={loading ? 9 : 0} sx={{ height: '58vh', overflow: 'scroll' }}>
          {loading &&
            Array(5)
              .fill(1)
              .map((val, i) => (
                <SkeletonCard
                  color="#e0ddf3"
                  isByme
                  key={i}
                  isCardDetailsRequired={false}
                />
              ))}
        </Box>
      )}

      {planMasterDefaultData.length === 0 && (
        <HomeEmptyState
          illustartion={<FileSearchIllustration />}
          title="No Default plan "
        />
      )}
    </Box>
  );
}

DefaultPlan.propTypes = {
  className: PropTypes.string,
};

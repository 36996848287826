export const signIn_style = {
  rootSx: {
    // display: 'grid',
    // gridTemplateRows: 'minmax(auto, 26%) minmax(auto, 74%)',
    backgroundColor: '#F3F0FF',
    // gap: 3.5,
    // minHeight: '100vh',
    // display: 'flex',
    // justifyContent: 'center',
  },
  imgContSx: {
    py: 3.5,
    // maxWidth: '90%',
    // mx: 'auto',
    // my: 'auto',
  },
};

import { Label } from '@atoms';
import {
  BloodGroup,
  BmiBgIcon,
  BpIcon,
  HeightIcon,
  // PulseIcon,
  // SpoIcon,
  TemperatureIcon,
  WeightIcon,
} from '@atoms/icons';
import { clinicalRoutes } from '@hc/routes';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { DropIcon, DropUpIcon, EditIcon } from '../../../atoms';
import { VitalsCard_style } from './style';

export function VitalsCard(props) {
  const {
    className = '',
    data = {},
    isShowMedicalCondition = false,
    fromMedicalCondition = false,
    handleOnChangePage = () => {},
    ...rest
  } = props;
  const [expand, setExpand] = useState(fromMedicalCondition);

  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={VitalsCard_style.cardSx}>
        <Box>
          {/* card Title */}
          <Box sx={{ padding: 2 }}>
            <Typography sx={VitalsCard_style.titleSx}>Vitals</Typography>

            <Grid container rowSpacing={2}>
              <Grid item xs={6} sm={6}>
                <Box sx={VitalsCard_style.vitalWrapperSx}>
                  <HeightIcon />
                  <Box>
                    <Label isRequired>Height</Label>
                    {data?.height ? (
                      <Typography sx={VitalsCard_style.vitalValueSx}>
                        {data?.height}
                        <Box as="span" sx={VitalsCard_style.unitSx}>
                          <Label>Cm</Label>
                        </Box>
                      </Typography>
                    ) : (
                      <Link to={clinicalRoutes.editProfile}>
                        <Typography sx={VitalsCard_style.addBtnSx}>
                          + Add
                        </Typography>
                      </Link>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box sx={VitalsCard_style.vitalWrapperSx}>
                  <WeightIcon />
                  <Box>
                    <Label isRequired>Weight</Label>
                    {data?.weight ? (
                      <Typography sx={VitalsCard_style.vitalValueSx}>
                        {data?.weight}
                        <Box as="span" sx={VitalsCard_style.unitSx}>
                          <Label>Kg</Label>
                        </Box>
                      </Typography>
                    ) : (
                      <Link to={clinicalRoutes.editProfile}>
                        <Typography sx={VitalsCard_style.addBtnSx}>
                          + Add
                        </Typography>
                      </Link>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box sx={VitalsCard_style.vitalWrapperSx}>
                  <BmiBgIcon />
                  <Box>
                    <Typography sx={VitalsCard_style.vitalSx}>BMI</Typography>
                    {data?.bmi ? (
                      <Typography sx={VitalsCard_style.vitalValueSx}>
                        {data?.bmi}
                        <Box as="span" sx={VitalsCard_style.unitSx}>
                          <Label> {data?.bmiCategory ?? 'Normal'}</Label>
                        </Box>
                      </Typography>
                    ) : (
                      <Link to={clinicalRoutes.editProfile}>
                        <Typography sx={VitalsCard_style.addBtnSx}>
                          + Add
                        </Typography>
                      </Link>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                {/* temperature */}
                <Box sx={VitalsCard_style.vitalWrapperSx}>
                  <TemperatureIcon />
                  <Box>
                    <Typography sx={VitalsCard_style.vitalSx}>Temp</Typography>
                    {data?.temp_in_f ? (
                      <Typography sx={VitalsCard_style.vitalValueSx}>
                        {data?.temp_in_f}
                        <Box as="span" sx={VitalsCard_style.unitSx}>
                          <Label>F</Label>
                        </Box>
                      </Typography>
                    ) : (
                      <Link to={clinicalRoutes.editProfile}>
                        <Typography sx={VitalsCard_style.addBtnSx}>
                          + Add
                        </Typography>
                      </Link>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                {/* blood */}
                <Box sx={VitalsCard_style.vitalWrapperSx}>
                  <BloodGroup />
                  <Box>
                    <Typography sx={VitalsCard_style.vitalSx}>Blood</Typography>
                    {data?.bloodGroupName ? (
                      <Typography sx={VitalsCard_style.vitalValueSx}>
                        {data?.bloodGroupName}
                        <Box as="span" sx={VitalsCard_style.unitSx}>
                          <Label />
                        </Box>
                      </Typography>
                    ) : (
                      <Link to={clinicalRoutes.editProfile}>
                        <Typography sx={VitalsCard_style.addBtnSx}>
                          + Add
                        </Typography>
                      </Link>
                    )}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} sm={6}>
                {/* bp */}
                <Box sx={VitalsCard_style.vitalWrapperSx}>
                  <BpIcon />
                  <Box>
                    <Typography sx={VitalsCard_style.vitalSx}>BP</Typography>
                    {data?.blood_pressure ? (
                      <Typography sx={VitalsCard_style.vitalValueSx}>
                        {data?.blood_pressure}
                        <Box as="span" sx={VitalsCard_style.unitSx}>
                          <Label>mmHg</Label>
                        </Box>
                      </Typography>
                    ) : (
                      <Link to={clinicalRoutes.editProfile}>
                        <Typography sx={VitalsCard_style.addBtnSx}>
                          + Add
                        </Typography>
                      </Link>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {isShowMedicalCondition && (
            <Box>
              <Divider />
              <Grid item xs={12} px={2} py={1.8}>
                <Stack flexDirection="row" justifyContent="space-between">
                  <Typography sx={VitalsCard_style.ConditionsTextSx}>
                    Medical Conditions
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      cursor: 'pointer',
                    }}
                  >
                    {expand && (
                      <EditIcon
                        onClick={() => handleOnChangePage()}
                        rootStyle={{
                          color: '#1C7C6C',
                        }}
                      />
                    )}
                    {expand ? (
                      <DropIcon
                        onClick={() => setExpand(false)}
                        rootStyle={{
                          cursor: 'pointer',
                          color: '#DCF5F1',
                          stroke: '#1C7C6C',
                        }}
                      />
                    ) : (
                      <DropUpIcon
                        onClick={() => setExpand(true)}
                        rootStyle={{
                          cursor: 'pointer',
                          stroke: '#1C7C6C',
                          color: '#DCF5F1',
                        }}
                      />
                    )}
                  </Box>
                </Stack>
                <Typography sx={VitalsCard_style.content}>
                  Please include relevant information, if any. Else, Skip.
                </Typography>
              </Grid>

              {expand && (
                <Box sx={{ px: 1.5, pt: 0, pb: 1 }}>
                  {/* Allergy */}
                  <Typography sx={VitalsCard_style.headingSx}>
                    Allergy
                  </Typography>
                  <Box sx={VitalsCard_style.lableContainerSx}>
                    {data?.medical_allergies &&
                    data?.medical_allergies.length ? (
                      data?.medical_allergies?.map((val, index) => (
                        <Box key={index} sx={VitalsCard_style.labelSx}>
                          {val ?? ''}
                        </Box>
                      ))
                    ) : (
                      <Typography sx={VitalsCard_style.noDataFound}>
                        Include the allergies you have, if any. <br />
                        Eg: Food, Dust etc
                      </Typography>
                    )}{' '}
                  </Box>
                  {/* Problem */}
                  <Typography sx={VitalsCard_style.headingSx}>
                    Health Problem
                  </Typography>
                  <Box sx={VitalsCard_style.lableContainerSx}>
                    {data?.medical_health_problems &&
                    data?.medical_health_problems.length ? (
                      data?.medical_health_problems?.map((val, index) => (
                        <Box key={index} sx={VitalsCard_style.labelSx}>
                          {val ?? ''}
                        </Box>
                      ))
                    ) : (
                      <Typography sx={VitalsCard_style.noDataFound}>
                        Include your health problems if applicable. <br />
                        Eg: Diabetics, Asthma etc
                      </Typography>
                    )}{' '}
                  </Box>
                  {/* Medications */}
                  <Typography sx={VitalsCard_style.headingSx}>
                    Active Medication
                  </Typography>
                  <Box sx={VitalsCard_style.lableContainerSx}>
                    {data?.medical_active_medications &&
                    data?.medical_active_medications.length ? (
                      data?.medical_active_medications?.map((val, index) => (
                        <Box key={index} sx={VitalsCard_style.labelSx}>
                          {val ?? ''}
                        </Box>
                      ))
                    ) : (
                      <Typography sx={VitalsCard_style.noDataFound}>
                        Specify any medicines you are presently taking. <br />
                        Eg: Lisinopril, Aspirin etc
                      </Typography>
                    )}{' '}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
VitalsCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  sx: PropTypes.object,
  isShowMedicalCondition: PropTypes.bool,
  fromMedicalCondition: PropTypes.bool,
  handleOnChangePage: PropTypes.func,
};

/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient,
} from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useExpertHistory = create((set, get) => ({
  historyState: {
    historyData: [],
    historyCount: '',
    historyRenewalData: {},
    filters: {
      search: '',
      gender_filter: '',
      date: '',
      date_filter: {
        start_date: '',
        end_date: '',
      },
      type_filter: '',
    },
  },
  loading: null,
  setHistoryDataLocalStorage: () => {
    const { historyState } = get();
    localStorage.setItem(
      localStorageKeys?.historyState,
      JSON.stringify(historyState),
    );
  },
  // getHistoryDataLocalStorage: () => {
  //   const data = localStorage.getItem(localStorageKeys?.requestState);
  //   const jsonData = JSON.parse(data);
  //   if (jsonData !== 'undefined') {
  //     if (jsonData) {
  //       set({
  //         requestState: jsonData,
  //       });
  //     }
  //   }
  // },
  getHistory: async (search, offset, isSearch) => {
    try {
      const { historyState, setHistoryDataLocalStorage } = get();

      const { filters, historyData } = historyState;
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });
      const payload = {
        user_id: tokenData?.user_id ?? '',
        page: offset ?? 0,
        limit: 10,
        search: search ?? '',
        gender_filter: filters?.gender_filter ?? '',
        date_filter: filters?.date_filter ?? '',
        type_filter: filters?.type_filter ?? '',
      };

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['expert-history', payload],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/expert/plan/history`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      let array = [];

      for (const val of data?.new_plan_history) {
        let obj = {};
        let mind = tokenData?.is_mind_expert ? 'Inprogress' : 'Not yet';
        let body = tokenData?.is_body_expert ? 'Inprogress' : 'Not yet';
        let food = tokenData?.is_food_expert ? 'Inprogress' : 'Not yet';
        if (val?.mbf_expert_plan_statuses?.length > 0) {
          for (const object of val?.mbf_expert_plan_statuses) {
            if (object?.master_mbf_category?.category_name === 'Mind') {
              mind =
                object?.created_by === tokenData?.user_profile_id
                  ? 'Inprogress'
                  : 'Completed';
            } else if (object?.master_mbf_category?.category_name === 'Body') {
              body =
                object?.created_by === tokenData?.user_profile_id
                  ? 'Inprogress'
                  : 'Completed';
            } else if (object?.master_mbf_category?.category_name === 'Food') {
              food =
                object?.created_by === tokenData?.user_profile_id
                  ? 'Inprogress'
                  : 'Completed';
            }
          }
        }

        obj = {
          ...val,
          status: val.plan_status,
          mind,
          body,
          food,
        };
        array.push(obj);
      }

      const arrayData = isSearch ? array : [...historyData, ...array];

      if (status?.code === 200) {
        set({
          loading: false,
          historyState: {
            ...historyState,
            historyData: arrayData ?? [],
            historyCount: data?.count ?? 0,
          },
        });
      }
      setHistoryDataLocalStorage();
      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getHistoryByUserPlanId: async (user_plan_id) => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });
      const payload = {
        user_id: tokenData?.user_id ?? '',
        user_plan_id: user_plan_id ?? '',
      };

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['expert-history', payload],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/expert/plan/history-get-by-user-plan-id`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      // let array = [];

      let obj = {};
      let mind = tokenData?.is_mind_expert ? 'Inprogress' : 'Not yet';
      let body = tokenData?.is_body_expert ? 'Inprogress' : 'Not yet';
      let food = tokenData?.is_food_expert ? 'Inprogress' : 'Not yet';
      if (data?.new_plan_history?.[0]?.mbf_expert_plan_statuses?.length > 0) {
        for (const object of data?.new_plan_history?.[0]
          ?.mbf_expert_plan_statuses) {
          if (object?.master_mbf_category?.category_name === 'Mind') {
            mind =
              object?.created_by === tokenData?.user_profile_id
                ? 'Inprogress'
                : 'Completed';
          } else if (object?.master_mbf_category?.category_name === 'Body') {
            body =
              object?.created_by === tokenData?.user_profile_id
                ? 'Inprogress'
                : 'Completed';
          } else if (object?.master_mbf_category?.category_name === 'Food') {
            food =
              object?.created_by === tokenData?.user_profile_id
                ? 'Inprogress'
                : 'Completed';
          }
        }
      }

      obj = {
        ...data?.new_plan_history?.[0],
        status: data?.new_plan_history?.[0]?.plan_status,
        mind,
        body,
        food,
      };
      return { statusCode: status?.code, data: obj };
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getHistoryRenewalPlanById: async (profileId) => {
    try {
      const { setHistoryDataLocalStorage } = get();
      const { historyState } = get();
      const payload = {
        mbf_user_plan_id: profileId ?? '',
      };
      // set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: [`get-history-plan-details-by-id`],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/expert/get-history-plan-details-by-id`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        set({
          // loading: false,
          historyState: {
            ...historyState,
            historyRenewalData: data ?? {},
          },
        });
      }
      setHistoryDataLocalStorage();
      return data;
    } catch (err) {
      // set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  updateHistoryFilters: (key, value) => {
    const { historyState, setHistoryDataLocalStorage } = get();
    const { filters } = historyState;

    set({
      historyState: {
        ...historyState,
        filters: {
          ...filters,
          [key]: value,
        },
      },
    });
    setHistoryDataLocalStorage();
  },
}));

export const selectActivitiesFooter_style = {
  containerSx: {
    height: '50px',
    alignItems: 'center',
    justifyContent: 'space-between',
    px: 2,
    background: ' #6552CC',
    boxShadow: '0px 0px 20px #00000014',
    borderRadius: '8px',
  },
  selectedFont: {
    fontSize: '10px',
    fontWeight: 300,
    letterSpacing: '-0.05px',
    color: '#FFFFFF',
  },
  activityFont: {
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '-0.06px',
    color: '#FFFFFF',
    textAlign: 'left',
  },
  proceedFont: {
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '-0.05px',
    color: '#FFFFFF',
    textAlign: 'left',
    cursor: 'pointer',
  },
  viewFont: {
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '-0.05px',
    color: '#FFFFFF',
    textAlign: 'left',
    cursor: 'pointer',
  },
};

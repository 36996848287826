import { SvgIcon } from '@mui/material';

export function NewRequestIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '92px',
    height: '104px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 92.247 104"
      {...rest}
    >
      <g transform="translate(-272.437 -121.721)">
        <path
          data-name="Path 101443"
          d="M273.12 213.836l89.083-.646 2.481 60.565-92.244-.855z"
          transform="translate(-.002 -56.923)"
          fill="#d3d3d3"
        />
        <path
          data-name="Path 101444"
          d="M274.23 156.913l42.164-34.113a4.842 4.842 0 016.115.023l40.808 33.447"
          transform="translate(-1.116)"
          fill="#a2a2a2"
        />
        <g data-name="Group 106531">
          <path
            data-name="Path 101445"
            d="M274.23 156.913l42.164-34.113a4.842 4.842 0 016.115.023l40.808 33.447"
            transform="translate(273.114 121.721) translate(-274.23 -121.721)"
            opacity={0.3}
          />
        </g>
        <path
          data-name="Path 101446"
          d="M272.438 314.595c-.11-.106 7.021-7.893 15.931-17.392s16.218-17.117 16.331-17.015-7.021 7.89-15.931 17.392-16.218 17.12-16.331 17.015z"
          transform="translate(0 -98.617)"
          fill="#263238"
        />
        <path
          data-name="Path 101447"
          d="M466.227 316.793c-.113.1-7.059-7.527-15.511-17.037s-15.216-17.306-15.108-17.408 7.059 7.527 15.511 17.041 15.228 17.302 15.108 17.404z"
          transform="translate(-101.544 -99.961)"
          fill="#263238"
        />
        <g data-name="Group 106532">
          <path
            data-name="Path 101448"
            d="M363.323 213.19l-44.736 35.736-44.347-35.09"
            transform="translate(273.118 156.267) translate(-274.24 -213.19)"
            opacity={0.5}
          />
        </g>
        <path
          data-name="Path 101449"
          d="M285.886 176.3l-.136-27.82 78-.925.88 29.274-38.886 30.785z"
          transform="translate(-8.285 -16.074)"
          fill="#fafafa"
        />
        <path
          data-name="Path 101450"
          d="M285.83 176.14s.253.189.733.563l2.115 1.647 8.063 6.3 29.01 22.744h-.1l38.875-30.8-.038.079c-.166-5.582-.344-11.444-.525-17.4-.117-4.015-.238-7.98-.355-11.87l.1.094-78 .888.06-.06c.03 8.751.057 15.7.072 20.47v5.476c0-1.243-.026-3.074-.045-5.454l-.132-20.493v-.064h.06l78.006-.956h.094v.1l.355 11.867c.181 5.964.355 11.821.525 17.407v.049l-.038.03-38.9 30.77-.049.038-.049-.038-28.994-22.827-8.014-6.326-2.115-1.662c-.476-.376-.714-.572-.714-.572z"
          transform="translate(-8.229 -15.918)"
          fill="#263238"
        />
        <path
          data-name="Path 101451"
          d="M371.837 184.6c0 .053-11.931.17-26.642.257s-26.645.121-26.645.064 11.927-.17 26.645-.261 26.642-.116 26.642-.06z"
          transform="translate(-28.697 -39.113)"
          fill="#263238"
        />
        <path
          data-name="Path 101452"
          d="M371.837 205.405c0 .057-11.931.174-26.642.261s-26.645.117-26.645.064 11.927-.17 26.645-.261 26.642-.116 26.642-.064z"
          transform="translate(-28.697 -52.062)"
          fill="#263238"
        />
        <path
          data-name="Path 101453"
          d="M371.837 229.394c0 .053-11.931.17-26.642.261s-26.645.117-26.645.06 11.927-.17 26.645-.257 26.642-.121 26.642-.064z"
          transform="translate(-28.697 -66.99)"
          fill="#263238"
        />
        <path
          data-name="Path 101454"
          d="M375.87 253.373c0 .057-11.379.166-25.418.253s-25.418.11-25.422.057 11.379-.166 25.422-.253 25.418-.109 25.418-.057z"
          transform="translate(-32.73 -81.912)"
          fill="#263238"
        />
        <path
          data-name="Path 101455"
          d="M382.544 274.439a1.64 1.64 0 01-.31.023l-.9.026-3.293.053-10.873.155c-4.245.072-8.094.094-10.877.1H352.1a1.969 1.969 0 01-.31-.019 1.64 1.64 0 01.31-.023l.9-.023 3.293-.057 10.873-.155c4.245-.072 8.094-.094 10.873-.1h4.2a2.454 2.454 0 01.31.019z"
          transform="translate(-49.383 -95.028)"
          fill="#263238"
        />
        <path
          data-name="Path 101456"
          d="M387.96 294.548c0 .053-2.542.1-5.665.1s-5.665-.045-5.665-.1 2.546-.1 5.665-.1 5.665.044 5.665.1z"
          transform="translate(-64.842 -107.493)"
          fill="#263238"
        />
        <ellipse
          data-name="Ellipse 129866"
          cx={13.296}
          cy={13.296}
          rx={13.296}
          ry={13.296}
          transform="translate(332.145 158.596)"
          fill="#3b2e81"
        />
        <ellipse
          data-name="Ellipse 129867"
          cx={13.296}
          cy={13.296}
          rx={13.296}
          ry={13.296}
          transform="translate(329.686 158.596)"
          fill="#7a67d9"
        />
        <text
          data-name={0}
          transform="translate(342.982 179.596)"
          fill="#fff"
          fontSize={20}
          fontFamily="Poppins-Bold, Poppins"
          fontWeight={700}
        >
          <tspan x={-6} y={0}>
            0
          </tspan>
        </text>
        <ellipse
          data-name="Ellipse 129868"
          cx={46}
          cy={3}
          rx={46}
          ry={3}
          transform="translate(272.437 219.721)"
          fill="#e8e8e8"
        />
      </g>
    </SvgIcon>
  );
}
export function NotesEmptyStateIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '128px',
    height: '118px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 128.252 118.438"
      {...rest}
    >
      <path
        data-name="Path 20625"
        d="M199.688 152.67c.327-1.875.852-3.73 1.228-5.64a33.34 33.34 0 00.157-11.169 43.873 43.873 0 00-8.233-19.6c-4.586-6.152-11.482-11.134-18.894-9.252-7.711 1.949-14.238 7.9-21.737 10.609-12.7 4.593-25.557-3.779-38.355-3.48-11.051.24-22.645 4.207-29.924 14.016-2.972 4-5.042 9.395-4.339 14.7.859 6.462 5.6 11.378 9.137 16.159 5.421 7.328 7.474 15.31 2.912 23.863-3.083 5.8-7.23 10.919-6.872 18.118.574 11.517 11.208 18.473 19.976 21.573 8.908 3.152 19.023 3.48 27.878-.07 7.867-3.166 13.358-10.091 20.529-14.51a32.45 32.45 0 0121.055-4.621c7.578.9 15.564 2.5 22.888-.769 7.008-3.132 10.327-11.5 10.588-19.52a32.063 32.063 0 00-1.778-11.775c-1.625-4.544-5.219-8.09-6.232-12.909a14.962 14.962 0 01.014-5.717z"
        transform="translate(-77.798 -112.187) translate(77.798 112.187) translate(-79.454 -106.601)"
        fill="#c9c4e9"
      />
      <path
        data-name="Path 20626"
        d="M199.688 152.67c.327-1.875.852-3.73 1.228-5.64a33.34 33.34 0 00.157-11.169 43.873 43.873 0 00-8.233-19.6c-4.586-6.152-11.482-11.134-18.894-9.252-7.711 1.949-14.238 7.9-21.737 10.609-12.7 4.593-25.557-3.779-38.355-3.48-11.051.24-22.645 4.207-29.924 14.016-2.972 4-5.042 9.395-4.339 14.7.859 6.462 5.6 11.378 9.137 16.159 5.421 7.328 7.474 15.31 2.912 23.863-3.083 5.8-7.23 10.919-6.872 18.118.574 11.517 11.208 18.473 19.976 21.573 8.908 3.152 19.023 3.48 27.878-.07 7.867-3.166 13.358-10.091 20.529-14.51a32.45 32.45 0 0121.055-4.621c7.578.9 15.564 2.5 22.888-.769 7.008-3.132 10.327-11.5 10.588-19.52a32.063 32.063 0 00-1.778-11.775c-1.625-4.544-5.219-8.09-6.232-12.909a14.962 14.962 0 01.014-5.717z"
        transform="translate(-77.798 -112.187) translate(77.798 112.187) translate(-79.454 -106.601)"
        fill="#fff"
        opacity={0.7}
      />
      <path
        d="M16.558 4A8.569 8.569 0 008 12.558v58.957a8.569 8.569 0 008.558 8.558h24.991a24.847 24.847 0 01-4.112-5.705H16.558a2.856 2.856 0 01-2.853-2.853V12.558a2.856 2.856 0 012.853-2.853h21.871v16.166a8.569 8.569 0 008.558 8.558h16.166v4.127a24.415 24.415 0 015.705 1.586v-8.565a2.842 2.842 0 00-.836-2.017L43.3 4.836A2.842 2.842 0 0041.282 4zm27.577 9.739l14.984 14.985H46.988a2.856 2.856 0 01-2.853-2.853zm15.215 28.3a20.92 20.92 0 1020.92 20.92 20.92 20.92 0 00-20.921-20.922zm-9.51 9.507a1.9 1.9 0 011.345.557l8.165 8.164 8.164-8.167a1.9 1.9 0 112.686 2.692l-8.164 8.165 8.164 8.164a1.9 1.9 0 11-2.689 2.689l-8.165-8.164-8.165 8.164a1.9 1.9 0 01-2.681-2.689l8.164-8.165-8.164-8.164a1.9 1.9 0 011.345-3.246z"
        transform="translate(-77.798 -112.187) translate(97.905 127.252)"
        fill="#b1acce"
      />
    </SvgIcon>
  );
}

export function DontsEmptyStateIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '128px',
    height: '118px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 128.252 118.438"
      {...rest}
    >
      <g data-name="dont's">
        <path
          data-name="Path 20625"
          d="M199.688 152.67c.327-1.875.852-3.73 1.228-5.64a33.34 33.34 0 00.157-11.169 43.873 43.873 0 00-8.233-19.6c-4.586-6.152-11.482-11.134-18.894-9.252-7.711 1.949-14.238 7.9-21.737 10.609-12.7 4.593-25.557-3.779-38.355-3.48-11.051.24-22.645 4.207-29.924 14.016-2.972 4-5.042 9.395-4.339 14.7.859 6.462 5.6 11.378 9.137 16.159 5.421 7.328 7.474 15.31 2.912 23.863-3.083 5.8-7.23 10.919-6.872 18.118.574 11.517 11.208 18.473 19.976 21.573 8.908 3.152 19.023 3.48 27.878-.07 7.867-3.166 13.358-10.091 20.529-14.51a32.45 32.45 0 0121.055-4.621c7.578.9 15.564 2.5 22.888-.769 7.008-3.132 10.327-11.5 10.588-19.52a32.063 32.063 0 00-1.778-11.775c-1.625-4.544-5.219-8.09-6.232-12.909a14.962 14.962 0 01.014-5.717z"
          transform="translate(-77.798 -112.187) translate(77.798 112.187) translate(-79.454 -106.601)"
          fill="#c9c4e9"
        />
        <path
          data-name="Path 20626"
          d="M199.688 152.67c.327-1.875.852-3.73 1.228-5.64a33.34 33.34 0 00.157-11.169 43.873 43.873 0 00-8.233-19.6c-4.586-6.152-11.482-11.134-18.894-9.252-7.711 1.949-14.238 7.9-21.737 10.609-12.7 4.593-25.557-3.779-38.355-3.48-11.051.24-22.645 4.207-29.924 14.016-2.972 4-5.042 9.395-4.339 14.7.859 6.462 5.6 11.378 9.137 16.159 5.421 7.328 7.474 15.31 2.912 23.863-3.083 5.8-7.23 10.919-6.872 18.118.574 11.517 11.208 18.473 19.976 21.573 8.908 3.152 19.023 3.48 27.878-.07 7.867-3.166 13.358-10.091 20.529-14.51a32.45 32.45 0 0121.055-4.621c7.578.9 15.564 2.5 22.888-.769 7.008-3.132 10.327-11.5 10.588-19.52a32.063 32.063 0 00-1.778-11.775c-1.625-4.544-5.219-8.09-6.232-12.909a14.962 14.962 0 01.014-5.717z"
          transform="translate(-77.798 -112.187) translate(77.798 112.187) translate(-79.454 -106.601)"
          fill="#fff"
          opacity={0.7}
        />
        <g data-name="Group 106446" fill="#b1acce">
          <path
            data-name="Path 99976"
            d="M19.407.794a3.934 3.934 0 00-3.921 3.9v.255H7.024a5.043 5.043 0 00-5.031 5.024V63.5a5.045 5.045 0 005.031 5.024h27.8a1.331 1.331 0 00.343-.052 16.792 16.792 0 1018.253-23.69V9.973a5.043 5.043 0 00-5.031-5.021h-8.461V4.7A3.934 3.934 0 0036.007.8zm0 2.663h16.6A1.219 1.219 0 0137.265 4.7v3.161A1.217 1.217 0 0136.007 9.1h-16.6a1.215 1.215 0 01-1.256-1.238V4.7a1.217 1.217 0 011.256-1.244zM7.024 7.6h8.461v.257a3.932 3.932 0 003.921 3.9h16.6a3.932 3.932 0 003.921-3.9V7.6h8.461a2.337 2.337 0 012.369 2.369V44.5c-.14 0-.28-.005-.421-.005a16.759 16.759 0 00-16.16 21.364H7.024A2.328 2.328 0 014.656 63.5V9.973A2.337 2.337 0 017.024 7.6zm15.017 10.085a1.331 1.331 0 00-.991.494l-4.875 6.053-2.535-2.624a1.331 1.331 0 10-1.916 1.849l3.586 3.713a1.331 1.331 0 002-.088l5.817-7.231a1.331 1.331 0 00-1.082-2.166zm6.88 5.291a1.331 1.331 0 100 2.663h13.807a1.331 1.331 0 000-2.663zm-6.88 10.1a1.331 1.331 0 00-.991.494l-4.875 6.053L13.639 37a1.331 1.331 0 00-1.916 1.849l3.586 3.713a1.331 1.331 0 002-.088l5.817-7.234a1.328 1.328 0 00-1.082-2.163zm6.88 5.289a1.333 1.333 0 100 2.665h13.807a1.333 1.333 0 000-2.665zm21.416 8.8a14.093 14.093 0 11-14.143 14.089 14.1 14.1 0 0114.142-14.093zm-28.3 1.308a1.331 1.331 0 00-.991.5l-4.875 6.056-2.532-2.629a1.33 1.33 0 10-1.916 1.846l3.586 3.705a1.331 1.331 0 002-.088l5.817-7.223a1.33 1.33 0 00-1.082-2.171z"
            fillRule="evenodd"
            transform="translate(-77.798 -112.187) translate(106.324 131.788)"
          />
          <path
            d="M48.3 42.037a9.869 9.869 0 109.869 9.869 9.869 9.869 0 00-9.869-9.869zm-4.486 4.486a.894.894 0 01.634.263l3.852 3.851 3.852-3.851a.9.9 0 011.269 1.269l-3.851 3.852 3.851 3.852a.9.9 0 11-1.269 1.269L48.3 53.174l-3.852 3.851a.9.9 0 11-1.269-1.269l3.851-3.852-3.851-3.852a.9.9 0 01.634-1.531z"
            transform="translate(-77.798 -112.187) translate(106.324 131.788) translate(2.17 8.849)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function ReferenceEmptyStateIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '128px',
    height: '118px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 128.252 118.438"
      {...rest}
    >
      <path
        data-name="Path 20625"
        d="M199.688 152.67c.327-1.875.852-3.73 1.228-5.64a33.34 33.34 0 00.157-11.169 43.873 43.873 0 00-8.233-19.6c-4.586-6.152-11.482-11.134-18.894-9.252-7.711 1.949-14.238 7.9-21.737 10.609-12.7 4.593-25.557-3.779-38.355-3.48-11.051.24-22.645 4.207-29.924 14.016-2.972 4-5.042 9.395-4.339 14.7.859 6.462 5.6 11.378 9.137 16.159 5.421 7.328 7.474 15.31 2.912 23.863-3.083 5.8-7.23 10.919-6.872 18.118.574 11.517 11.208 18.473 19.976 21.573 8.908 3.152 19.023 3.48 27.878-.07 7.867-3.166 13.358-10.091 20.529-14.51a32.45 32.45 0 0121.055-4.621c7.578.9 15.564 2.5 22.888-.769 7.008-3.132 10.327-11.5 10.588-19.52a32.063 32.063 0 00-1.778-11.775c-1.625-4.544-5.219-8.09-6.232-12.909a14.962 14.962 0 01.014-5.717z"
        transform="translate(-77.798 -112.187) translate(77.798 112.187) translate(-79.454 -106.601)"
        fill="#c9c4e9"
      />
      <path
        data-name="Path 20626"
        d="M199.688 152.67c.327-1.875.852-3.73 1.228-5.64a33.34 33.34 0 00.157-11.169 43.873 43.873 0 00-8.233-19.6c-4.586-6.152-11.482-11.134-18.894-9.252-7.711 1.949-14.238 7.9-21.737 10.609-12.7 4.593-25.557-3.779-38.355-3.48-11.051.24-22.645 4.207-29.924 14.016-2.972 4-5.042 9.395-4.339 14.7.859 6.462 5.6 11.378 9.137 16.159 5.421 7.328 7.474 15.31 2.912 23.863-3.083 5.8-7.23 10.919-6.872 18.118.574 11.517 11.208 18.473 19.976 21.573 8.908 3.152 19.023 3.48 27.878-.07 7.867-3.166 13.358-10.091 20.529-14.51a32.45 32.45 0 0121.055-4.621c7.578.9 15.564 2.5 22.888-.769 7.008-3.132 10.327-11.5 10.588-19.52a32.063 32.063 0 00-1.778-11.775c-1.625-4.544-5.219-8.09-6.232-12.909a14.962 14.962 0 01.014-5.717z"
        transform="translate(-77.798 -112.187) translate(77.798 112.187) translate(-79.454 -106.601)"
        fill="#fff"
        opacity={0.7}
      />
      <path
        d="M10.44 0a10.44 10.44 0 1010.44 10.44A10.44 10.44 0 0010.44 0zM5.694 4.745a.946.946 0 01.671.278L10.44 9.1l4.074-4.074a.949.949 0 111.342 1.342l-4.074 4.072 4.074 4.074a.949.949 0 11-1.342 1.342l-4.074-4.074-4.075 4.074a.949.949 0 11-1.342-1.342L9.1 10.44 5.023 6.365a.949.949 0 01.671-1.62z"
        transform="translate(-77.798 -112.187) translate(113.013 127.788) translate(40.6 50.885)"
        fill="#b1acce"
        data-name="Group 106446"
      />
      <path
        d="M32.151 4A28.2 28.2 0 006.333 20.9h4.7a24.056 24.056 0 0112.4-11.021A33.381 33.381 0 0019.207 20.9h4.308c1.8-7.8 5.354-12.677 8.636-12.677S38.984 13.1 40.787 20.9H45.1a33.381 33.381 0 00-4.239-11.018A24.056 24.056 0 0153.266 20.9h4.7A28.2 28.2 0 0032.151 4zM18.965 23.758a1.761 1.761 0 00-1.653 1.516l-1.158 7.266-2.107-4.87a1.761 1.761 0 00-3.23 0l-2.108 4.87-1.158-7.266a1.761 1.761 0 10-3.477.553l2.113 13.241a1.761 1.761 0 003.354.421l2.891-6.689 2.891 6.688a1.761 1.761 0 003.354-.421l2.113-13.241a1.761 1.761 0 00-1.824-2.069zm19.72 0a1.761 1.761 0 00-1.653 1.516l-1.158 7.266-2.108-4.87a1.761 1.761 0 00-3.23 0l-2.107 4.87-1.158-7.266a1.761 1.761 0 10-3.477.553l2.113 13.241a1.761 1.761 0 003.354.421l2.89-6.689 2.891 6.688a1.761 1.761 0 003.354-.421l2.113-13.241a1.761 1.761 0 00-1.824-2.069zm19.72 0a1.761 1.761 0 00-1.653 1.516l-1.159 7.266-2.107-4.87a1.761 1.761 0 00-3.23 0l-2.107 4.87-1.158-7.266a1.761 1.761 0 10-3.477.553l2.113 13.241a1.761 1.761 0 003.354.421l2.89-6.689 2.891 6.688a1.761 1.761 0 003.354-.421l2.113-13.241a1.761 1.761 0 00-1.829-2.068zM6.333 43.439a28.17 28.17 0 0051.638 0h-4.7a24.055 24.055 0 01-12.4 11.021A33.381 33.381 0 0045.1 43.439h-4.313c-1.8 7.8-5.354 12.677-8.636 12.677s-6.833-4.874-8.636-12.677h-4.308a33.381 33.381 0 004.234 11.021 24.056 24.056 0 01-12.4-11.021z"
        transform="translate(-77.798 -112.187) translate(102.225 136.017)"
        fill="#b1acce"
      />
    </SvgIcon>
  );
}

export function UnderDraftIllustratation(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '190px',
    height: '145px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 190.716 145.725"
      {...rest}
    >
      <ellipse
        cx={95.358}
        cy={5.567}
        rx={95.358}
        ry={5.567}
        fill="#e8e8e8"
        transform="translate(-56.11 -131.259) translate(56.11 265.849)"
      />
      <g transform="translate(-56.11 -131.259) translate(81.793 131.259)">
        <path
          data-name="Path 101457"
          d="M315.079 153.347a4.053 4.053 0 010-5.72l1.921-1.918a4.053 4.053 0 015.72 0 4.057 4.057 0 010 5.715l-1.923 1.923a4.057 4.057 0 01-5.715 0z"
          transform="translate(-212.797 -138.002)"
          fill="#fbe27f"
        />
        <path
          data-name="Path 101459"
          d="M320.214 143.919a5.223 5.223 0 010-7.377l3.758-3.757a5.223 5.223 0 017.377 0 5.218 5.218 0 010 7.377l-3.767 3.757a5.233 5.233 0 01-7.368 0z"
          transform="translate(-215.232 -131.259)"
          fill="#898989"
        />
        <path
          data-name="Path 101460"
          d="M319.451 143.63a8.912 8.912 0 00-12.571 0l12.571 12.576a8.921 8.921 0 000-12.576z"
          transform="translate(-209.23 -136.227)"
          fill="#898989"
        />
        <path
          data-name="Path 101461"
          d="M341.959 164.283a.762.762 0 010-1.525 13.771 13.771 0 100-27.542.762.762 0 110-1.52 15.294 15.294 0 110 30.588z"
          transform="translate(-226.639 -132.497)"
          fill="#898989"
        />
        <path
          data-name="Path 101462"
          d="M340.18 320.975l-17.4-82.625-2.326.634 9.035 42.886L316 320.95h-12.007l.034-.536h-2.68l-.2 2.907h39.729l.162-2.371zm-10.328-37.442l7.869 37.442h-20.813z"
          transform="translate(-206.318 -185.681)"
          fill="#b9b9b9"
        />
        <path
          data-name="Path 101463"
          d="M164.015 321l-17.381-82.6-2.326.634 9.03 42.886-13.49 39.08h-12.02l.039-.536h-2.685l-.192 2.907h39.724l.162-2.371zm-10.328-37.442L161.556 321h-20.823z"
          transform="translate(-116.796 -185.707)"
          fill="#b9b9b9"
        />
        <path
          data-name="Path 101464"
          d="M269.654 321.78l-2.238 6.477H180.99l2.233-6.477z"
          transform="translate(-145.255 -228.079)"
          fill="#b9b9b9"
        />
        <path
          data-name="Path 101465"
          d="M265.046 331.71l1.166 1.593h-86.426l-1.166-1.593z"
          transform="translate(-144.05 -233.125)"
          fill="#9c9c9c"
        />
        <path
          data-name="Path 101467"
          d="M215.422 240.162H109.86l-1.53-1.525 135.893-57.807 1.525 1.525z"
          transform="translate(-108.33 -156.45)"
          fill="#5b48bd"
        />
        <path
          data-name="Path 101468"
          d="M215.422 240.162H109.86l-1.53-1.525 135.893-57.807 1.525 1.525z"
          transform="translate(-108.33 -156.45)"
          opacity={0.4}
        />
        <path
          data-name="Path 101469"
          d="M213.893 238.638H108.33l30.33-57.808h105.563z"
          transform="translate(-108.33 -156.45)"
          fill="#7a67d9"
        />
        <path
          data-name="Path 101470"
          d="M224.191 237.076l-74.711.266L171.582 196c17.705 1.269 44.224 1.387 73.231.811z"
          transform="translate(-129.242 -164.159)"
          opacity={0.3}
        />
        <path
          data-name="Path 101471"
          d="M222.677 237.718H148.62l21.28-40.555c22.81.846 47.239.3 73.551-.423-4.082 10.22-11.478 25.048-20.774 40.978z"
          transform="translate(-128.805 -164.536)"
          fill="#fff"
        />
        <path
          data-name="Path 101489"
          d="M244.223 180.8l-30.315 57.808H108.33l30.33-57.808h30.261l-15.421 7.067h-.034c-7.869 2.867-14.159 7.259-17.376 12.743-7.377 12.561 4.215 26.066 25.869 30.207s45.173-2.725 52.536-15.286a15.049 15.049 0 002.2-8.361l1.589-26.4z"
          transform="translate(-108.33 -156.42)"
          opacity={0.17}
        />
        <path
          data-name="Line 1674"
          transform="translate(47.675 42.362)"
          fill="none"
          stroke="#d5ccff"
          strokeLinecap="round"
          strokeWidth={1}
          d="M0 0L39 0"
        />
        <path
          data-name="Line 1675"
          transform="translate(55.832 47.362)"
          fill="none"
          stroke="#d5ccff"
          strokeLinecap="round"
          strokeWidth={1}
          d="M0 0L39 0"
        />
        <path
          data-name="Line 1676"
          transform="translate(44.832 53.269)"
          fill="none"
          stroke="#d5ccff"
          strokeLinecap="round"
          strokeWidth={1}
          d="M0 0L39 0"
        />
        <path
          data-name="Line 1677"
          transform="translate(52.832 58.269)"
          fill="none"
          stroke="#d5ccff"
          strokeLinecap="round"
          strokeWidth={1}
          d="M0 0L39 0"
        />
      </g>
    </SvgIcon>
  );
}

export function HistoryIllustration(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '110px',
    height: '126px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 110.263 126.582"
      {...rest}
    >
      <g data-name="504 Error Gateway Timeout-cuate">
        <path
          data-name="Path 101491"
          d="M263.215 412.224c-2.45.088-5.193.226-7.223-1.363-1.876-1.468-3.554-3.659-3.311-6.175.072-.717.237-1.159 1.01-.855 1.17.469 2.069 1.76 2.881 2.665a25.153 25.153 0 003.4 3.234c.877.668 1.816 1.247 2.71 1.893l.331.248.127.1h.072a.116.116 0 01.1.05.072.072 0 010 .044.127.127 0 01-.1.16z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-251.308 -392.826)"
          fill="#455a64"
        />
        <path
          data-name="Path 101492"
          d="M264.711 413.9c-2.158-.039-4.486.061-6.208-1.4a10.066 10.066 0 01-2.009-2.439c-.248-.4-.993-1.611-.684-2.1.552-.872 3.129 2.682 3.488 3.024a10.485 10.485 0 004.768 2.472l.331.248a11.726 11.726 0 01-3.548-1.369c-1.529-.911-2.378-2.263-3.581-3.526-.254-.27-1.037-1.186-1.33-.618-.193.375.524 1.562.684 1.815a9.784 9.784 0 001.893 2.312c1.722 1.507 4.039 1.451 6.214 1.54a.072.072 0 01-.018.041z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-252.688 -394.658)"
          fill="#fff"
        />
        <path
          data-name="Path 101493"
          d="M271.955 409.691a1.285 1.285 0 01-.061.281.055.055 0 01-.039.05.094.094 0 01-.132-.11.34.34 0 000-.039l.028-.083c-.436-1.622-.607-3.311-1.065-4.966s-.982-3.074-1.3-4.657c-.2-1-.888-6 1.319-5.38.977.276 1.655 1.694 2.014 2.538a10.385 10.385 0 01.817 3.6c.158 3.061-.963 5.836-1.581 8.766z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-258.691 -388.792)"
          fill="#455a64"
        />
        <path
          data-name="Path 101494"
          d="M273.829 406.009a11.937 11.937 0 00-.353-3.4c-.1-.386-.579-2.207-1.2-2.18-.4.028-.475.822-.508 1.1a10.79 10.79 0 00.436 3.339 45.538 45.538 0 01.811 6.688 3.578 3.578 0 01-.055.425c-.017.138-.033.237-.044.359v.215a.1.1 0 00.127.05 2.375 2.375 0 01.039-.276v.226l.05-.237a.161.161 0 010-.05v-.368a50.116 50.116 0 00.7-5.888zm-.745 5.055a40.7 40.7 0 00-.381-3.924 49.394 49.394 0 00-.552-3.123 10.664 10.664 0 01-.281-1.771c0-.458.044-2.147.916-1.1a6.863 6.863 0 01.855 3.311 16.765 16.765 0 01-.061 3.112c-.126 1.166-.325 2.331-.496 3.495z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-259.858 -391.345)"
          fill="#fff"
        />
        <path
          data-name="Path 101495"
          d="M268.859 428.116h-.088c-2.246-.116-5.237.094-7.1-1.391-1.38-1.1-2.759-3.267-2.3-5.1.5-2.075 3.311.414 4.039 1.015a67.45 67.45 0 015.518 5.353v.039c.03.057-.02.057-.069.084z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-254.275 -400.479)"
          fill="#455a64"
        />
        <path
          data-name="Path 101496"
          d="M270.3 429.778l-.386-.243q-2.207-1.4-4.293-2.985c-.353-.265-2.881-2.516-3.261-1.711-.226.486.607 1.473.872 1.81a7.008 7.008 0 001.705 1.523c1.5.933 3.311 1.131 5.005 1.584a1.25 1.25 0 01.237.061h.044c.054 0 .099 0 .077-.039zm-4.415-1.28a6.886 6.886 0 01-1.937-1.308c-.381-.359-1.705-1.7-1.352-2.251.188-.3.342-.138.7.033.16.083.32.177.475.276.375.248.723.552 1.071.817.618.491 1.242.966 1.882 1.435a52.787 52.787 0 003.212 2.158 29.241 29.241 0 01-4.073-1.159z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-255.639 -402.208)"
          fill="#fff"
        />
        <path
          data-name="Path 101497"
          d="M280.785 419.71a13.244 13.244 0 01-3.863 6.716l-.039.028a.11.11 0 01-.116-.044.094.094 0 010-.132 1.044 1.044 0 00.11-.11c.375-3.052.784-6.07 1.1-9.149.072-.717.64-5.309 2.047-4.564.872.464.933 2.345 1 3.19a13.243 13.243 0 01-.239 4.065z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-262.102 -396.698)"
          fill="#455a64"
        />
        <path
          data-name="Path 101498"
          d="M280.01 417.928c-.077-.254-.21-.519-.5-.337-.552.342-.767 2.5-.855 3.1-.375 2.461-.96 4.829-1.551 7.24l-.149.613-.138.182a.11.11 0 00.116.044v-.07c.061-.077.127-.16.182-.243a15.722 15.722 0 002.207-4.332 14.794 14.794 0 00.684-3.024 8.64 8.64 0 00.004-3.173zm-2.881 10.347c-.028.044-.061.083-.083.121a1.014 1.014 0 01.072-.243 43.492 43.492 0 001.374-5.607c.166-.96.27-1.931.464-2.886.088-.4.281-1.556.618-1.744s.326.265.364.524a4.211 4.211 0 010 .7 15.451 15.451 0 01-2.809 9.135z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-262.137 -399.01)"
          fill="#fff"
        />
        <path
          data-name="Path 101499"
          d="M267.712 424.374h-.43v-1.186c-.055-1.595-.121-3.19-.215-4.784a54.288 54.288 0 00-.215-2.7v-.088a.738.738 0 00-.028-.221c-.028-.072 0-.16-.028-.237a19.435 19.435 0 00-1.545-5.86 30.02 30.02 0 00-.922-1.827l-.1-.171a.111.111 0 000-.044l-.1-.182-.077-.132c-1.037-1.827-2.207-3.576-3.355-5.331-.32-.5-.646-1.01-.96-1.518a.386.386 0 01-.055-.088c-.028-.033-.05-.072-.077-.11-1.413-2.207-2.8-4.486-4.277-6.677-.055-.083.077-.154.132-.072.993 1.451 2.02 2.881 3.041 4.321.138.188.27.386.408.552l.8 1.137a63.66 63.66 0 011.573 2.3 63.883 63.883 0 014.133 6.688c.088.171.171.348.254.524s.1.221.155.331.116.259.171.386a20.672 20.672 0 011.512 7.069l.033.513v.237a.905.905 0 000 .121V418.048c.066 1.595.11 3.19.143 4.779-.009.526.007 1.034.029 1.547z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-252.5 -388.062)"
          fill="#455a64"
        />
        <path
          data-name="Path 101500"
          d="M252.027 391.391c-1.231-1.656-2.8-5.06-.993-6.832a1.931 1.931 0 012.566-.193 5.632 5.632 0 011.694 3.085c.833 2.892 1.38 5.838 2.417 8.669 0 0 .088.243.116.3s-.072.088-.094 0-.072-.182-.1-.276c-1.964-1.387-4.149-2.777-5.606-4.753z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-250.213 -383.968)"
          fill="#455a64"
        />
        <path
          data-name="Path 101501"
          d="M259.38 398.148a.4.4 0 00-.028-.05 20.5 20.5 0 01-1.148-2.158 39.269 39.269 0 01-1.766-4.735c-.2-.618-1.1-3.653-2.169-2.445a2.743 2.743 0 00-.32 2.036 7.682 7.682 0 00.944 2.847 13.984 13.984 0 004.084 4.26l.215.166.044.072.033.05a.072.072 0 00.1-.039l.011-.006zm-.48-.337a16.351 16.351 0 01-3.454-3.471 8.493 8.493 0 01-1.1-2.3 6.8 6.8 0 01-.248-1.1 4.851 4.851 0 01-.05-.85c0-.624.281-1.308.651-1.369s.607.182.9.706a4.186 4.186 0 01.27.552 14.9 14.9 0 01.513 1.44c.254.8.513 1.6.8 2.395.2.552.4 1.065.635 1.589a21.277 21.277 0 001.346 2.616z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-251.865 -385.99)"
          fill="#fff"
        />
        <path
          data-name="Path 101502"
          d="M425.726 400.747c-.2 1.656-.519 3.256-.85 4.873-.188.916-.386 1.832-.552 2.759a6.624 6.624 0 00-.232 3.057.606.606 0 010 .138.121.121 0 01-.21-.033.511.511 0 010-.072 4.115 4.115 0 01-.353-.85 20.638 20.638 0 01-.811-2.367 20.331 20.331 0 01-.795-5.049 17.107 17.107 0 01.475-4.553c.292-1.192.706-2.963 1.595-3.863.8-.822 1.473.5 1.705 1.164a11.7 11.7 0 01.028 4.796z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-327.165 -388.7)"
          fill="#455a64"
        />
        <path
          data-name="Path 101503"
          d="M424.735 399.5c-.6 0-1.032 1.926-1.242 2.759a16.467 16.467 0 00-.513 4.017 21.522 21.522 0 001.236 7.141c.033.094.061.188.094.276v.061a.116.116 0 00.21.039c-.039-.2-.066-.4-.088-.6s-.044-.359-.055-.552a28.329 28.329 0 01.364-5.91c.33-2.242.783-7.231-.006-7.231zm-.43 13.564c.033.265.072.552.121.789a57.21 57.21 0 00-.177-.552 23.728 23.728 0 01-1.1-6.3 17.109 17.109 0 01.259-3.6 16.809 16.809 0 01.464-1.887c.088-.259.519-1.871.888-1.788.259.055.32 1.507.326 1.54v.872c0 .6-.05 1.2-.1 1.8-.116 1.3-.3 2.588-.458 3.863a22.623 22.623 0 00-.221 5.253z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-327.642 -390.929)"
          fill="#fff"
        />
        <path
          data-name="Path 101504"
          d="M436.555 412.493a15.562 15.562 0 01-4.851 5.182c-1.021.75-2.08 1.457-3.118 2.207a16.75 16.75 0 00-2.693 2.069l-.1.11a4.414 4.414 0 00-.552.773.061.061 0 01-.05.033.681.681 0 01-.039.094h-.039a.676.676 0 010-.1.077.077 0 010-.083.117.117 0 010-.033.617.617 0 010-.1c.315-2.505 2.064-5.033 3.543-6.953a13.211 13.211 0 015.585-4.508c.552-.221 1.882-.833 2.461-.364s.091 1.215-.147 1.673z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-328.591 -395.92)"
          fill="#455a64"
        />
        <path
          data-name="Path 101505"
          d="M434.886 413.9c-.519-.519-2.053.767-2.439 1.065a26.192 26.192 0 00-3.173 3.085 22.841 22.841 0 00-4.05 5.866l-.055.066v.143a.066.066 0 000 .083l.077-.088c.193-.237.392-.464.6-.69l.1-.11a49.115 49.115 0 014.746-4.188 23.731 23.731 0 003.156-3.068c.216-.281 1.479-1.722 1.038-2.164zm-.48 1.148c-1.291 2.147-3.532 3.714-5.436 5.276a25.582 25.582 0 00-3.609 3.427 24.283 24.283 0 013.747-5.27 32.5 32.5 0 012.583-2.649 13.146 13.146 0 011.562-1.253 5.284 5.284 0 011.06-.552c.723-.232.315.657.094 1.026z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-328.616 -397.328)"
          fill="#fff"
        />
        <path
          data-name="Path 101506"
          d="M419.976 430.484a.127.127 0 01-.11.061l.028.039a.039.039 0 11-.055.055 21.3 21.3 0 01-4.966-7.3 11.317 11.317 0 01-.723-3.6c0-.839-.072-2.533.651-3.151 1.451-1.231 2.759 4 2.963 4.79.364 1.435.6 2.9.927 4.343.364 1.578.844 3.129 1.319 4.68a.1.1 0 01-.034.083z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-323.683 -398.504)"
          fill="#455a64"
        />
        <path
          data-name="Path 101507"
          d="M420.751 432.108c-.077-.132-.155-.265-.226-.4a24.34 24.34 0 01-2.61-7.025 22.463 22.463 0 00-.966-3.659c-.116-.287-.48-1.4-.955-.839a8.473 8.473 0 00.055 2.947 15.135 15.135 0 001.175 3.592 25.384 25.384 0 003.311 5.4l.116.143a.033.033 0 000-.05.1.1 0 00-.028-.039.138.138 0 00.128-.07zm-2.941-4.492a18.1 18.1 0 01-1.391-3.488 11.934 11.934 0 01-.3-1.468 7.537 7.537 0 01-.1-1.164c0-.955.083-1.2.243-1.236s.237.083.519.651a6.269 6.269 0 01.4 1.159c.149.552.281 1.1.4 1.705.248 1.137.48 2.279.811 3.4a18.934 18.934 0 002.009 4.569 29.8 29.8 0 01-2.592-4.128z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-324.458 -400.128)"
          fill="#fff"
        />
        <path
          data-name="Path 101508"
          d="M431.529 431.047a7.119 7.119 0 01-1.33 2.759c-1.2 1.656-2.759 3.046-4.056 4.619a8.539 8.539 0 00-.552.717 1.781 1.781 0 01-.171.342 1.2 1.2 0 01.066-.359 29.664 29.664 0 011.766-6.12 14.8 14.8 0 011.92-3.008c.375-.419 1.247-1.33 1.909-1.131s.553 1.651.448 2.181z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-328.735 -404.078)"
          fill="#455a64"
        />
        <path
          data-name="Path 101509"
          d="M430.445 431.587c-.7-.441-2.516 2.8-2.759 3.223a20.593 20.593 0 00-2.075 4.966c-.055.193-.1.386-.155.585a2.691 2.691 0 00-.066.359c0 .005.149-.315.171-.348l.094-.121c.155-.2.315-.4.475-.59.789-1.181 1.6-2.351 2.362-3.548a18.969 18.969 0 001.468-2.693c.093-.239.794-1.62.485-1.833zm-.2.911c-1 2.82-3.046 5.044-4.608 7.549a.873.873 0 00-.072.121.825.825 0 01.061-.221 23.34 23.34 0 011.655-4.117 21.577 21.577 0 011.4-2.323 10.958 10.958 0 01.866-1.208c.143-.16.552-.579.7-.508s.06.518-.001.709z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-328.722 -405.291)"
          fill="#fff"
        />
        <path
          data-name="Path 101510"
          d="M440.9 388.635c-2.091 4.806-7.582 7.041-9.961 11.677l-.21.43a.094.094 0 01-.154.028.072.072 0 010-.083l.05-.1a25.048 25.048 0 012.936-8.388 12.74 12.74 0 015.126-5.452c.552-.292 1.655-.9 2.29-.425s.186 1.723-.077 2.313z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-331.042 -384.942)"
          fill="#455a64"
        />
        <path
          data-name="Path 101511"
          d="M439.024 390.941c-.265-.193-.668.044-.9.16a8.227 8.227 0 00-1.584 1.1 13.243 13.243 0 00-2.533 3.008 27.419 27.419 0 00-2.985 6.07c-.16.486-.3.971-.414 1.468a1.07 1.07 0 01-.039.182.1.1 0 00.16 0c.061-.149.127-.281.193-.419a.23.23 0 000-.039 16.314 16.314 0 011.49-2.759 37.933 37.933 0 013.136-3.712c.447-.5 4.398-4.364 3.476-5.059zm-.337 1.1a17.961 17.961 0 01-2.478 3.007c-.872.971-1.749 1.931-2.6 2.914a17.849 17.849 0 00-1.832 2.5 14.552 14.552 0 00-1.148 2.312 20.6 20.6 0 01.822-2.406v-.028a36.357 36.357 0 012.031-4.039.228.228 0 01.028-.05 16.477 16.477 0 012.312-3.228 9.321 9.321 0 011.655-1.4c.684-.447 1.286-.734 1.457-.513s-.055.629-.248.96z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-331.043 -387.06)"
          fill="#fff"
        />
        <path
          data-name="Path 101512"
          d="M430.732 400.808a161.987 161.987 0 00-1.821 4.106c-.281.646-.552 1.291-.833 1.937a.24.24 0 010 .039c-.094.232-.2.469-.3.7a43.124 43.124 0 00-2.456 6.5 20.143 20.143 0 00-.5 3.615v.575a.816.816 0 000 .116c-.039 1.054 0 2.108 0 3.14.028 1.435.149 2.858.281 4.277a1.1 1.1 0 000 .127v.193a.551.551 0 010 .1v.182c.044.453.088.911.127 1.374h-.436a27.81 27.81 0 01-.552-4.233.477.477 0 010-.088v-.927c0-.408-.028-.817-.039-1.22a30.19 30.19 0 01.508-7.086 1.86 1.86 0 01.044-.188.441.441 0 010-.083l.033-.138.055-.215c.033-.127.072-.259.11-.386l.033-.11a46.579 46.579 0 012.4-5.833c.16-.342.32-.684.486-1.026.088-.193.182-.381.27-.552l.43-.861v-.028c.673-1.346 1.369-2.682 2.031-4.039.052-.084.162-.034.129.032z"
          transform="translate(-250.213 -237.7) translate(250.213 318.415) translate(-328.175 -391.481)"
          fill="#455a64"
        />
        <g transform="translate(-250.213 -237.7) translate(262.418 237.7)">
          <path
            data-name="Rectangle 56269"
            transform="translate(.006)"
            fill="#bfbfbf"
            d="M0 0H85.843V6.136H0z"
          />
          <path
            data-name="Rectangle 56270"
            transform="translate(4.244 6.136)"
            fill="#bfbfbf"
            d="M0 0H77.361V3.846H0z"
          />
          <path
            data-name="Path 101513"
            d="M357.381 250.321l-77.361.844v-2.345h77.361z"
            transform="translate(-275.776 -242.684)"
            opacity={0.2}
          />
          <path
            data-name="Rectangle 56271"
            transform="rotate(180 42.921 58.583)"
            fill="#bfbfbf"
            d="M0 0H85.843V6.136H0z"
          />
          <path
            data-name="Rectangle 56272"
            transform="rotate(180 40.803 55.514)"
            fill="#bfbfbf"
            d="M0 0H77.361V3.846H0z"
          />
          <path
            data-name="Path 101514"
            d="M280.02 435.494l77.361-.844V437H280.02z"
            transform="translate(-275.776 -325.967)"
            opacity={0.2}
          />
          <g data-name="Group 106535" opacity={0.4}>
            <path
              data-name="Path 101515"
              d="M351.9 328.555c3.1 4.3 4.481 24.435 4.481 24.435h-66.7s1.38-20.131 4.481-24.435c4.966-6.876 25.936-21.654 25.936-25.042v-1.258c-.16-3.466-20.97-18.155-25.936-25-3.1-4.31-4.481-21.477-4.481-21.477h66.7S355 272.947 351.9 277.257c-4.966 6.87-25.936 21.648-25.936 25.037v1.264c.181 3.46 21.007 18.149 25.936 24.997z"
              transform="translate(9.425 9.861) translate(-289.531 -255.664)"
              fill="#ebebeb"
            />
            <path
              data-name="Path 101516"
              d="M356.408 353.034h-67v-.149c.055-.822 1.418-20.214 4.508-24.507 2.334-3.245 8.156-8.189 13.8-12.963 5.949-5.049 12.1-10.264 12.1-12v-1.258c-.083-1.76-5.916-6.71-12.091-11.947-5.634-4.779-11.462-9.723-13.8-12.974-3.085-4.288-4.453-20.837-4.508-21.521v-.149h66.992v.149c-.055.706-1.418 17.256-4.508 21.521-2.334 3.25-8.156 8.189-13.8 12.962-5.949 5.049-12.1 10.264-12.1 12v1.264c.083 1.76 5.916 6.7 12.091 11.942 5.634 4.784 11.462 9.723 13.8 12.974 3.09 4.293 4.453 23.685 4.508 24.507zm-66.672-.276h66.374c-.132-1.843-1.54-20.186-4.415-24.22-2.362-3.2-8.162-8.167-13.785-12.924-6.49-5.518-12.1-10.259-12.184-12.14v-1.269c0-1.86 5.651-6.655 12.2-12.207 5.618-4.762 11.428-9.7 13.746-12.913 2.892-4.017 4.3-19.557 4.415-21.257h-66.351c.143 1.7 1.556 17.239 4.442 21.257 2.318 3.223 8.134 8.156 13.757 12.924 6.49 5.518 12.1 10.264 12.184 12.14v1.269c0 1.86-5.651 6.655-12.2 12.207-5.645 4.746-11.456 9.712-13.774 12.913-2.897 4.035-4.305 22.377-4.409 24.22z"
              transform="translate(9.425 9.861) translate(-289.41 -255.57)"
              fill="#c7c7c7"
            />
          </g>
          <path
            data-name="Path 101517"
            d="M351.822 327.046c2.914 4.056 4.216 25.859 4.216 25.859H297.08s1.3-21.8 4.216-25.859c4.657-6.467 24.385-20.374 24.385-23.563V302.3c-.149-3.256-19.75-17.107-24.385-23.519-2.914-4.056-4.216-23.177-4.216-23.177h58.958s-1.3 19.115-4.216 23.177c-4.657 6.467-24.391 20.374-24.391 23.563v1.186c.154 3.252 19.755 17.076 24.391 23.516z"
            transform="translate(-283.422 -245.722)"
            fill="#ebebeb"
            opacity={0.8}
          />
          <g
            data-name="Group 106536"
            transform="translate(20.462 85.164)"
            opacity={0.1}
          >
            <path
              data-name="Path 101520"
              d="M374.547 422.848a.408.408 0 11-.408-.408.408.408 0 01.408.408z"
              transform="translate(-338.236 -405.659)"
            />
            <path
              data-name="Path 101521"
              d="M321.592 413.478a.414.414 0 01-.414.408.408.408 0 110-.817.414.414 0 01.414.408z"
              transform="translate(-314.501 -401.459)"
            />
            <path
              data-name="Path 101522"
              d="M376.438 419.914a.309.309 0 11-.309-.3.309.309 0 01.309.3z"
              transform="translate(-339.173 -404.391)"
            />
            <path
              data-name="Path 101523"
              d="M369.378 399.849a.309.309 0 11-.192-.286.309.309 0 01.192.286z"
              transform="translate(-336.009 -395.396)"
            />
            <path
              data-name="Path 101524"
              d="M310.028 425.164a.309.309 0 11-.309-.3.309.309 0 01.309.3z"
              transform="translate(-309.41 -406.743)"
            />
            <path
              data-name="Path 101525"
              d="M342.227 396.033a.3.3 0 11-.607 0 .3.3 0 01.3-.3.3.3 0 01.307.3z"
              transform="translate(-323.846 -393.688)"
            />
            <path
              data-name="Path 101526"
              d="M341.275 392.24a.215.215 0 01-.215.21.21.21 0 010-.419.215.215 0 01.215.209z"
              transform="translate(-323.5 -392.03)"
            />
            <path
              data-name="Path 101527"
              d="M349.26 419.06a.215.215 0 11-.215-.21.21.21 0 01.215.21z"
              transform="translate(-327.077 -404.05)"
            />
            <path
              data-name="Path 101528"
              d="M325.5 421.61a.215.215 0 11-.215-.21.21.21 0 01.215.21z"
              transform="translate(-316.428 -405.193)"
            />
            <path
              data-name="Path 101529"
              d="M373.769 405.71a.21.21 0 11-.21-.21.21.21 0 01.21.21z"
              transform="translate(-338.066 -398.067)"
            />
            <circle
              data-name="Ellipse 129869"
              cx={0.21}
              cy={0.21}
              r={0.21}
              transform="translate(41.04 18.001)"
            />
          </g>
          <g data-name="Group 106537" opacity={0.1}>
            <path
              data-name="Path 101532"
              d="M347 327.335a.309.309 0 11.3.3.3.3 0 01-.3-.3z"
              transform="translate(39.784 48.876) translate(-345.579 -326.606)"
            />
            <path
              data-name="Path 101533"
              d="M344.428 329.363a.215.215 0 11.1.215.215.215 0 01-.1-.215z"
              transform="translate(39.784 48.876) translate(-344.425 -327.575)"
            />
            <path
              data-name="Path 101534"
              d="M358.68 326.485a.215.215 0 01.21-.215.221.221 0 01.215.215.215.215 0 01-.215.21.21.21 0 01-.21-.21z"
              transform="translate(39.784 48.876) translate(-350.814 -326.27)"
            />
          </g>
          <path
            data-name="Path 101535"
            d="M302.112 395.927a77.567 77.567 0 01.143-11.439c.155-1.9.37-3.8.712-5.728.1-.5.182-.966.331-1.49l.11-.392.05-.193a.9.9 0 01.1-.248 5.578 5.578 0 01.43-.872 19.57 19.57 0 011.86-2.472 72.739 72.739 0 018.277-7.968 77.354 77.354 0 019.276-6.694c-2.378 3.013-4.928 5.816-7.488 8.587s-5.193 5.463-7.582 8.211a18.082 18.082 0 00-1.573 2.025 3.76 3.76 0 00-.221.386.391.391 0 00-.039.083l-.05.143-.088.287c-.132.381-.226.844-.353 1.264-.43 1.771-.811 3.614-1.209 5.447-.777 3.679-1.588 7.382-2.686 11.063z"
            transform="translate(-285.612 -291.807)"
            fill="#fff"
          />
          <path
            data-name="Path 101536"
            d="M323.026 295.4a101.71 101.71 0 01-8.857-7.014q-4.238-3.7-8.211-7.665l-.5-.5-.248-.248-.292-.3a6.623 6.623 0 01-1-1.567 19.25 19.25 0 01-1.032-2.875 45.362 45.362 0 01-1.153-5.634 60.292 60.292 0 01-.607-11.329c1.2 3.6 2.163 7.223 3.173 10.783.513 1.777 1 3.554 1.6 5.242a18.07 18.07 0 00.96 2.367 3.528 3.528 0 00.5.789l.944.938q3.962 3.962 7.687 8.178a100.806 100.806 0 017.036 8.835z"
            transform="translate(-285.215 -246.919)"
            fill="#fff"
          />
          <ellipse
            data-name="Ellipse 129868"
            cx={43}
            cy={3}
            rx={43}
            ry={3}
            transform="translate(-.574 120.582)"
            fill="#e8e8e8"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function FileSearchIllustration(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '120px',
    height: '120px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 120.313 120.04"
      {...rest}
    >
      <g data-name="File searching-rafiki">
        <path
          data-name="Path 99907"
          d="M170.116 131.594c-4.648-3.163-14.641-4.783-20.269-2.916-7.188 2.387-6.936 7.624-12.167 10.865-14.13 8.745-28.473 2.865-43.405-.9-33.091-8.361-53.813 23.479-21.315 43.053 19.541 11.764 60.577 20.359 65.2-10.823 2-13.48-5.213-14.505 11.544-17.437 6.613-1.164 41.468-7.511 20.412-21.842z"
          transform="translate(-56.11 -24.516) translate(56.27 54.465) translate(-56.643 -127.886)"
          fill="#f5f5f5"
        />
        <ellipse
          cx={58.29}
          cy={3.403}
          rx={58.29}
          ry={3.403}
          fill="#f5f5f5"
          transform="translate(-56.11 -24.516) translate(56.11 137.75)"
        />
        <g data-name="Group 106442">
          <path
            data-name="Path 99958"
            d="M37.64 53.388a27.628 27.628 0 1115.747-35.76 27.628 27.628 0 01-15.747 35.76z"
            transform="translate(-56.11 -24.516) rotate(6.02 -188.381 857.207) translate(1.756 1.753)"
            fill="#665cd7"
            opacity={0.2}
          />
          <path
            data-name="Path 99959"
            d="M28.07.462L6.4 49.676a35.234 35.234 0 01-.989-.779A27.3 27.3 0 010 42.857L18.717.348a27.339 27.339 0 019.353.114z"
            transform="translate(-56.11 -24.516) rotate(6.02 -188.381 857.207) translate(6.327 1.756)"
            fill="#fff"
            opacity={0.1}
          />
          <path
            data-name="Path 99960"
            d="M32.579 7.089l-20.373 46.27A27.592 27.592 0 010 50.527L22.247 0a27.658 27.658 0 0110.332 7.089z"
            transform="translate(-56.11 -24.516) rotate(6.02 -188.381 857.207) translate(17.222 3.667)"
            fill="#fff"
            opacity={0.1}
          />
          <path
            data-name="Path 99961"
            d="M6.645 10.777a29.39 29.39 0 114.131 41.358 29.39 29.39 0 01-4.131-41.358zm43.628 35.7a26.985 26.985 0 10-37.976 3.8 26.985 26.985 0 0037.976-3.8z"
            fill="#665cd7"
            transform="translate(-56.11 -24.516) rotate(6.02 -188.381 857.207)"
          />
          <path
            data-name="Path 99962"
            d="M2.646.251C5.542 1.979 8.3 3.879 11 5.839s5.342 4 7.937 6.1 5.141 4.251 7.6 6.515q1.846 1.7 3.608 3.481c1.178 1.2 2.336 2.405 3.427 3.71a1.693 1.693 0 01-2.1 2.573c-1.5-.812-2.91-1.708-4.314-2.625s-2.775-1.873-4.128-2.856c-2.706-1.96-5.324-4.025-7.889-6.157S10.045 12.267 7.589 10 2.725 5.413.458 2.917A1.741 1.741 0 012.64.251z"
            transform="translate(-56.11 -24.516) rotate(6.02 -188.381 857.207) translate(50.221 46.127)"
            fill="#665cd7"
          />
        </g>
        <path
          data-name="Path 99965"
          d="M171.275 223.72a3.818 3.818 0 013.52-2.5h24.066a2.844 2.844 0 012.922 3.241l-6.217 52.163a3.782 3.782 0 01-3.689 3.226h-76.36a2.838 2.838 0 01-2.919-3.226l5.213-43.826a3.782 3.782 0 013.689-3.226h41.553a8.92 8.92 0 008.222-5.852z"
          transform="translate(-56.11 -24.516) translate(63.807 81.856) translate(-103.293 -220.548)"
          fill="#7b7b7b"
        />
        <path
          data-name="Path 99966"
          d="M117.23 283.357h79.791l4.546-53.687h-79.788z"
          transform="translate(-56.11 -24.516) translate(63.807 81.856) translate(-106.552 -226.462)"
          fill="#f0f0f0"
        />
        <path
          data-name="Path 99967"
          d="M117.23 283.357h79.791l3.289-53.687h-78.531z"
          transform="translate(-56.11 -24.516) translate(63.807 81.856) translate(-106.552 -226.462)"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 99968"
          d="M117.23 279.391h79.791L199.762 224h-79.787z"
          transform="translate(-56.11 -24.516) translate(63.807 81.856) translate(-106.552 -222.497)"
          fill="#f0f0f0"
        />
        <path
          data-name="Path 99969"
          d="M117.23 279.391h79.791L198.106 224h-78.131z"
          transform="translate(-56.11 -24.516) translate(63.807 81.856) translate(-106.552 -222.497)"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 99970"
          d="M117.23 275.895h79.791L197.5 219h-79.792z"
          transform="translate(-56.11 -24.516) translate(63.807 81.856) translate(-106.552 -219)"
          fill="#f0f0f0"
        />
        <path
          data-name="Path 99971"
          d="M114.621 278.4h79.791l-1.638-55.818H113.5z"
          transform="translate(-56.11 -24.516) translate(63.807 81.856) translate(-103.944 -221.504)"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 99972"
          d="M111.726 279.391h79.791L189.148 224H109.36z"
          transform="translate(-56.11 -24.516) translate(63.807 81.856) translate(-101.048 -222.497)"
          fill="#f0f0f0"
        />
        <path
          data-name="Path 99973"
          d="M108.46 281.336h79.791l-3.773-54.556H104.69z"
          transform="translate(-56.11 -24.516) translate(63.807 81.856) translate(-97.782 -224.441)"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 99974"
          d="M107.061 282.42h79.791l-4.374-54.09H102.69z"
          transform="translate(-56.11 -24.516) translate(63.807 81.856) translate(-96.383 -225.525)"
          fill="#f0f0f0"
        />
        <path
          data-name="Path 99975"
          d="M132.347 234.848a2.6 2.6 0 012.721-2.564h24.066a4.209 4.209 0 013.95 3.307l9.509 48.7a2.646 2.646 0 01-2.661 3.307H93.571a4.208 4.208 0 01-3.953-3.307l-7.843-40.149a2.646 2.646 0 012.661-3.307h41.556a6.07 6.07 0 006.355-5.987z"
          transform="translate(-56.11 -24.516) translate(63.807 81.856) translate(-81.712 -228.288)"
          fill="#b9b9b9"
        />
      </g>
    </SvgIcon>
  );
}

export function NotificationIllustrator(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '128px',
    height: '120px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 128.252 120.478"
      {...rest}
    >
      <g
        data-name="Notification Empty state"
        transform="translate(-124 -174.96)"
      >
        <path
          data-name="Path 20625"
          d="M199.688 152.67c.327-1.875.852-3.73 1.228-5.64a33.34 33.34 0 00.157-11.169 43.873 43.873 0 00-8.233-19.6c-4.586-6.152-11.482-11.134-18.894-9.252-7.711 1.949-14.238 7.9-21.737 10.609-12.7 4.593-25.557-3.779-38.355-3.48-11.051.24-22.645 4.207-29.924 14.016-2.972 4-5.042 9.395-4.339 14.7.859 6.462 5.6 11.378 9.137 16.159 5.421 7.328 7.474 15.31 2.912 23.863-3.083 5.8-7.23 10.919-6.872 18.118.574 11.517 11.208 18.473 19.976 21.573 8.908 3.152 19.023 3.48 27.878-.07 7.867-3.166 13.358-10.091 20.529-14.51a32.45 32.45 0 0121.055-4.621c7.578.9 15.564 2.5 22.888-.769 7.008-3.132 10.327-11.5 10.588-19.52a32.063 32.063 0 00-1.778-11.775c-1.625-4.544-5.219-8.09-6.232-12.909a14.962 14.962 0 01.014-5.717z"
          transform="translate(46.202 64.813) translate(77.798 112.187) translate(-79.454 -106.601)"
          fill="#f7f6ff"
        />
        <path
          data-name="Path 20626"
          d="M199.688 152.67c.327-1.875.852-3.73 1.228-5.64a33.34 33.34 0 00.157-11.169 43.873 43.873 0 00-8.233-19.6c-4.586-6.152-11.482-11.134-18.894-9.252-7.711 1.949-14.238 7.9-21.737 10.609-12.7 4.593-25.557-3.779-38.355-3.48-11.051.24-22.645 4.207-29.924 14.016-2.972 4-5.042 9.395-4.339 14.7.859 6.462 5.6 11.378 9.137 16.159 5.421 7.328 7.474 15.31 2.912 23.863-3.083 5.8-7.23 10.919-6.872 18.118.574 11.517 11.208 18.473 19.976 21.573 8.908 3.152 19.023 3.48 27.878-.07 7.867-3.166 13.358-10.091 20.529-14.51a32.45 32.45 0 0121.055-4.621c7.578.9 15.564 2.5 22.888-.769 7.008-3.132 10.327-11.5 10.588-19.52a32.063 32.063 0 00-1.778-11.775c-1.625-4.544-5.219-8.09-6.232-12.909a14.962 14.962 0 01.014-5.717z"
          transform="translate(46.202 64.813) translate(77.798 112.187) translate(-79.454 -106.601)"
          fill="#f4f2ff"
          opacity={0.7}
        />
        <g data-name="Group 3963">
          <g data-name="Group 3942">
            <path
              d="M25.715 9.261C13.464 9.835 4.077 20.364 4.077 32.545V43.56L.06 51.641l-.022.044a5.372 5.372 0 004.8 7.563h12.879a9.093 9.093 0 0018.187 0H48.78a5.373 5.373 0 004.8-7.563l-.021-.044-4.017-8.081V31.968A22.773 22.773 0 0025.715 9.261zm.213 4.541a18.147 18.147 0 0119.069 18.166v12.124a2.273 2.273 0 00.241 1.013l4.233 8.519a.7.7 0 01-.687 1.077H4.838a.7.7 0 01-.684-1.077h0l4.233-8.516a2.274 2.274 0 00.237-1.012V32.545c0-9.852 7.546-18.283 17.304-18.743zm-3.664 45.446h9.093a4.547 4.547 0 01-9.093 0z"
              fill="#101010"
              stroke="#464646"
              strokeWidth={0.5}
              transform="translate(157.947 190.587)"
            />
            <g
              data-name="Ellipse 39213"
              transform="translate(157.947 190.587) translate(29.553)"
              fill="#7a67d9"
              stroke="#edf8f6"
              strokeWidth={1}
            >
              <circle cx={14.776} cy={14.776} r={14.776} stroke="none" />
              <circle cx={14.776} cy={14.776} r={14.276} fill="none" />
            </g>
            <text
              data-name={0}
              transform="translate(157.947 190.587) translate(44.053 20.413)"
              fill="#fff"
              fontSize={16}
              fontFamily="Poppins-Bold, Poppins"
              fontWeight={700}
            >
              <tspan x={-5} y={0}>
                0
              </tspan>
            </text>
          </g>
        </g>
        <ellipse
          data-name="Ellipse 129793"
          cx={23.5}
          cy={3}
          rx={23.5}
          ry={3}
          transform="translate(162 263)"
          fill="#eae6ff"
        />
        <text
          transform="rotate(22 -434.342 520.442)"
          fill="#101010"
          fontSize={16}
          fontFamily="Poppins-Bold, Poppins"
          fontWeight={700}
        >
          <tspan x={-3} y={0}>
            z
          </tspan>
        </text>
        <text
          data-name="z"
          transform="rotate(22 -416.173 498.788)"
          fill="#101010"
          fontSize={16}
          fontFamily="Poppins-Bold, Poppins"
          fontWeight={700}
        >
          <tspan x={-3} y={0}>
            z
          </tspan>
        </text>
      </g>
    </SvgIcon>
  );
}

export const homeEmptyState_style = {
  rootSx: {
    height: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleSx: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#5C6266',
    textAlign: 'center',
    mb: 0.5,
  },
  descriptionSx: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#888888',
    textAlign: 'center',
  },
};
